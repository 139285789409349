import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';

import { LocalizationProvider } from '@mui/x-date-pickers';
import LoadingPage from './components/LoadingPage';
import NotFound from './components/NotFound';
import CandidatesList from './containers/CandidatesList';
import CreateOrUpdateCandidate from './containers/CreateOrUpdateCandidate';
import Home from './containers/Home';
import Layout from './containers/Layout';
import PrivateRoute from './containers/PrivateRoute';
import PublicRoute from './containers/PublicRoute';
import SignIn from './containers/SignIn';
import './custom.css';
import { selectLoading, selectUser } from './store/user/selectors';

const App: React.FC = () => {
	const isLoading = useSelector(selectLoading);
	const user = useSelector(selectUser);

	if (isLoading) return <LoadingPage />;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Layout>
				<Switch>
					<PublicRoute
						authenticated={user !== null}
						path='/'
						component={SignIn}
						exact
					/>
					<PrivateRoute
						authenticated={user !== null}
						path='/home'
						component={Home}
						exact
					/>
					<PrivateRoute
						authenticated={user !== null}
						path='/candidates'
						component={CandidatesList}
						exact
					/>
					<PrivateRoute
						authenticated={user !== null}
						path='/candidates/new'
						component={CreateOrUpdateCandidate}
						exact
					/>
					<PrivateRoute
						authenticated={user !== null}
						path='/candidates/:email'
						component={CreateOrUpdateCandidate}
						exact
					/>
					<Route component={NotFound} />
				</Switch>
			</Layout>
		</LocalizationProvider>
	);
};

export default App;
