import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Candidate, Reference } from 'src/models';
import { selectProfiles } from 'src/store/profile';
import { createReference } from 'src/store/reference';
import CreateOrEditModal from '../CreateOrEditModal';
import FormInput from '../FormInput';

type Props = {
	candidate: Candidate;
};

export const CreateReferenceModal: React.FC<Props> = ({ candidate }) => {
	const dispatch = useDispatch();
	let profiles = useSelector(selectProfiles);
	const createNewReference = bindActionCreators(createReference, dispatch);

	const [reference, setReference] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '',
		referenceEmail: '',
		company: '',
		relationship: '',
		order: 0,
		isDisplayable: true,
		candidateEmail: candidate.candidateEmail,
	} as Reference);

	const resetReference = () =>
		setReference({
			firstName: '',
			lastName: '',
			phoneNumber: '',
			referenceEmail: '',
			company: '',
			relationship: '',
			order: 0,
			isDisplayable: true,
			candidateEmail: candidate.candidateEmail,
		} as Reference);

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) =>
		createNewReference(
			reference,
			candidate.candidateEmail,
			handleClose,
			handleError
		);

	return (
		<CreateOrEditModal
			label={'Add reference'}
			title={'Add a new reference'}
			resetModel={resetReference}
			saveData={saveData}
		>
			<FormInput
				label='First name'
				required
				maxLength={50}
				fieldValue={reference.firstName}
				setFieldValue={(value: string) =>
					setReference({ ...reference, firstName: value })
				}
			/>
			<FormInput
				label='Last name'
				required
				maxLength={50}
				fieldValue={reference.lastName}
				setFieldValue={(value: string) =>
					setReference({ ...reference, lastName: value })
				}
			/>
			<FormInput
				label='Company'
				required
				maxLength={200}
				fieldValue={reference.company}
				setFieldValue={(value: string) =>
					setReference({ ...reference, company: value })
				}
			/>
			<FormInput
				label='Phone number'
				type='tel'
				maxLength={50}
				fieldValue={reference.phoneNumber}
				setFieldValue={(value?: string | null) =>
					setReference({ ...reference, phoneNumber: value })
				}
			/>
			<FormInput
				label='Email'
				type='email'
				fieldValue={reference.referenceEmail}
				setFieldValue={(value?: string | null) =>
					setReference({ ...reference, referenceEmail: value })
				}
			/>
			<FormInput
				label='Professional relationship type'
				type='textarea'
				required
				maxLength={600}
				fieldValue={reference.relationship}
				setFieldValue={(value: string) =>
					setReference({ ...reference, relationship: value })
				}
			/>
			<FormInput
				label='Order of appearance'
				type='numeric'
				min='-1'
				required
				helpText='The greater the number, the further down in the list the reference will be. If -1, then the reference is not displayed.'
				fieldValue={reference.order}
				setFieldValue={(value: string) =>
					setReference({ ...reference, order: Number(value) })
				}
			/>
			<span className='text-danger px-1'>
				Either an email or phone number is required
			</span>
		</CreateOrEditModal>
	);
};

export default CreateReferenceModal;
