import { Skill } from "src/models/Skill";

export interface SkillState {
    skills: Skill[];
}

export const unloadedState: SkillState = {
    skills: []
};

export const reset: () => SkillState = () => {
	return {
		skills: []
	} as SkillState
}