import { push } from 'connected-react-router';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaFilePdf, FaUserPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import FormInput from 'src/components/FormInput';
import IconButton from 'src/components/IconButton';
import LoadingPage from 'src/components/LoadingPage';
import { Candidate } from 'src/models';
import {
	getCandidates,
	setCandidateOffline,
} from 'src/store/candidate/actions';
import {
	selectCandidate,
	selectCandidates,
} from 'src/store/candidate/selectors';
import { ResetCandidate } from 'src/store/root/actions';
import { selectCanManageCandidates } from 'src/store/user';

const Home: React.FC = () => {
	const dispatch = useDispatch();
	const canManageCandidates = useSelector(selectCanManageCandidates);
	const { addToast } = useToasts();
	const [email, setEmail] = React.useState('');
	const [isDisabled, setIsDisabled] = React.useState(true);
	const candidateEmails = useSelector(selectCandidates);
	const [isLoading, setIsLoading] = React.useState(true);
	const candidate = useSelector(selectCandidate);
	const emailAddressRegex: RegExp =
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

	const onChange = (value: string) => {
		setEmail(value);
		setIsDisabled(
			!value ||
				value.trim().replace(' ', '') === '' ||
				!emailAddressRegex.test(value) ||
				!candidateEmails ||
				isLoading
		);
	};

	const goToAdd = (email: string) => {
		dispatch(ResetCandidate());
		dispatch(
			setCandidateOffline({
				firstName: '',
				lastName: '',
				phoneNumber: '',
				candidateEmail: email,
				birthDate: null,
				nationality: '',
				gender: '',
				status: '',
				availability: '',
			} as Candidate)
		);
		dispatch(push(`/candidates/new?email=${email}`));
	};

	const goToEdit = (email: string) => {
		dispatch(ResetCandidate());
		dispatch(push(`/candidates/${email}`));
	};
	const getSelectItems = React.useCallback(() => {
		dispatch(
			getCandidates(
				() => {
					setIsLoading(false);
				},
				(error: string) =>
					addToast(
						error
							? `The following error occured: ${error}`
							: 'An error occured',
						{ appearance: 'error' }
					)
			)
		);
	}, [dispatch]);

	React.useEffect(() => {
		if (isLoading) {
			getSelectItems();
		}
	}, [isLoading]);

	if (isLoading) return <LoadingPage />;

	return (
		<>
			<Row className='d-flex flex-column justify-content-center align-items-center'>
				<Col sm='12' className='text-center'>
					<Card>
						<Card.Header className='bg-black text-white'>
							<h4 className='mb-0'>RMT, what is it?</h4>
						</Card.Header>
						<Card.Body>
							<Card.Text>
								RMT as in "Resume Management Tool" is an
								internal project helping Capyx's employees
								building CVs based on the company's clients CV
								templates.
							</Card.Text>
							<Card.Text>
								To discuss the matter of adding a new template
								to this tool, please contact{' '}
								<Card.Link
									target='_blank'
									href={`mailto:francois.lechat@capyx.be`}
								>
									François Lechat
								</Card.Link>
								.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{canManageCandidates && (
				<>
					<Row className='d-flex flex-column justify-content-center align-items-center'>
						<Col sm='4' className='mt-3 text-center'>
							<FormInput
								type='email'
								placeholder='firstname.lastname@capyx.be'
								values={candidateEmails}
								shouldAutoComplete={true}
								fieldValue={email}
								setFieldValue={onChange}
							/>
						</Col>
					</Row>

					<Row className='d-flex flex-row justify-content-center align-items-center'>
						<Col sm='2' className='mb-3 text-center'>
							<IconButton
								label='Create new candidate'
								icon={<FaUserPlus />}
								onClick={() => goToAdd(email.toLowerCase())}
								disabled={
									isDisabled ||
									candidateEmails.includes(
										email.toLowerCase()
									)
								}
							/>
						</Col>

						<Col sm='2' className='mb-3 text-center'>
							<IconButton
								label="Generate candidate's CV"
								icon={<FaFilePdf />}
								onClick={() => goToEdit(email.toLowerCase())}
								disabled={
									isDisabled ||
									!candidateEmails.includes(
										email.toLowerCase()
									)
								}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default Home;
