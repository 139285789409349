import * as React from 'react';
import './index.css';

const NotFound: React.FC = () => (
	<div id='notfound'>
		<div className='notfound'>
			<div className='notfound-404'>
				<h1>Oops!</h1>
				<h2>404 - The Page can't be found</h2>
			</div>
			<a href='/home'>GO TO HOMEPAGE</a>
		</div>
	</div>
);

export default NotFound;
