import React, { useEffect } from 'react';
import { Form, FormTextProps } from 'react-bootstrap';

type Props = {
	children?: React.ReactElement;
} & FormTextProps;

const CharacterCountInput: React.FC<Props> = ({ children, ...props }) => {
	const container = React.useRef(null);
	const [valueLength, setValueLength] = React.useState<number | undefined>(
		null
	);
	const [maxLength, setMaxLength] = React.useState<number | undefined>(null);

	useEffect(() => {
		const input: HTMLInputElement = container.current.firstChild;

		const inputReader = () => setValueLength(input.value.length);
		input.onkeyup = inputReader;
		inputReader();

		const isValidHTMLElement =
			['text', 'email', 'password'].includes(children.props.type) ||
			children.props.as === 'textarea';

		setMaxLength(isValidHTMLElement ? children.props.maxLength : null);
	}, [container, children]);

	let className = 'text-muted';
	if (valueLength >= maxLength - 10) className = 'text-warning';
	if (valueLength == maxLength) className = 'text-danger';

	return (
		<div ref={container} className='text-end'>
			{children}
			{valueLength != null && valueLength > 0 && maxLength && (
				<Form.Text {...props} className={className}>
					{valueLength}/{maxLength}
				</Form.Text>
			)}
		</div>
	);
};

export default CharacterCountInput;
