import React from 'react';

import Loading from '../Loading';

const LoadingPage: React.FC = () => (
	<div className='d-flex flex-column min-vh-100 justify-content-center align-items-center'>
		<Loading />
	</div>
);

export default LoadingPage;
