import { Action, Dispatch } from 'redux';

import { Description } from 'src/models/Description';
import { ExperienceDTO } from 'src/models/ExperienceDTO';
import { dateCreator, findDateFormat } from 'src/utils';
import { AppThunkAction } from '../';
import {
	AxiosError,
	AxiosResponse,
	defaultInstance,
	getAuthHeader,
} from '../../api';
import { ApiError, Experience } from '../../models';
import { dateFormat } from '../../utils';

export enum ExperienceActionTypes {
	SET_EXPERIENCES = 'SetExperiences',
	UPDATE_EXPERIENCE = 'UpdateExperience',
	ADD_EXPERIENCE = 'AddExperience',
	DELETE_EXPERIENCE = 'DeleteExperience',
	UPDATE_DESCRIPTION = 'UpdateDescription',
	ADD_DESCRIPTION = 'AddDescription',
	DELETE_DESCRIPTION = 'DeleteDescription',
}

// ACTIONS
export interface SetExperiencesAction extends Action {
	type: ExperienceActionTypes.SET_EXPERIENCES;
	experiences: Experience[];
}
const SetExperiences = (experiences: Experience[]): SetExperiencesAction => {
	return { type: ExperienceActionTypes.SET_EXPERIENCES, experiences };
};

export interface UpdateExperienceAction extends Action {
	type: ExperienceActionTypes.UPDATE_EXPERIENCE;
	experience: Experience;
}
const UpdateExperience = (experience: Experience): UpdateExperienceAction => {
	return { type: ExperienceActionTypes.UPDATE_EXPERIENCE, experience };
};

export interface AddExperienceAction extends Action {
	type: ExperienceActionTypes.ADD_EXPERIENCE;
	experience: Experience;
}
const AddExperience = (experience: Experience): AddExperienceAction => {
	return { type: ExperienceActionTypes.ADD_EXPERIENCE, experience };
};

export interface DeleteExperienceAction extends Action {
	type: ExperienceActionTypes.DELETE_EXPERIENCE;
	experienceId: string;
}
const DeleteExperience = (experienceId: string): DeleteExperienceAction => {
	return { type: ExperienceActionTypes.DELETE_EXPERIENCE, experienceId };
};

export interface UpdateDescriptionAction extends Action {
	type: ExperienceActionTypes.UPDATE_DESCRIPTION;
	description: Description;
}
const UpdateDescription = (
	description: Description
): UpdateDescriptionAction => {
	return { type: ExperienceActionTypes.UPDATE_DESCRIPTION, description };
};

export interface AddDescriptionAction extends Action {
	type: ExperienceActionTypes.ADD_DESCRIPTION;
	description: Description;
}
const AddDescription = (description: Description): AddDescriptionAction => {
	return { type: ExperienceActionTypes.ADD_DESCRIPTION, description };
};

export interface DeleteDescriptionAction extends Action {
	type: ExperienceActionTypes.DELETE_DESCRIPTION;
	experienceId: string;
	profile: string;
}
const DeleteDescription = (
	experienceId: string,
	profile: string
): DeleteDescriptionAction => {
	return {
		type: ExperienceActionTypes.DELETE_DESCRIPTION,
		experienceId,
		profile,
	};
};

export type ExperienceActions =
	| SetExperiencesAction
	| UpdateExperienceAction
	| AddExperienceAction
	| DeleteExperienceAction
	| UpdateDescriptionAction
	| AddDescriptionAction
	| DeleteDescriptionAction;

// ACTION CREATORS

export const getExperiences =
	(
		candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		defaultInstance
			.get(`/v1/candidates/${candidateEmail}/experiences/details`, {
				headers: getAuthHeader(),
			})
			.then((response: AxiosResponse<ExperienceDTO[]>) => {
				const experiences: Experience[] = [];
				response.data.forEach((e) => {
					e.descriptions.map((d) => {
						d.longDescription = d.longDescription ?? '';
					});
					experiences.push({
						...e,
						startDate: dateFormat(e.startDate, e.dateFormat),
						endDate: dateFormat(e.endDate, e.dateFormat),
					});
				});

				dispatch(SetExperiences(experiences));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const setExperiences = (experiences: Experience[]) => {
	return (dispatch: Dispatch) => {
		dispatch(SetExperiences(experiences));
	};
};

export const saveExperience =
	(
		experience: Experience,
		candidateEmail: string,
		experienceId: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		experience.dateFormat = findDateFormat(experience.startDate);

		const updatedExperience: ExperienceDTO = {
			...experience,
			startDate: dateCreator(experience.startDate),
			endDate: dateCreator(experience.endDate),
		};

		defaultInstance
			.put(
				`/v1/candidates/${candidateEmail}/experiences/${experienceId}`,
				updatedExperience,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse<Experience>) => {
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const saveExperienceAndDescription =
	(
		experience: Experience,
		description: Description,
		candidateEmail: string,
		experienceId: string,
		profile: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	async (dispatch) => {
		experience.dateFormat = findDateFormat(experience.startDate);

		const updatedExperience: ExperienceDTO = {
			...experience,
			startDate: dateCreator(experience.startDate),
			endDate: dateCreator(experience.endDate),
		};
		try {
			await defaultInstance.put(
				`/v1/candidates/${candidateEmail}/experiences/${experienceId}`,
				updatedExperience,
				{ headers: getAuthHeader() }
			);

			dispatch(UpdateExperience(experience));

			description.candidateEmail = candidateEmail;

			if (description.experienceId) {
				await defaultInstance.put(
					`/v1/candidates/${candidateEmail}/experiences/${experienceId}/descriptions/${profile}`,
					description,
					{ headers: getAuthHeader() }
				);

				dispatch(UpdateDescription(description));
			} else {
				description.experienceId = experienceId;
				description.profile = profile;
				const createDescriptionResponse: AxiosResponse<Description> =
					await defaultInstance.post(
						`v1/candidates/${candidateEmail}/experiences/${experienceId}/descriptions`,
						description,
						{ headers: getAuthHeader() }
					);

				dispatch(AddDescription(createDescriptionResponse.data));
			}

			successCallback();
		} catch (error) {
			if (error && error.response && error.response.data) {
				const errorsToDisplay = Object.values(
					error.response.data.errors
				).flat() as Array<string>;
				failureCallback(errorsToDisplay);
			} else console.error(error);
		}
	};

export const createExperience =
	(
		experience: Experience,
		candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		experience.dateFormat = findDateFormat(experience.startDate);

		const updatedExperience: ExperienceDTO = {
			...experience,
			startDate: dateCreator(experience.startDate),
			endDate: dateCreator(experience.endDate),
		};

		defaultInstance
			.post(
				`v1/candidates/${candidateEmail}/experiences`,
				updatedExperience,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse<Experience>) => {
				const responseExperience = response.data;

				const createdExperience: Experience = {
					...responseExperience,
					startDate: dateFormat(
						responseExperience.startDate,
						responseExperience.dateFormat
					),
					endDate: dateFormat(
						responseExperience.endDate,
						responseExperience.dateFormat
					),
				};
				dispatch(AddExperience(createdExperience));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(
						error.response.data.errors
					).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};

export const createExperienceWithDescription =
	(
		experience: Experience,
		description: Description,
		candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	async (dispatch) => {
		experience.dateFormat = findDateFormat(experience.startDate);

		const updatedExperience: ExperienceDTO = {
			...experience,
			startDate: dateCreator(experience.startDate),
			endDate: dateCreator(experience.endDate),
		};

		try {
			const createExperienceResponse: AxiosResponse<Experience> =
				await defaultInstance.post(
					`v1/candidates/${candidateEmail}/experiences`,
					updatedExperience,
					{ headers: getAuthHeader() }
				);
			const createExperienceResponseData = createExperienceResponse.data;

			const createdExperience: Experience = {
				...createExperienceResponseData,
				startDate: dateFormat(
					createExperienceResponseData.startDate,
					createExperienceResponseData.dateFormat
				),
				endDate: dateFormat(
					createExperienceResponseData.endDate,
					createExperienceResponseData.dateFormat
				),
			};

			dispatch(AddExperience(createdExperience));

			if (description.longDescription !== null) {
				description.experienceId = createdExperience.id;
				const createDescriptionResponse: AxiosResponse<Description> =
					await defaultInstance.post(
						`v1/candidates/${candidateEmail}/experiences/${createdExperience.id}/descriptions`,
						description,
						{ headers: getAuthHeader() }
					);

				dispatch(AddDescription(createDescriptionResponse.data));
			}

			successCallback();
		} catch (error) {
			if (error && error.response && error.response.data) {
				const errorsToDisplay = Object.values(
					error.response.data.errors
				).flat() as Array<string>;
				failureCallback(errorsToDisplay);
			} else console.error(error);
		}
	};

export const deleteExperience =
	(
		candidateEmail: string,
		experienceId: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		defaultInstance
			.delete(
				`/v1/candidates/${candidateEmail}/experiences/${experienceId}`,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse) => {
				dispatch(DeleteExperience(experienceId));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const updateExperience = (experience: Experience) => {
	return (dispatch: Dispatch) => {
		dispatch(UpdateExperience(experience));
	};
};

export const saveDescription =
	(
		candidateEmail: string,
		experienceId: string,
		profile: string,
		description: Description,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		description.candidateEmail = candidateEmail;
		defaultInstance
			.put(
				`/v1/candidates/${candidateEmail}/experiences/${experienceId}/descriptions/${profile}`,
				description,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse<Description>) => {
				dispatch(UpdateDescription(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const deleteDescription =
	(
		candidateEmail: string,
		experienceId: string,
		profile: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		defaultInstance
			.delete(
				`/v1/candidates/${candidateEmail}/experiences/${experienceId}/descriptions/${profile}`,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse) => {
				dispatch(DeleteDescription(experienceId, profile));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const createDescription =
	(
		description: Description,
		candidateEmail: string,
		experienceId: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ExperienceActions> =>
	(dispatch) => {
		defaultInstance
			.post(
				`v1/candidates/${candidateEmail}/experiences/${experienceId}/descriptions`,
				description,
				{ headers: getAuthHeader() }
			)
			.then((response: AxiosResponse<Description>) => {
				dispatch(AddDescription(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(
						error.response.data.errors
					).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};

export const updateDescription = (description: Description) => {
	return (dispatch: Dispatch) => {
		dispatch(UpdateDescription(description));
	};
};
