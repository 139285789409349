import React from 'react';
import { Nav } from 'react-bootstrap';

import { AsideLink } from '../../models';
import IconButton from '../IconButton';

type Props = {
	asideData: AsideLink[];
	className?: string | undefined;
};

export const AsideForm: React.FC<Props> = ({ asideData, className }) => (
	<aside className={className}>
		<Nav className='mb-2'>
			{asideData.map((data: AsideLink, index: number) => (
				<Nav.Item
					key={index}
					as={IconButton}
					variant={data.formShown ? 'dark' : 'outline-dark'}
					onClick={data.toggleForm}
					label={data.label.trim()}
					className='m-2'
					disabled={data.disabled}
				/>
			))}
		</Nav>
	</aside>
);

export default AsideForm;