export interface LevelState {
	levels: string[];
	error: string;
	loading: boolean;
}

export const unloadedState: LevelState = {
	levels: [],
	error: null,
	loading: false,
};
