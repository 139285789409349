import { Token } from 'src/models';

const key = 'token';

export function getAuthHeader() {
	const token = JSON.parse(localStorage.getItem(key)) as Token;
	if (token && token.accessToken) {
		return { Authorization: 'Bearer ' + token.accessToken };
	}
	return {};
}

export function setAuthHeader(token: Token) {
	localStorage.setItem(key, JSON.stringify(token));
}
