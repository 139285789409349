export interface StatusState {
	statuses: string[];
	error: string;
	loading: boolean;
}

export const unloadedState: StatusState = {
	statuses: [],
	error: null,
	loading: false,
};
