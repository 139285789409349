import { Action, Dispatch } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError, Candidate, Supplier } from '../../models';
import { AppThunkAction } from '../';

export enum SupplierActionTypes {
    SET_SUPPLIERS = 'SetSuppliers',
	UPDATE_SUPPLIER = 'UpdateSupplier',
	DELETE_SUPPLIER_REFERENCE = 'DeleteSupplierReference',
	ADD_SUPPLIER_REFERENCE = 'AddSupplierReference',
	SET_ALL_SUPPLIERS_EMAILS = 'SetAllSuppliersEmails'
}

// ACTIONS
export interface SetSuppliersAction extends Action {
	type: SupplierActionTypes.SET_SUPPLIERS;
    suppliers: Supplier[]
}
const SetSuppliers = (suppliers:Supplier[]): SetSuppliersAction => {
	return { type: SupplierActionTypes.SET_SUPPLIERS, suppliers};
};

export interface UpdateSupplierAction extends Action {
	type: SupplierActionTypes.UPDATE_SUPPLIER;
    supplier: Supplier;
	index: number;
}
const UpdateSupplier = (supplier:Supplier, index:number): UpdateSupplierAction => {
	return { type: SupplierActionTypes.UPDATE_SUPPLIER, supplier, index};
};

export interface DeleteSupplierReferenceAction extends Action {
	type: SupplierActionTypes.DELETE_SUPPLIER_REFERENCE;
	supplierEmail: string;
}
const DeleteSupplierReference = (supplierEmail:string): DeleteSupplierReferenceAction => {
	return { type: SupplierActionTypes.DELETE_SUPPLIER_REFERENCE, supplierEmail };
}

export interface AddSupplierReferenceAction extends Action {
	type: SupplierActionTypes.ADD_SUPPLIER_REFERENCE;
	supplier: Supplier;
}
const AddSupplierReference = (supplier: Supplier): AddSupplierReferenceAction => {
	return { type: SupplierActionTypes.ADD_SUPPLIER_REFERENCE, supplier };
}

export interface SetAllSuppliersEmailsAction extends Action {
	type: SupplierActionTypes.SET_ALL_SUPPLIERS_EMAILS;
	suppliersEmails: string[];
}
const SetAllSuppliersEmails = (suppliersEmails: string[]): SetAllSuppliersEmailsAction => {
	return { type: SupplierActionTypes.SET_ALL_SUPPLIERS_EMAILS, suppliersEmails };
}

export type SupplierActions = SetSuppliersAction | UpdateSupplierAction | DeleteSupplierReferenceAction | AddSupplierReferenceAction | SetAllSuppliersEmailsAction;

// ACTION CREATORS
export const getCandidateSuppliers =
	(
        candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<SupplierActions> =>
	(dispatch) => {
		defaultInstance
            .get(`/v1/candidates/${candidateEmail}/suppliers/details`, { headers: getAuthHeader() })
			.then((response: AxiosResponse<Supplier[]>) => {
				dispatch(SetSuppliers(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const saveSupplier =
(
	supplier: Supplier,
	supplierEmail: string,
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
): AppThunkAction<SupplierActions> =>
(dispatch) => {
	defaultInstance.put(
			`/v1/suppliers/${supplierEmail}`,
			supplier,
			{ headers: getAuthHeader() }
		)
	.then((response: AxiosResponse<Candidate>) => {
		successCallback();
	})
	.catch((error: AxiosError<ApiError>) => {
		if (error && error.response && error.response.data) {
			failureCallback(error.response.data.detail);
		} else console.error(error);
	});
};

export const deleteSupplierReference =
(
	candidateEmail:string,
	supplierEmail:string,
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
):AppThunkAction<SupplierActions> =>
(dispatch) => {
	defaultInstance
		.delete(`/v1/candidates/${candidateEmail}/suppliers/${supplierEmail}`, { headers: getAuthHeader() })
		.then((response: AxiosResponse) => {
			dispatch(DeleteSupplierReference(supplierEmail));
			successCallback();
		})
		.catch((error: AxiosError<ApiError>) => {
			if (error && error.response && error.response.data) {
				failureCallback(error.response.data.detail);
			} else console.error(error);
		});
}

export const addSupplierReference =
(
	candidateEmail:string,
	supplierEmail: string,
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
):AppThunkAction<SupplierActions> =>
(dispatch) => {
	defaultInstance
		.post(`/v1/candidates/${candidateEmail}/suppliers`, { supplierEmail, candidateEmail }, { headers: getAuthHeader() })
		.then((response: AxiosResponse) => {
			defaultInstance
				.get(`/v1/suppliers/${supplierEmail}`, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Supplier>) => {
					dispatch(AddSupplierReference(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		})
		.catch((error: AxiosError<ApiError>) => {
			if (error && error.response && error.response.data) {
				failureCallback(error.response.data.detail);
			} else console.error(error);
		});
}

export const getAllSuppliers = 
(
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
):AppThunkAction<SupplierActions> =>
(dispatch) => {
	defaultInstance
		.get(`/v1/suppliers`, { headers: getAuthHeader() })
		.then((response: AxiosResponse<string[]>) => {
			dispatch(SetAllSuppliersEmails(response.data));
			successCallback();
		})
		.catch((error: AxiosError<ApiError>) => {
			if (error && error.response && error.response.data) {
				failureCallback(error.response.data.detail);
			} else console.error(error);
		});
}

export const setSuppliers = (suppliers:Supplier[]) => {
	return (dispatch:Dispatch) => {
		dispatch(SetSuppliers(suppliers));
	}
}

export const updateSupplier = (supplier:Supplier, index:number) => {
	return (dispatch:Dispatch) => {
		dispatch(UpdateSupplier(supplier, index));
	}
}