import { useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Candidate, Language } from "src/models";
import { createLanguage } from "src/store/language";
import { selectLevels } from "src/store/level";
import CreateOrEditModal from "../CreateOrEditModal";
import FormInput from "../FormInput";

type Props = {
    candidate: Candidate;
}

export const CreateLanguageModal: React.FC<Props> = ({ candidate }) => {
    const dispatch = useDispatch();
    let levels = useSelector(selectLevels);
    const createNewLanguage = bindActionCreators(createLanguage, dispatch);

    const [language, setLanguage] = useState({
        name: '',
        candidateEmail: candidate.candidateEmail,
        level: ''
    } as Language);


    const resetExperience = () => setLanguage({
        name: '',
        candidateEmail: candidate.candidateEmail,
        level: ''
    } as Language);

    const saveData = (handleClose: () => void, handleError: (errors: string[]) => void) => createNewLanguage(language, candidate.candidateEmail, handleClose, handleError)

    return (
        <CreateOrEditModal label={'Add language'} title={'Add a new language'} resetModel={resetExperience} saveData={saveData}>
            <Table responsive='lg' borderless>
                <thead>
                    <tr>
                        <th>Language</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormInput
                                required
                                maxLength={50}
                                fieldValue={language.name}
                                setFieldValue={(value: string) =>
                                    setLanguage({ ...language, name: value })
                                }
                            />
                        </td>
                        <td>
                            <FormInput
                                type='select'
                                values={levels}
                                fieldValue={language.level}
                                setFieldValue={(value: string) =>
                                    setLanguage({ ...language, level: value })
                                }
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </CreateOrEditModal>
    );
}

export default CreateLanguageModal;