import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { StatusState } from './state';

const statusSelector = (state: ApplicationState) => state.statusFeature;

export const selectStatuses = createSelector(
	statusSelector,
	(statusState: StatusState) => statusState.statuses
);
export const selectError = createSelector(
	statusSelector,
	(statusState: StatusState) => statusState.error
);
export const selectLoading = createSelector(
	statusSelector,
	(statusState: StatusState) => statusState.loading
);
