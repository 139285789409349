import * as React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { User } from 'src/models';

import './index.css';

type Props = {
	onSignOut: () => void;
	onBrandClick: () => void;
	onCandidatesClick: () => void;
	onMyCvClick: () => void;
	user: User | undefined;
	canManageCandidates: boolean;
};

const Header: React.FC<Props> = ({
	onSignOut,
	onBrandClick,
	onCandidatesClick,
	onMyCvClick,
	user,
	canManageCandidates,
}) => {
	const color =
		process.env.REACT_APP_ENVIRONMENT === 'production'
			? '#ffd514'
			: '#80d616';

	return (
		<header>
			<Navbar
				className='navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3'
				expand='lg'
				style={{ backgroundColor: color, borderColor: color }}
			>
				<Container>
					<Navbar.Brand as={Nav.Link} onClick={onBrandClick}>
						<img
							src='https://myerp.capyx.be/img/header_logo.png'
							width='70'
							height='20'
							className='d-inline-block align-center'
							alt='ACVG'
						/>
					</Navbar.Brand>
					<Navbar.Toggle className='mr-2' aria-controls='navbar' />
					<Navbar.Collapse id='navbar'>
						<Nav navbar className='me-auto'>
							{user && (
								<>
									<Nav.Item>
										{canManageCandidates && (
											<Nav.Link
												onClick={onCandidatesClick}
											>
												Candidates
											</Nav.Link>
										)}
									</Nav.Item>
									<Nav.Item>
										<Nav.Link onClick={onMyCvClick}>
											My CV
										</Nav.Link>
									</Nav.Item>
								</>
							)}
						</Nav>
						<Nav as='ul'>
							{user && (
								<>
									<Nav.Item>
										<Nav.Link>{user.email}</Nav.Link>
									</Nav.Item>

									<Nav.Item>
										<Nav.Link
											className='text-dark'
											onClick={onSignOut}
										>
											Sign Out
										</Nav.Link>
									</Nav.Item>
								</>
							)}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>
	);
};

export default Header;
