import * as React from 'react';
import { Row, Table } from 'react-bootstrap';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
	AsideForm,
	CandidateForm,
	CreateCertificationModal,
	CreateEducationModal,
	CreateExperienceModal,
	CreateLanguageModal,
	CreateReferenceModal,
	CreateSupplierReferenceModal,
	HeaderForm,
	HeaderProps,
	LanguageForm,
	ListForm,
	SupplierForm,
} from 'src/components';
import IconButton from 'src/components/IconButton';
import {
	AsideLink,
	Certification,
	Education,
	Experience,
	Language,
	Reference,
	SkillCategory,
	Supplier,
} from 'src/models';
import { selectCandidate } from 'src/store/candidate';
import {
	getCertifications,
	selectCertifications,
} from 'src/store/certification';
import { getEducations, selectEducations } from 'src/store/education';
import { getExperiences, selectExperiences } from 'src/store/experience';
import { getLanguages, selectLanguages } from 'src/store/language';
import { selectSelectedProfile } from 'src/store/profile';
import { getReferences, selectReferences } from 'src/store/reference';
import { getSkills } from 'src/store/skill';
import { getSummaries, selectSummary } from 'src/store/summary';
import { getCandidateSuppliers, selectSuppliers } from 'src/store/supplier';

import CertificationPreview from 'src/components/CertificationPreview';
import EducationPreview from 'src/components/EducationPreview';
import ExperiencePreview from 'src/components/ExperiencePreview';
import ReferencePreview from 'src/components/ReferencePreview';
import SkillsContainer from 'src/components/SkillForm/SkillsContainer';
import SummaryPreview from 'src/components/SummaryPreview';
import { selectCanManageCandidates } from 'src/store/user';
import './index.css';

type Props = {
	header?: HeaderProps;
	profiles: string[];
	templates: string[];
	genders: string[];
	statuses: string[];
	levels: string[];
	isEdit?: boolean;
};

const CvForm: React.FC<Props> = ({
	header,
	profiles,
	templates,
	genders,
	statuses,
	levels,
	isEdit,
}) => {
	const canManageCandidates = useSelector(selectCanManageCandidates);
	const profile: string = useSelector(selectSelectedProfile);
	let candidate = useSelector(selectCandidate);
	let suppliers = useSelector(selectSuppliers);
	let summary = useSelector(selectSummary(profile));
	let references = useSelector(selectReferences());
	let educations = useSelector(selectEducations());
	let certifications = useSelector(selectCertifications());
	let experiences = useSelector(selectExperiences);
	let languages = useSelector(selectLanguages);

	const [currentTabIndex, setCurrentTabIndex] = React.useState(0);

	const dispatch = useDispatch();

	const asideData: AsideLink[] = [
		{
			formShown: currentTabIndex == 0,
			toggleForm: () => setCurrentTabIndex(0),
			label: 'Candidate',
		},
		{
			formShown: currentTabIndex == 1,
			toggleForm: () => {
				setCurrentTabIndex(1);
				const fetchSuppliers = bindActionCreators(
					getCandidateSuppliers,
					dispatch
				);
				fetchSuppliers(candidate.candidateEmail);
			},
			label: 'Suppliers',
			disabled: !canManageCandidates,
		},
		{
			formShown: currentTabIndex == 2,
			toggleForm: () => {
				setCurrentTabIndex(2);
				const fetchSummaries = bindActionCreators(
					getSummaries,
					dispatch
				);
				fetchSummaries(candidate.candidateEmail);
			},
			label: 'Summary',
		},
		{
			formShown: currentTabIndex == 3,
			toggleForm: () => {
				setCurrentTabIndex(3);
				const fetchReferences = bindActionCreators(
					getReferences,
					dispatch
				);
				fetchReferences(candidate.candidateEmail);
			},
			label: 'References',
		},
		{
			formShown: currentTabIndex == 4,
			toggleForm: () => {
				setCurrentTabIndex(4);
				const fetchFormations = bindActionCreators(
					getEducations,
					dispatch
				);
				fetchFormations(candidate.candidateEmail);
			},
			label: 'Educations',
		},
		{
			formShown: currentTabIndex == 5,
			toggleForm: () => {
				setCurrentTabIndex(5);
				const fetchCertifications = bindActionCreators(
					getCertifications,
					dispatch
				);
				fetchCertifications(candidate.candidateEmail);
			},
			label: 'Certifications',
		},
		{
			formShown: currentTabIndex == 6,
			toggleForm: () => {
				setCurrentTabIndex(6);
				const fetchExperiences = bindActionCreators(
					getExperiences,
					dispatch
				);
				fetchExperiences(candidate.candidateEmail);
			},
			label: 'Experiences',
		},
		{
			formShown: currentTabIndex == 7,
			toggleForm: () => {
				setCurrentTabIndex(7);
				const fetchLanguages = bindActionCreators(
					getLanguages,
					dispatch
				);
				fetchLanguages(candidate.candidateEmail);
			},
			label: 'Languages',
		},
		{
			formShown: currentTabIndex == 8,
			toggleForm: () => {
				setCurrentTabIndex(8);
				const fetchSkills = bindActionCreators(getSkills, dispatch);
				fetchSkills(
					candidate.candidateEmail,
					SkillCategory.SKILL_MANAGEMENT
				);
				fetchSkills(
					candidate.candidateEmail,
					SkillCategory.SKILL_TECHNICAL
				);
				fetchSkills(
					candidate.candidateEmail,
					SkillCategory.SKILL_OTHER
				);
			},
			label: 'Skills',
		},
	];

	asideData.forEach((a) => {
		if (!isEdit && a.label != 'Candidate') {
			a.disabled = true;
		}

		return a;
	});

	const pages = [
		<CandidateForm
			className={'mb-5'}
			genders={genders}
			statuses={statuses}
			candidate={candidate}
			isEdit={isEdit}
		/>,
		<ListForm
			title='Suppliers'
			buttonLabel='Add supplier'
			className={'mb-5'}
			ModalComponent={
				<CreateSupplierReferenceModal candidate={candidate} />
			}
		>
			{suppliers &&
				suppliers.map((s: Supplier, index: number) => (
					<React.Fragment key={index}>
						<SupplierForm
							supplier={s}
							index={index}
							candidate={candidate}
						/>
						<hr />
					</React.Fragment>
				))}
		</ListForm>,

		<SummaryPreview candidate={candidate} summary={summary} />,

		<ListForm
			title='References'
			buttonLabel='Add reference'
			className={'mb-5'}
			ModalComponent={<CreateReferenceModal candidate={candidate} />}
		>
			{references &&
				references.map((r: Reference, index: number) => (
					<React.Fragment key={index}>
						<ReferencePreview candidate={candidate} reference={r} />
						<hr />
					</React.Fragment>
				))}
		</ListForm>,

		<ListForm
			title='Educations'
			buttonLabel='Add education'
			className={'mb-5'}
			ModalComponent={<CreateEducationModal candidate={candidate} />}
		>
			{educations &&
				educations.map((education: Education, index: number) => (
					<React.Fragment key={index}>
						<EducationPreview
							candidate={candidate}
							education={education}
						/>
						<hr />
					</React.Fragment>
				))}
		</ListForm>,

		<ListForm
			title='Certifications'
			buttonLabel='Add certification'
			className={'mb-5'}
			ModalComponent={<CreateCertificationModal candidate={candidate} />}
		>
			{certifications &&
				certifications.map(
					(certification: Certification, index: number) => (
						<React.Fragment key={index}>
							<CertificationPreview
								candidate={candidate}
								certification={certification}
							/>
							<hr />
						</React.Fragment>
					)
				)}
		</ListForm>,

		<ListForm
			title='Experiences'
			buttonLabel='Add experience'
			className={'mb-5'}
			ModalComponent={<CreateExperienceModal candidate={candidate} />}
		>
			{experiences &&
				experiences.map((e: Experience, index: number) => (
					<React.Fragment key={index}>
						<ExperiencePreview
							candidate={candidate}
							experience={e}
						/>
						<hr />
					</React.Fragment>
				))}
		</ListForm>,

		<ListForm
			title='Languages'
			buttonLabel='Add language'
			className={'mb-5'}
			ModalComponent={<CreateLanguageModal candidate={candidate} />}
		>
			<Table responsive='lg' borderless>
				<thead>
					<tr>
						<th>Language</th>
						<th>Level</th>
					</tr>
				</thead>
				<tbody>
					{languages &&
						languages.map((l: Language, index: number) => (
							<LanguageForm
								key={index}
								language={l}
								candidate={candidate}
							/>
						))}
				</tbody>
			</Table>
		</ListForm>,

		<SkillsContainer candidateEmail={candidate.candidateEmail} />,
	];

	return (
		<>
			<Row>
				<HeaderForm
					buttonLabel={header?.buttonLabel}
					icon={header?.icon}
					profiles={profiles}
					templates={templates}
					candidateEmail={candidate.candidateEmail}
					onSubmit={header?.onSubmit}
					isEdit={isEdit}
					isLoading={header?.isLoading}
				/>
			</Row>
			<Row>
				{!profile && isEdit ? (
					<>You must select a profile first</>
				) : (
					<>
						<Row className='mb-3' md='auto'>
							<AsideForm asideData={asideData} />
						</Row>
						<Row>{pages[currentTabIndex]}</Row>
						{isEdit && (
							<div>
								<div style={{ float: 'right' }}>
									{currentTabIndex > 0 && (
										<IconButton
											className='navButton'
											label='Previous'
											variant='none'
											icon={<GrPrevious />}
											onClick={() =>
												setCurrentTabIndex(
													currentTabIndex - 1
												)
											}
										/>
									)}
									<div
										style={{
											marginLeft: '10px',
											display: 'inline',
										}}
									></div>

									{currentTabIndex < pages.length - 1 && (
										<IconButton
											className='navButton'
											label='Next'
											variant='none'
											icon={<GrNext />}
											isIconLeading={false}
											onClick={() =>
												setCurrentTabIndex(
													currentTabIndex + 1
												)
											}
										/>
									)}
								</div>
							</div>
						)}
					</>
				)}
			</Row>
		</>
	);
};

export default CvForm;
