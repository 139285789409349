import { Action, Dispatch } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError } from '../../models';
import { AppThunkAction } from '..';

export enum TemplateActionTypes {
	GET_TEMPLATES = 'GetTemplates',
	GET_TEMPLATES_SUCCESS = 'GetTemplatesSuccess',
	GET_TEMPLATES_FAILURE = 'GetTemplatesFailure',
	SET_SELECTED_TEMPLATE = 'SetSelectedTemplate'
}

// ACTIONS
export interface GetTemplatesAction extends Action {
	type: TemplateActionTypes.GET_TEMPLATES;
	loading: boolean;
}
const GetTemplates = (): GetTemplatesAction => {
	return { type: TemplateActionTypes.GET_TEMPLATES, loading: true };
};

export interface GetTemplatesActionSuccess extends Action {
	type: TemplateActionTypes.GET_TEMPLATES_SUCCESS;
	templates: string[];
}
const GetTemplatesSuccess = (
	templates: string[]
): GetTemplatesActionSuccess => {
	return { type: TemplateActionTypes.GET_TEMPLATES_SUCCESS, templates };
};

export interface GetTemplatesActionFailure extends Action {
	type: TemplateActionTypes.GET_TEMPLATES_FAILURE;
	error: string;
}
const GetTemplatesFailure = (error: string): GetTemplatesActionFailure => {
	return { type: TemplateActionTypes.GET_TEMPLATES_FAILURE, error };
};

export interface SetSelectedTemplateAction extends Action {
	type: TemplateActionTypes.SET_SELECTED_TEMPLATE;
	template: string;
}

const SetSelectedTemplate = (template: string): SetSelectedTemplateAction => {
	return { type: TemplateActionTypes.SET_SELECTED_TEMPLATE, template }
}

export type TemplateActions =
	| GetTemplatesAction
	| GetTemplatesActionSuccess
	| GetTemplatesActionFailure
	| SetSelectedTemplateAction;

// ACTION CREATORS
export const getTemplates =
	(
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<TemplateActions> =>
	(dispatch) => {
		dispatch(GetTemplates());
		defaultInstance
			.get('/v1/templates', { headers: getAuthHeader() })
			.then((response: AxiosResponse<string[]>) => {
				dispatch(GetTemplatesSuccess(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};

export const setSelectedStoreTemplate = (template: string) => {
	return (dispatch: Dispatch) => {
		dispatch(SetSelectedTemplate(template))
	}
}
