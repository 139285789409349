import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { CandidateState } from './state';

const candidateSelector = (state: ApplicationState) => state.candidateFeature;

export const selectCandidates = createSelector(
	candidateSelector,
	(candidateState: CandidateState) => candidateState.candidates
);

export const selectError = createSelector(
	candidateSelector,
	(candidateState: CandidateState) => candidateState.error
);

export const selectLoading = createSelector(
	candidateSelector,
	(candidateState: CandidateState) => candidateState.loading
);

export const selectCandidate = createSelector(
	candidateSelector,
	(candidateState: CandidateState) => candidateState.candidate
);

export const selectCandidatesWhereEmailLike = (candidateEmail: string) => createSelector(
	candidateSelector,
	(candidateState: CandidateState) => candidateState.candidates.filter(c => c.includes(candidateEmail))
);
