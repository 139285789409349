import { createSelector } from 'reselect';
import { Reference } from 'src/models';

import { ApplicationState } from '../';
import { ReferenceState } from './state';
import { reference } from '@popperjs/core';

const referenceSelector = (state: ApplicationState) => state.referenceFeature;

export const selectReferences = () => createSelector(
	referenceSelector,
	(ReferenceState: ReferenceState) =>
		ReferenceState.references
			.sort(function(a: Reference, b: Reference){
				if (a.order == -1 && b.order == -1) return 0;
				if (a.order == -1) return 1;
				if (b.order == -1) return -1;

				return a.order - b.order;
			})
			.filter(reference => reference)
);