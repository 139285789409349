import { Action, Reducer } from 'redux';
import { SummaryActions, SummaryActionTypes } from './actions';
import { SummaryState, unloadedState } from './state';


export const summaryReducer: Reducer<SummaryState> = (
	state: SummaryState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as SummaryActions;
	switch (action.type) {
		case SummaryActionTypes.SET_SUMMARIES:
			state.summaries = action.summaries;
			return { ...state }

		case SummaryActionTypes.UPDATE_SUMMARY:
			state.summaries = state.summaries.map((summary) => summary.id === action.summary.id ? action.summary : summary);
			return { ...state }

		case SummaryActionTypes.ADD_SUMMARY:
			state.summaries.push(action.summary);
			return { ...state }

		case SummaryActionTypes.DELETE_SUMMARY:
			state.summaries = state.summaries.filter(e => e.id !== action.summaryId);
			return { ...state }

		default:
			return state;
	}
};
