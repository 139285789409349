import { Action, Reducer } from 'redux';
import { Education } from 'src/models';
import { EducationActions, EducationActionTypes } from './actions';
import { EducationState, unloadedState } from './state';

//const allSameType = (educations:Education[], type:EducationType) => educations.every(e => e.type === type);

export const educationReducer: Reducer<EducationState> = (
	state: EducationState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as EducationActions;
	switch (action.type) {
		case EducationActionTypes.SET_EDUCATIONS:
			// if((allSameType(state.educations, EducationType.FORMATION) && allSameType(action.educations, EducationType.CERTIFICATION)) || allSameType(state.educations, EducationType.CERTIFICATION) && allSameType(action.educations, EducationType.FORMATION)){
			// 	state.educations = [...state.educations, ...action.educations];
			// } else {
				state.educations = action.educations;
			//}
			
			return { ...state }

		case EducationActionTypes.UPDATE_EDUCATION:
			state.educations = state.educations.map((education) => education.id === action.education.id ? action.education : education);
			return { ...state }

		case EducationActionTypes.ADD_EDUCATIONS:
			state.educations.push(...action.educations);
			return { ...state }

		case EducationActionTypes.DELETE_EDUCATION:
			state.educations = state.educations.filter(e => e.id !== action.educationId);
			return { ...state }

		default:
			return state;
	}
};
