import React from 'react';
import { Form } from 'react-bootstrap';

import { Reference } from 'src/models';
import { phoneRegex } from 'src/utils';
import FormInput from '../FormInput';

type Props = {
	reference: Reference;
	saveReference: (reference: Reference) => void;
};

export const ReferenceForm: React.FC<Props> = ({
	reference,
	saveReference,
}) => {
	return (
		<Form>
			<FormInput
				label='First name'
				required
				maxLength={50}
				fieldValue={reference.firstName}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, firstName: value })
				}
			/>
			<FormInput
				label='Last name'
				required
				maxLength={50}
				fieldValue={reference.lastName}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, lastName: value })
				}
			/>
			<FormInput
				label='Company'
				required
				maxLength={200}
				fieldValue={reference.company}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, company: value })
				}
			/>
			<FormInput
				label='Phone number'
				type='tel'
				maxLength={50}
				fieldValue={reference.phoneNumber}
				setFieldValue={(value: string) => {
					if (phoneRegex.test(value) || value === '') {
						saveReference({ ...reference, phoneNumber: value });
					}
				}}
			/>
			<FormInput
				label='Email'
				type='email'
				fieldValue={reference.referenceEmail}
				maxLength={255}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, referenceEmail: value })
				}
			/>
			<FormInput
				label='Professional relationship type'
				type='textarea'
				required
				maxLength={600}
				fieldValue={reference.relationship}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, relationship: value })
				}
			/>
			<FormInput
				label='Order of appearance'
				type='number'
				min='-1'
				required
				helpText='The greater the number, the further down in the list the reference will be. If -1, then the reference is not displayed.'
				fieldValue={reference.order}
				setFieldValue={(value: string) =>
					saveReference({ ...reference, order: Number(value) })
				}
			/>
		</Form>
	);
};
