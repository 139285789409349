import { Action, Dispatch } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError, Reference } from '../../models';
import { AppThunkAction } from '../';

export enum ReferenceActionTypes {
	SET_REFERENCES = 'SetReferences',
	UPDATE_REFERENCE = 'UpdateReference',
	ADD_REFERENCE = 'AddReference',
	DELETE_REFERENCE = 'DeleteReference'

}

// ACTIONS
export interface SetReferencesAction extends Action {
	type: ReferenceActionTypes.SET_REFERENCES;
	references: Reference[]
}
const SetReferences = (references: Reference[]): SetReferencesAction => {
	return { type: ReferenceActionTypes.SET_REFERENCES, references: references };
};

export interface UpdateReferenceAction extends Action {
	type: ReferenceActionTypes.UPDATE_REFERENCE;
	reference: Reference;
	//index: number;
}
const UpdateReference = (reference: Reference): UpdateReferenceAction => {
	return { type: ReferenceActionTypes.UPDATE_REFERENCE, reference: reference };
};

export interface AddReferenceAction extends Action {
	type: ReferenceActionTypes.ADD_REFERENCE;
	reference: Reference;
};
const AddReference = (reference: Reference): AddReferenceAction => {
	return { type: ReferenceActionTypes.ADD_REFERENCE, reference }
};

export interface DeleteReferenceAction extends Action {
	type: ReferenceActionTypes.DELETE_REFERENCE;
	referenceId: string;
};
const DeleteReference = (referenceId: string): DeleteReferenceAction => {
	return { type: ReferenceActionTypes.DELETE_REFERENCE, referenceId }
};


export type ReferenceActions = SetReferencesAction | UpdateReferenceAction | AddReferenceAction | DeleteReferenceAction;

// ACTION CREATORS

export const getReferences =
	(
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<ReferenceActions> =>
		(dispatch) => {
			defaultInstance
				.get(`/v1/candidates/${candidateEmail}/references/details`, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Reference[]>) => {
					response.data.map(r =>{
						r.referenceEmail = r.referenceEmail ?? '';
						r.phoneNumber = r.phoneNumber ?? '';
					})
					dispatch(SetReferences(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		};

export const saveReference =
	(
		reference: Reference,
		candidateEmail: string,
		referenceId: string,
		// index: number,
		successCallback: () => void = () => { },
		failureCallback: (error?: string[]) => void = () => { }
	): AppThunkAction<ReferenceActions> =>
		(dispatch) => {
			defaultInstance.put(
				`/v1/candidates/${candidateEmail}/references/${referenceId}`,
				reference,
				{ headers: getAuthHeader() }
			)
				.then((response: AxiosResponse<Reference>) => {
					const r = response.data;
					r.referenceEmail = r.referenceEmail ?? '';
					r.phoneNumber = r.phoneNumber ?? '';
					dispatch(UpdateReference(r));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.detail).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const createReference =
	(
		reference: Reference,
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<ReferenceActions> =>
		(dispatch) => {
			defaultInstance
				.post(`v1/candidates/${candidateEmail}/references`, reference, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Reference>) => {
					dispatch(AddReference(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		}

export const deleteReference =
	(
		candidateEmail: string,
		referenceId: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<ReferenceActions> =>
		(dispatch) => {
			defaultInstance
				.delete(`/v1/candidates/${candidateEmail}/references/${referenceId}`, { headers: getAuthHeader() })
				.then((response: AxiosResponse) => {
					dispatch(DeleteReference(referenceId));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		}

export const setReferences = (references: Reference[]) => {
	return (dispatch: Dispatch) => {
		dispatch(SetReferences(references));
	}
}

export const updateReference = (reference: Reference) => {
	return (dispatch: Dispatch) => {
		dispatch(UpdateReference(reference));
	}
}