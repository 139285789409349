export interface TemplateState {
	templates: string[];
	selectedTemplate: string;
	error: string;
	loading: boolean;
}

export const unloadedState: TemplateState = {
	templates: [],
	selectedTemplate: "",
	error: null,
	loading: false,
};
