import debounce from 'lodash.debounce';
import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Candidate, Supplier } from 'src/models';
import { deleteSupplierReference, saveSupplier, updateSupplier } from 'src/store/supplier';
import DeleteButton from '../DeleteButton';
import FormInput from '../FormInput';

type Props = {
	className?: string | undefined;
	supplier: Supplier;
	index?:number | undefined;
	candidate: Candidate;
};

export const SupplierForm: React.FC<Props> = ({ className, supplier, index, candidate }) => {
	const dispatch = useDispatch();
	const updateSupplierState = bindActionCreators(updateSupplier, dispatch);
	const updateSupplierInDb = bindActionCreators(saveSupplier, dispatch);
	const deleteSupplierReferenceInDb = bindActionCreators(deleteSupplierReference, dispatch);
	const deleteData = (successCallback: () => void) => deleteSupplierReferenceInDb(candidate.candidateEmail, supplier.supplierEmail, successCallback);
	const save = (s:Supplier) => updateSupplierState(s, index);

	const onDebounce = useMemo(
		() =>
			debounce((supp:Supplier) => {
				supplier = { ...supplier, ...supp };

				const supplierEmail = supplier.supplierEmail;

				updateSupplierInDb(supplier, supplierEmail);

			}, 500),
		[debounce]
	);

	return (
		<section className={className}>
			<Form>
				<FormInput 
					label='First name' 
					required 
					maxLength={50}
					fieldValue={supplier.firstName}
					setFieldValue={(value: string) =>
						save({ ...supplier, firstName : value })
					}
					saveFieldvalue={(value:string) =>
						onDebounce({ ...supplier, firstName: value })
					}
				/>
				<FormInput 
					label='Last name' 
					required 
					maxLength={50}
					fieldValue={supplier.lastName}
					setFieldValue={(value: string) =>
						save({ ...supplier, lastName : value })
					}
					saveFieldvalue={(value:string) =>
						onDebounce({ ...supplier, lastName: value })
					}
				/>
				<FormInput 
					label='Company' 
					required 
					maxLength={50}
					fieldValue={supplier.company}
					setFieldValue={(value: string) =>
						save({ ...supplier, company : value })
					}
					saveFieldvalue={(value:string) =>
						onDebounce({ ...supplier, company: value })
					}
				/>
				<FormInput 
					label='VAT number' 
					required 
					maxLength={50}
					fieldValue={supplier.vat}
					setFieldValue={(value: string) =>
						save({ ...supplier, vat : value })
					}
					saveFieldvalue={(value:string) =>
						onDebounce({ ...supplier, vat: value })
					}
				/>
				<FormInput
					label='Phone number'
					type='tel'
					required
					maxLength={50}
					fieldValue={supplier.phoneNumber}
					setFieldValue={(value: string) =>
						save({ ...supplier, phoneNumber : value })
					}
					saveFieldvalue={(value:string) =>
						onDebounce({ ...supplier, phoneNumber: value })
					}
				/>
				<FormInput 
					label='Email' 
					type='email' 
					disabled
					fieldValue={supplier.supplierEmail}
				/>
				<DeleteButton deleteData={deleteData} />
			</Form>
		</section>
	)
};

export * from './Modal'