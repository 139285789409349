import { Action, Reducer } from 'redux';

import { filterAndSort } from 'src/utils';
import { LevelActions, LevelActionTypes } from './actions';
import { unloadedState, LevelState } from './state';

export const levelReducer: Reducer<LevelState> = (
	state: LevelState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as LevelActions;
	switch (action.type) {
		case LevelActionTypes.GET_LEVELS:
			return { ...state, loading: true };
		case LevelActionTypes.GET_LEVELS_SUCCESS:
			return {
				...state,
				loading: false,
				levels: action.levels,
			};
		case LevelActionTypes.GET_LEVELS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			};
		default:
			return state;
	}
};
