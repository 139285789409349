import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { GenderState } from './state';

const genderSelector = (state: ApplicationState) => state.genderFeature;

export const selectGenders = createSelector(
	genderSelector,
	(genderState: GenderState) => genderState.genders
);
export const selectError = createSelector(
	genderSelector,
	(genderState: GenderState) => genderState.error
);
export const selectLoading = createSelector(
	genderSelector,
	(genderState: GenderState) => genderState.loading
);
