import { Reference } from 'src/models';

export interface ReferenceState {
	references: Reference[];
}

export const unloadedState: ReferenceState = {
	references: []
};

export const reset: () => ReferenceState = () => {
	return {
		references: []
	} as ReferenceState
}
