import React, { ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Candidate } from 'src/models';

type ModalProps = {
	candidate: Candidate;
};

type Props = {
	className?: string | undefined;
	title: string;
	buttonLabel: string;
	onButtonClick?: () => void;
	ModalComponent?: ReactElement | undefined;
};

export const ListForm: React.FC<Props> = ({
	className,
	title,
	buttonLabel,
	onButtonClick,
	children,
	ModalComponent,
}) => {
	if (!className.includes('align-items-center'))
		className += ' align-items-center';

	return (
		<Col className='none'>
			<Row>
				<Col>
					<header className='mb-5'>
						<h3>{title}</h3>
					</header>
				</Col>
				<Col>
					<div style={{ float: 'right' }}>{ModalComponent}</div>
				</Col>
			</Row>
			<Row>{children}</Row>
		</Col>
	);
};

export default ListForm;
