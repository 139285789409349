import { Skill, SkillCategory } from "src/models";
import CreateSkillModal from "./Modal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectManagementSkills, selectOtherSkills, selectTechnicalSkills } from "src/store/skill";
import { selectSelectedProfile } from "src/store/profile";
import React from "react";
import { SkillForm } from ".";
import { Badge, Col, ListGroup, Row } from "react-bootstrap";
import ListForm from "../ListForm";
import { selectCandidate } from "src/store/candidate";

type Props = {
    candidateEmail: string | undefined;
}

export const SkillsContainer: React.FC<Props> = ({ candidateEmail }) => {
    const profile: string = useSelector(selectSelectedProfile);
    let candidate = useSelector(selectCandidate);
    const managementSkills = useSelector(selectManagementSkills(profile));
	const technicalSkills = useSelector(selectTechnicalSkills(profile));
	const otherSkills = useSelector(selectOtherSkills(profile));

    return (
        <>
        <Row>
            <ListForm
                title='Management skills'
                buttonLabel='Add skill(s)'
                className={'mb-5'}
                ModalComponent={
                    <CreateSkillModal
                        candidateEmail={candidate.candidateEmail}
                        category={SkillCategory.SKILL_MANAGEMENT}
                    />
                }
            >
                {managementSkills && (
                    <React.Fragment>
                        <SkillForm skills={managementSkills} />
                    </React.Fragment>
                )}
            </ListForm>
        </Row>
        <hr/>
        <Row>
            <ListForm
                title='Technical Skills'
                buttonLabel='Add skill(s)'
                className={'mb-5'}
                ModalComponent={
                    <CreateSkillModal
                        candidateEmail={candidate.candidateEmail}
                        category={SkillCategory.SKILL_TECHNICAL}
                    />
                }
            >
                {technicalSkills && (
                    <React.Fragment>
                        <SkillForm skills={technicalSkills} />
                    </React.Fragment>
                )}
            </ListForm>
        </Row>
        <hr/>
		<Row>
            <ListForm
                title='Other Skills'
                buttonLabel='Add skill(s)'
                className={'mb-5'}
                ModalComponent={
                    <CreateSkillModal
                        candidateEmail={candidate.candidateEmail}
                        category={SkillCategory.SKILL_OTHER}
                    />
                }
            >
                {otherSkills && (
                    <React.Fragment>
                        <SkillForm skills={otherSkills} />
                    </React.Fragment>
                )}
            </ListForm>
        </Row>
        </>
	);
}

export default SkillsContainer;