import { Experience } from 'src/models';

export interface ExperienceState {
	experiences: Experience[];
}

export const unloadedState: ExperienceState = {
	experiences: []
};

export const reset: () => ExperienceState = () => {
	return {
		experiences: []
	} as ExperienceState
}