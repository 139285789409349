import { User, Token } from '../../models';

export interface UserState {
	email: string;
	user: User;
	token: Token;
	error: string;
	loading: boolean;
}

export const unloadedState: UserState = {
	user: null,
	token: null,
	email: null,
	error: null,
	loading: false,
};
