import { Action, Reducer } from 'redux';

import { filterAndSort, formatSpaces } from 'src/utils';
import { ProfileActionTypes, ProfileActions } from './actions';
import { ProfileState, unloadedState } from './state';

export const profileReducer: Reducer<ProfileState> = (
	state: ProfileState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as ProfileActions;
	switch (action.type) {

		case ProfileActionTypes.GET_PROFILES:
			return { ...state, loading: true };

		case ProfileActionTypes.GET_PROFILES_SUCCESS:
			return {
				...state,
				loading: false,
				profiles: filterAndSort(action.profiles),
			};

		case ProfileActionTypes.GET_PROFILES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		case ProfileActionTypes.SET_SELECTED_PROFILE:
			state.selectedProfile = action.profile;	
			return { ...state };

		case ProfileActionTypes.SET_EXISTING_PROFILES:
			state.existingProfiles = filterAndSort(action.existingProfiles);
			return { ...state };

		case ProfileActionTypes.ADD_EXISTING_PROFILES:
			if (!state.existingProfiles.includes(action.existingProfile))
				state.existingProfiles.push(formatSpaces(action.existingProfile));
			return { ...state };
		
		default:
			return state;
	}
};
