import { ReactElement, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { MdAdd, MdEdit } from 'react-icons/md';

import IconButton from '../IconButton';

import './style.css';

type Props = {
	label: string;
	title: string;
	resetModel: () => void;
	saveData: (
		handleClose: () => void,
		handleError: (errors: string[]) => void,
		resetValidation?: () => void | null
	) => void;
	showActions?: boolean;
	isEdit?: boolean;
	onCancel?: () => void;
	onShow?: () => void;
	additionnalOption?: ReactElement | null;
};

export const CreateOrEditModal: React.FC<Props> = ({
	label,
	title,
	resetModel,
	saveData,
	showActions = true,
	isEdit = false,
	onCancel = null,
	onShow = null,
	additionnalOption = null,
	children,
}) => {
	const formRef = useRef(null);
	const [errors, setErrors] = useState([]);
	const [hasError, sethasError] = useState(false);
	const [show, setShow] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setLoading] = useState(false);

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
		}
		setValidated(false);
	};

	const handleError = (errors: string[]) => {
		setErrors(errors);
		sethasError(true);
		setLoading(false);
	};

	const handleClose = () => {
		setShow(false);
		setLoading(false);
		sethasError(false);
		setErrors(null);
		resetModel();
		handleReset();
	};

	const handleCancel = () => {
		if (onCancel !== null) onCancel();
		handleClose();
		resetModel();
	};

	const handleSave = (event) => {
		const form = formRef.current;

		event.preventDefault();

		if (form.checkValidity()) {
			setLoading(true);
			saveData(handleClose, handleError, resetValidation);
		}

		setValidated(true);
	};

	const handleShow = () => {
		if (onShow !== null) onShow();
		setShow(true);
	};

	const resetValidation = () => {
		const form = formRef.current;
		if (form) {
			setValidated(false);

			for (const element of form.elements) {
				element.setCustomValidity('');
				element.classList.remove('is-invalid');
				element.classList.remove('is-valid');

				if (element.type !== 'submit' && element.type !== 'button') {
					element.value = '';
				}
			}

			form.reset();
		}
	};

	return (
		<>
			<IconButton
				onClick={handleShow}
				variant={isEdit ? 'warning' : 'primary'}
				icon={
					isEdit ? <MdEdit color='white' /> : <MdAdd color='white' />
				}
				label={label}
			/>

			<Modal
				show={show}
				onHide={handleCancel}
				animation={false}
				dialogClassName='my-modal'
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{hasError && errors && errors.length > 0 && (
						<div className='alert alert-danger' role='alert'>
							{errors.length === 1 ? (
								<p className='mb-0'>{errors[0]}</p>
							) : (
								<ul className='mb-0'>
									{errors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							)}
						</div>
					)}
					<Form
						ref={formRef}
						noValidate
						validated={validated}
						onSubmit={handleSave}
					>
						{children}
					</Form>
				</Modal.Body>
				{showActions && (
					<Modal.Footer>
						<Button variant='danger' onClick={handleCancel}>
							Cancel
						</Button>
						{additionnalOption}
						<Button
							type='submit'
							onClick={handleSave}
							variant='success'
							disabled={isLoading}
						>
							{!isLoading ? (
								label
							) : (
								<Spinner animation='border' variant='dark' />
							)}
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
};

export default CreateOrEditModal;
