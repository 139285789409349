import { Col, Row } from 'react-bootstrap';
import { Education } from 'src/models';
import FormInput from '../FormInput';

type Props = {
	education: Education;
	saveEducation: (education: Education) => void;
};

export const EducationForm: React.FC<Props> = ({
	education,
	saveEducation,
}) => {
	return (
		<>
			<Row>
				<Col>
					<FormInput
						label='School'
						required
						fieldValue={education.school}
						setFieldValue={(value: string) =>
							saveEducation({
								...education,
								school: value,
							})
						}
					/>
				</Col>
				<Col>
					<FormInput
						label='Faculty/Major'
						required
						fieldValue={education.faculty}
						setFieldValue={(value: string) =>
							saveEducation({
								...education,
								faculty: value,
							})
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormInput
						label='Degree'
						fieldValue={education.degree}
						setFieldValue={(value: string) =>
							saveEducation({
								...education,
								degree: value,
							})
						}
					/>
				</Col>
				<Col>
					<FormInput
						label='Year'
						fieldValue={education.year}
						setFieldValue={(value: number) =>
							saveEducation({
								...education,
								year: value,
							})
						}
					/>
				</Col>
			</Row>
		</>
	);
};
