import { Certification } from 'src/models/Certification';

export interface CertificationState {
	certifications: Certification[];
}

export const unloadedState: CertificationState = {
	certifications: []
};

export const reset: () => CertificationState = () => {
	return {
		certifications: []
	} as CertificationState
}