import { push } from 'connected-react-router';
import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { MdOutlineSearch, MdVisibility } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { deleteCandidate, selectCandidates } from 'src/store/candidate';
import { ResetCandidate } from 'src/store/root/actions';

import DeleteButton from 'src/components/DeleteButton';
import FormInput from '../../components/FormInput';
import IconButton from '../../components/IconButton';

type Props = {
	className?: string | undefined;
};

const CandidatesList: React.FC<Props> = ({ className }) => {
	const dispatch: Dispatch<any> = useDispatch();
	const candidateEmails: string[] = useSelector(selectCandidates);
	let [searchQuery, setSearchQuery] = React.useState('');
	let [resultCandidates, setResultCandidates] =
		React.useState(candidateEmails);

	const goToEdit = (email: string) => {
		dispatch(ResetCandidate());
		dispatch(push(`/candidates/${email}`));
	};

	const updateCandidatesList = (candidateEmail: string) => {
		setSearchQuery(candidateEmail);
		setResultCandidates(
			candidateEmails.filter((c) =>
				c.toLowerCase().includes(candidateEmail.toLowerCase())
			)
		);
	};

	const deleteCandidateFromDb = bindActionCreators(deleteCandidate, dispatch);

	return (
		<Container>
			<FormInput
				type='email'
				placeholder='firstname.lastname@capyx.be'
				values={candidateEmails}
				shouldAutoComplete={true}
				fieldValue={searchQuery}
				setFieldValue={updateCandidatesList}
				icon={<MdOutlineSearch />}
			/>
			<Table responsive='lg' borderless>
				<thead>
					<tr>
						<th>Email</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{resultCandidates.map((candidateEmail) => (
						<tr key={candidateEmail}>
							<td>{candidateEmail}</td>
							<td>
								<div>
									<div
										className='d-inline'
										style={{ marginRight: '10px' }}
									>
										<IconButton
											label="View Candidate's CV"
											icon={<MdVisibility />}
											onClick={() =>
												goToEdit(candidateEmail)
											}
										/>
									</div>
									<div className='d-inline'>
										<DeleteButton
											deleteData={(successCallback) =>
												deleteCandidateFromDb(
													candidateEmail,
													() => {
														successCallback();
														setResultCandidates(
															candidateEmails.filter(
																(c) =>
																	c !=
																	candidateEmail
															)
														);
													}
												)
											}
										/>
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			{resultCandidates.length === 0 && <p>No candidates found.</p>}
		</Container>
	);
};

export default CandidatesList;
