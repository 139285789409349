import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { UserState } from './state';

const userSelectors = (state: ApplicationState) => state.userFeature;

export const selectUser = createSelector(
	userSelectors,
	(userState: UserState) => userState.user
);

export const selectCanManageCandidates = createSelector(
	userSelectors,
	(userState: UserState) =>
		[1, 2, 3, 5, 6, 8, 9, 10, 14].includes(userState.user?.roleId)
);

export const selectToken = createSelector(
	userSelectors,
	(userState: UserState) => userState.token
);
export const selectEmail = createSelector(
	userSelectors,
	(userState: UserState) => userState.email
);
export const selectError = createSelector(
	userSelectors,
	(userState: UserState) => userState.error
);
export const selectLoading = createSelector(
	userSelectors,
	(userState: UserState) => userState.loading
);
