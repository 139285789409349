export interface GenderState {
	genders: string[];
	error: string;
	loading: boolean;
}

export const unloadedState: GenderState = {
	genders: [],
	error: null,
	loading: false,
};
