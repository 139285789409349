import { Col, Row } from "react-bootstrap";
import { Certification } from "src/models";
import FormInput from "../FormInput";

type Props = {
    certification: Certification;
    saveCertification: (certification: Certification) => void;
};

export const CertificationForm: React.FC<Props> = ({ certification, saveCertification }) => {
    return (
        <>
            <Row>
                <Col>
                    <FormInput
                        label='Certification Name'
                        required
                        maxLength={100}
                        fieldValue={certification.certificationName}
                        setFieldValue={(value: string) =>
                            saveCertification({
                                ...certification,
                                certificationName: value,
                            })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormInput
                        label='Certifying Organization'
                        maxLength={100}
                        fieldValue={certification.organization}
                        setFieldValue={(value: string) =>
                            saveCertification({
                                ...certification,
                                organization: value,
                            })
                        }
                    />
                </Col>
                <Col>
                    <FormInput
                        label='Year'
                        fieldValue={certification.year}
                        setFieldValue={(value: number) =>
                            saveCertification({
                                ...certification,
                                year: value,
                            })
                        }
                    />
                </Col>
            </Row>
        </>
    );
}