import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useEffect, useState } from 'react';
import { Candidate, Reference } from 'src/models';
import {
	saveReference,
} from 'src/store/reference';
import CreateOrEditModal from '../CreateOrEditModal';
import { ReferenceForm } from './Form';

type Props = {
	candidate: Candidate;
	reference: Reference;
};

export const UpdateReferenceModal: React.FC<Props> = ({
	candidate,
	reference,
}) => {
	const dispatch = useDispatch();
	const saveReferenceAction = bindActionCreators(saveReference, dispatch);

	const [initialReferenceState, setInitialReferenceState] = useState(null);
	const [internalReference, setInternalReference] = useState(null);

	useEffect(() => {
		setInitialReferenceState(reference);
		setInternalReference(reference);
	}, [reference]);

	const onCancel = () => {
		setInternalReference({ ...initialReferenceState });
	};

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		saveReferenceAction(
			internalReference,
			candidate.candidateEmail,
			reference.id,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Edit'}
			title={'Edit reference'}
			isEdit={true}
			resetModel={() => { }}
			saveData={saveData}
			onCancel={onCancel}
		>
			<ReferenceForm
				reference={internalReference}
				saveReference={setInternalReference}
			/>
		</CreateOrEditModal>
	);
};

export default UpdateReferenceModal;
