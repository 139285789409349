import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useEffect, useState } from 'react';
import { Candidate, Education } from 'src/models';
import {
	saveEducation,
} from 'src/store/education';
import CreateOrEditModal from '../CreateOrEditModal';
import { EducationForm } from './Form';

type Props = {
	candidate: Candidate;
	education: Education;
};

export const UpdateEducationModal: React.FC<Props> = ({
	candidate,
	education,
}) => {
	const dispatch = useDispatch();
	const saveEducationAction = bindActionCreators(saveEducation, dispatch);

	const [initialEducationState, setInitialEducationState] = useState(null);
	const [internalEducation, setInternalEducation] = useState(null);

	useEffect(() => {
		setInitialEducationState(education);
		setInternalEducation(education);
	}, [education]);

	const onCancel = () => {
		setInternalEducation({ ...initialEducationState });
	};

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		saveEducationAction(
			internalEducation,
			candidate.candidateEmail,
			education.id,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Edit'}
			title={'Edit education'}
			isEdit={true}
			resetModel={() => { }}
			saveData={saveData}
			onCancel={onCancel}
		>
			<EducationForm
				education={internalEducation}
				saveEducation={setInternalEducation}
			/>
		</CreateOrEditModal>
	);
};

export default UpdateEducationModal;
