import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Candidate, Experience } from 'src/models';
import { Description } from 'src/models/Description';
import { deleteDescription, deleteExperience } from 'src/store/experience';
import { selectSelectedProfile } from 'src/store/profile';
import DeleteButton from '../DeleteButton';
import CreateDescriptionModal from '../DescriptionForm/Modal';
import Editor from '../Editor';
import UpdateExperienceModal from '../ExperienceForm/UpdateExperienceModal';

type Props = {
	className?: string | undefined;
	candidate: Candidate;
	experience: Experience;
};

type LabelDetailsProps = {
	label: string;
	details: string;
};

export const LabelDetails: React.FC<LabelDetailsProps> = ({
	label,
	details,
}) => {
	return (
		<div style={{ marginBottom: '10px' }}>
			<div style={{ fontWeight: 'bold' }}>{label} : </div>
			<div>{details}</div>
		</div>
	);
};

export const ExperiencePreview: React.FC<Props> = ({
	className,
	candidate,
	experience,
}) => {
	const profile = useSelector(selectSelectedProfile);
	const dispatch = useDispatch();
	const deleteExperienceFromDb = bindActionCreators(
		deleteExperience,
		dispatch
	);
	const deleteDescriptionFromDb = bindActionCreators(
		deleteDescription,
		dispatch
	);

	const description: Description | undefined = experience.descriptions.find(
		(desc) => desc.profile == profile
	);

	return (
		<>
			<div
				style={{
					fontWeight: 'bold',
					marginBottom: '10px',
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ alignSelf: 'center' }}>
					{experience.role} | {experience.company}{' '}
					{experience.location ? `(${experience.location})` : ''} |{' '}
					{experience.startDate} -{' '}
					{experience.endDate ? experience.endDate : 'Present'}
				</div>

				<div style={{ float: 'right' }}>
					<UpdateExperienceModal
						candidate={candidate}
						experience={experience}
						description={description}
					/>
					<div
						style={{ marginLeft: '10px', display: 'inline' }}
					></div>
					<DeleteButton
						deleteData={(successCallback) =>
							deleteExperienceFromDb(
								candidate.candidateEmail,
								experience.id,
								successCallback
							)
						}
					/>
				</div>
			</div>

			<div>
				{experience.client && (
					<div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
						{experience.client}
					</div>
				)}
				<div style={{ marginBottom: '10px' }}>
					<span style={{ whiteSpace: 'pre-line' }}>
						{description === undefined ? (
							<CreateDescriptionModal
								candidate={candidate}
								experience={experience}
							/>
						) : (
							<>
								<div
									style={{
										fontWeight: 'bold',
										marginBottom: '10px',
										display: 'flex',
										justifyContent: 'end',
									}}
								>
									<div>
										<DeleteButton
											label='Delete description'
											deleteData={(successCallback) => {
												deleteDescriptionFromDb(
													candidate.candidateEmail,
													description.experienceId,
													description.profile,
													successCallback
												);
											}}
										/>
									</div>
								</div>

								<Editor
									readonly
									value={description.longDescription}
								/>
								{/* {description.longDescription} */}
							</>
						)}
					</span>
				</div>

				<LabelDetails
					label='Environment'
					details={experience.environment}
				/>
				<LabelDetails label='Tools' details={experience.tools} />
				<LabelDetails
					label='Technologies'
					details={experience.technologies}
				/>
				<LabelDetails
					label='Methodology'
					details={experience.methodology}
				/>
			</div>
		</>
	);
};

export default ExperiencePreview;
