import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useEffect, useState } from 'react';
import { Candidate, Experience } from 'src/models';
import { Description } from 'src/models/Description';
import {
	saveExperienceAndDescription,
	updateDescription,
	updateExperience,
} from 'src/store/experience';
import { selectSelectedProfile } from 'src/store/profile';
import CreateOrEditModal from '../CreateOrEditModal';
import { ExperienceForm } from './Form';

type Props = {
	candidate: Candidate;
	experience: Experience;
	description: Description;
};

export const UpdateExperienceModal: React.FC<Props> = ({
	candidate,
	experience,
	description,
}) => {
	const profile: string = useSelector(selectSelectedProfile);
	const dispatch = useDispatch();

	const updateExperienceState = bindActionCreators(
		updateExperience,
		dispatch
	);
	const updateDescriptionState = bindActionCreators(
		updateDescription,
		dispatch
	);

	const saveExperienceAndDescriptionAction = bindActionCreators(
		saveExperienceAndDescription,
		dispatch
	);

	const [initialExperienceState, setInitialExperienceState] = useState(null);
	const [initialDescriptionState, setInitialDescriptionState] =
		useState(null);
	const [internalExperience, setInternalExperience] = useState(null);
	const [internalDescription, setInternalDescription] = useState(null);

	useEffect(() => {
		setInitialExperienceState(experience);
		setInternalExperience(experience);
	}, [experience]);

	useEffect(() => {
		setInitialDescriptionState(description);
		setInternalDescription(description);
	}, [description]);

	const onCancel = () => {
		updateExperienceState({ ...initialExperienceState });
		updateDescriptionState({ ...initialDescriptionState });
	};

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		saveExperienceAndDescriptionAction(
			internalExperience,
			internalDescription,
			candidate.candidateEmail,
			experience.id,
			profile,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Edit'}
			title={'Edit experience'}
			isEdit={true}
			resetModel={() => {}}
			saveData={saveData}
			onCancel={onCancel}
		>
			<ExperienceForm
				experience={internalExperience}
				description={internalDescription}
				saveExperience={setInternalExperience}
				saveDescription={setInternalDescription}
			/>
		</CreateOrEditModal>
	);
};

export default UpdateExperienceModal;
