import React, { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { GrDocumentPdf } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { bindActionCreators } from 'redux';
import LoadingPage from 'src/components/LoadingPage';
import CvForm from 'src/containers/CvForm';
import useEmailParameter from 'src/hooks/useEmailParameter';
import {
	getCandidate,
	selectCandidate,
	setCandidate,
} from 'src/store/candidate';
import { getStatuses, selectStatuses } from 'src/store/employeeStatus';
import { getGenders, selectGenders } from 'src/store/gender';
import { getLevels, selectLevels } from 'src/store/level';
import { generatePdf } from 'src/store/pdf/actions';
import { getExistingProfiles, getProfiles, selectProfiles } from 'src/store/profile';
import { getTemplates, selectTemplates } from 'src/store/template';

const CreateOrUpdateCandidate: React.FC = () => {
	//#region
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	const successCallback = () => { };
	const errorCallback = (errors: string[]) => {
		errors.forEach(error => {
			addToast(
				error
					? `The following error occured: ${error}`
					: 'An error occured',
				{ appearance: 'error' }
			);
		});
	};

	const [isLoading, setLoading] = useState(false);

	const profiles = useSelector(selectProfiles);
	const genders = useSelector(selectGenders);
	const statuses = useSelector(selectStatuses);
	const levels = useSelector(selectLevels);
	const templates = useSelector(selectTemplates);
	const { email, isEdit } = useEmailParameter();
	let candidate = useSelector(selectCandidate);

	const createPdf = bindActionCreators(generatePdf, dispatch);

	const getSelectItems = React.useCallback(() => {
		if (isEdit) {
			dispatch(getCandidate(email, successCallback, errorCallback));
			dispatch(getExistingProfiles(email, successCallback, errorCallback));
		}
		dispatch(getGenders(successCallback, errorCallback));
		dispatch(getStatuses(successCallback, errorCallback));
		dispatch(getProfiles(successCallback, errorCallback));
		dispatch(getLevels(successCallback, errorCallback));
		dispatch(getTemplates(successCallback, errorCallback));
	}, [dispatch]);

	const shouldLoad =
		(isEdit && candidate.candidateEmail !== email) ||
		(isEdit && !candidate) ||
		genders.length === 0 ||
		statuses.length === 0 ||
		profiles.length === 0 ||
		levels.length === 0 ||
		templates.length === 0;

	React.useEffect(() => {
		if (shouldLoad) {
			getSelectItems();
		}

		return () => {
			//onDebouncedCandidateChange.cancel();
			// TODO: do something with dispatch(resetCandidate());
		};
	}, [shouldLoad]);
	//#endregion

	if (shouldLoad) return <LoadingPage />;

	return (
		<CvForm
			header={
				isEdit
					? {
						buttonLabel: 'Generate PDF',
						icon: <GrDocumentPdf />,
						onSubmit: (
							email: string,
							profile: string,
							template: string,
							title: string | null = null
						) => {
							if (!profile || !template) {
								addToast('A profile and template must be selected !', { appearance: 'error' });
							}
							else {
								setLoading(true);

								createPdf(
									candidate,
									profile,
									template,
									title,
									() => { setLoading(false) },
									(errorMessages: string[]) => {
										setLoading(false);
										errorCallback(errorMessages);
									}
								);
							}
						},
						isLoading: isLoading
					}
					: {
						buttonLabel: 'Save',
						icon: <FaSave />,
						onSubmit: () => {
							dispatch(
								setCandidate(
									{ ...candidate, candidateEmail: email },
									email,
									isEdit,
									() => { setLoading(false) },
									(errorMessages: string[]) => {
										setLoading(false);
										errorCallback(errorMessages);
									}
								)
							);
						},
					}
			}
			profiles={profiles}
			templates={isEdit ? templates : null}
			genders={genders}
			statuses={statuses}
			levels={levels}
			isEdit={isEdit}
		/>
	);
};

export default CreateOrUpdateCandidate;
