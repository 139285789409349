import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import React, { ReactElement, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';

import CharacterCountInput from '../CharacterCountInput';

import dayjs, { Dayjs } from 'dayjs';
import { formatSpaces, getDatePickerFormat } from 'src/utils';
import './index.css';

type Props = {
	label?: string;
	type?: string | undefined;
	values?: string[] | undefined;
	helpText?: string;
	controlSize?: 'sm' | 'lg';
	shouldAutoComplete?: boolean | undefined;
	fieldValue?: string | number | Date | Boolean | undefined;
	setFieldValue?: (value: string | number | Date | undefined) => void;
	isReadOnly?: boolean | undefined;
	isPlainText?: boolean | undefined;
	saveFieldvalue?: (value: string | number | Date | undefined) => void;
	pattern?: string;
	icon?: ReactElement | null;
	dateFormat?: string | null;
	existingValues?: string[] | undefined;
} & React.HTMLProps<HTMLInputElement>;

const FormInput: React.FC<Props> = ({
	type = 'text',
	label,
	values = [],
	min,
	max,
	required,
	maxLength,
	helpText,
	controlSize,
	placeholder,
	shouldAutoComplete,
	fieldValue,
	setFieldValue,
	disabled,
	isReadOnly,
	isPlainText,
	saveFieldvalue,
	pattern,
	icon,
	dateFormat = 'MM/yyyy',
	existingValues,
}) => {
	const [suggestions, setSuggestions] = React.useState([]);
	const getSuggestions = (value: string) => {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;

		return inputLength === 0
			? []
			: values.filter(
					(v: string) =>
						v.trim().toLowerCase().indexOf(inputValue) > -1
			  );
	};
	const onChange: (
		event,
		arg: { newValue: string | number | Date | undefined }
	) => void = (event, { newValue }) => {
		if (setFieldValue) setFieldValue(newValue);
		if (saveFieldvalue) saveFieldvalue(newValue);
	};

	const defaultOnChange: any = async (event) => {
		if (type === 'tel') {
			const isPasted =
				event.nativeEvent.type &&
				event.nativeEvent.inputType.startsWith('insertFromPaste');
			if (isPasted) {
				const pasted: string = event.currentTarget.value;
				const formatted = pasted.replace(/[./]/g, ' ');
				event.currentTarget.value = formatted;
			}
		}

		if (setFieldValue) setFieldValue(event.currentTarget.value);
		if (saveFieldvalue) saveFieldvalue(event.currentTarget.value);
	};

	let element = null;

	switch (type) {
		case 'date':
			const [dateValue, setDateValue] = React.useState<Dayjs | null>(
				fieldValue
					? dayjs(fieldValue as string | Date | number | undefined)
					: null
			);

			useEffect(() => {
				setDateValue(
					fieldValue
						? dayjs(
								fieldValue as string | Date | number | undefined
						  )
						: null
				);
			}, [fieldValue]);

			const [error, setError] =
				React.useState<DateValidationError | null>(null);

			const errorMessage = React.useMemo(() => {
				switch (error) {
					case 'invalidDate': {
						return 'Your date is not valid';
					}

					default: {
						return '';
					}
				}
			}, [error]);

			element = (
				<DatePicker
					onError={(newError) => setError(newError)}
					slotProps={{
						textField: {
							size: 'small',
							helperText: errorMessage,
							required: required,
						},
					}}
					format={getDatePickerFormat(dateFormat)}
					value={dateValue}
					onChange={(value: Dayjs) => {
						setDateValue(value);

						const newValue =
							value && value.isValid() ? value.toDate() : null;
						onChange(null, { newValue });
					}}
				/>
			);
			break;
		case 'select':
			helpText ??= 'Select...';
			let defaultvalue =
				fieldValue && (fieldValue as string).trim().length === 0
					? ''
					: fieldValue;

			element = (
				<Form.Select
					required={required ?? false}
					size={controlSize}
					defaultValue={
						defaultvalue as Exclude<string | number | Date, Date>
					}
					onChange={defaultOnChange}
					disabled={disabled}
				>
					<option key='title' value={''}>
						{helpText.trim()}
					</option>
					{values.map((val: string, index: number) => {
						var styles = {};

						if (
							existingValues != null &&
							existingValues.includes(val)
						) {
							styles = { fontWeight: 'bold' };
						}

						return (
							<option
								style={styles}
								key={index}
								value={val.replace(/ /g, '')}
							>
								{val.trim()}
							</option>
						);
					})}
				</Form.Select>
			);
			break;

		case 'textarea':
			const MIN_TEXTAREA_HEIGHT = 32;
			const textareaRef = React.useRef(null);
			const [textAreaValue, setTextAreaValue] = React.useState('');
			const onTextAreaChange = (event) => {
				setTextAreaValue(event.target.value);
				defaultOnChange(event);
			};

			React.useLayoutEffect(() => {
				// Reset height - important to shrink on delete
				textareaRef.current.style.height = 'inherit';
				// Set height
				textareaRef.current.style.height = `${Math.max(
					textareaRef.current.scrollHeight,
					MIN_TEXTAREA_HEIGHT
				)}px`;
			}, [textAreaValue]);
			element = (
				<CharacterCountInput>
					<Form.Control
						onChange={onTextAreaChange}
						ref={textareaRef}
						style={{
							minHeight: MIN_TEXTAREA_HEIGHT,
							resize: 'none',
						}}
						as={type}
						required={required ?? false}
						maxLength={maxLength ?? undefined}
						size={controlSize}
						placeholder={placeholder ?? undefined}
						// onChange={defaultOnChange}
						value={
							fieldValue as Exclude<string | number | Date, Date>
						}
						disabled={disabled}
						readOnly={isReadOnly}
						plaintext={isPlainText}
					/>
				</CharacterCountInput>
			);
			break;

		case 'switch':
			element = (
				<Form.Check
					type='switch'
					required={required ?? false}
					onChange={defaultOnChange}
					disabled={disabled}
					checked={Boolean(fieldValue)}
				/>
			);
			break;

		case 'check':
			element = (
				<Form.Check
					required={required ?? false}
					onChange={defaultOnChange}
					disabled={disabled}
					checked={Boolean(fieldValue)}
				/>
			);
			break;

		default:
			if (label && label === 'Profile') {
				fieldValue = formatSpaces(fieldValue as string);
			}
			element =
				shouldAutoComplete && values?.length > 0 ? (
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={({
							value,
						}: {
							value: string;
						}) => {
							const newSuggestions = getSuggestions(value);
							setSuggestions(newSuggestions);
						}}
						onSuggestionsClearRequested={() => setSuggestions([])}
						getSuggestionValue={(item: string) => item}
						renderSuggestion={(suggestion: string) => (
							<span key={suggestion}>{suggestion}</span>
						)}
						highlightFirstSuggestion={true}
						inputProps={{
							type,
							controlSize,
							min,
							max,
							required,
							maxLength,
							placeholder,
							value: fieldValue,
							onChange,
							disabled,
							isReadOnly,
							isPlainText,
							pattern,
						}}
						renderInputComponent={({
							type,
							controlSize,
							min,
							max,
							required,
							maxLength,
							placeholder,
							value,
							onChange,
							disabled,
							isReadOnly,
							isPlainText,
							...props
						}) => (
							<InputGroup>
								{icon && (
									<InputGroup.Text>{icon}</InputGroup.Text>
								)}
								<Form.Control
									type={type}
									size={controlSize}
									min={min ?? undefined}
									max={max ?? undefined}
									required={required ?? false}
									maxLength={maxLength ?? undefined}
									placeholder={placeholder ?? undefined}
									value={value}
									onChange={onChange}
									disabled={disabled}
									readOnly={isReadOnly}
									plaintext={isPlainText}
									{...props}
								/>
							</InputGroup>
						)}
					/>
				) : (
					<CharacterCountInput>
						<Form.Control
							type={type}
							size={controlSize}
							min={min ?? undefined}
							max={max ?? undefined}
							required={required ?? false}
							maxLength={maxLength ?? undefined}
							placeholder={placeholder ?? undefined}
							onChange={defaultOnChange}
							value={
								fieldValue as Exclude<
									string | number | Date,
									Date
								>
							}
							disabled={disabled}
							readOnly={isReadOnly}
							plaintext={isPlainText}
							pattern={pattern}
						/>
					</CharacterCountInput>
				);
			break;
	}

	return (
		<Form.Group as={Row} className='mb-3'>
			{label && (
				<Form.Label column sm={3}>
					{label.trim()}
					{required && <span className='text-danger px-1'>*</span>}
				</Form.Label>
			)}
			<Col>
				{element}
				{type !== 'select' && helpText && (
					<Form.Text>{helpText.trim()}</Form.Text>
				)}
			</Col>
		</Form.Group>
	);
};

export default FormInput;
