import { Action, Dispatch } from 'redux';

import { AppThunkAction } from '../';
import {
	AxiosError,
	AxiosResponse,
	defaultInstance,
	getAuthHeader,
} from '../../api';
import { ApiError, Summary } from '../../models';

export enum SummaryActionTypes {
    SET_SUMMARIES = 'SetSummaries',
	UPDATE_SUMMARY = 'UpdateSummary',
	ADD_SUMMARY = 'AddSummary',
	DELETE_SUMMARY = 'DeleteSummary'
}

// ACTIONS
export interface SetSummariesAction extends Action {
	type: SummaryActionTypes.SET_SUMMARIES;
    summaries: Summary[]
};
const SetSummaries = (summaries:Summary[]): SetSummariesAction => {
	return { type: SummaryActionTypes.SET_SUMMARIES, summaries: summaries};
};

export interface UpdateSummaryAction extends Action {
	type: SummaryActionTypes.UPDATE_SUMMARY;
    summary: Summary;
};
const UpdateSummary = (summary:Summary): UpdateSummaryAction => {
	return { type: SummaryActionTypes.UPDATE_SUMMARY, summary};
};

export interface AddSummaryAction extends Action {
	type: SummaryActionTypes.ADD_SUMMARY;
	summary: Summary;
};
const AddSummary = (summary: Summary): AddSummaryAction => {
	return { type: SummaryActionTypes.ADD_SUMMARY, summary }
};

export interface DeleteSummaryAction extends Action {
	type: SummaryActionTypes.DELETE_SUMMARY;
	summaryId: string;
};
const DeleteSummary = (summaryId: string): DeleteSummaryAction => {
	return { type: SummaryActionTypes.DELETE_SUMMARY, summaryId }
};


export type SummaryActions = SetSummariesAction | UpdateSummaryAction | AddSummaryAction | DeleteSummaryAction;

// ACTION CREATORS

export const getSummaries =
	(
        candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<SummaryActions> =>
	(dispatch) => {
		defaultInstance
            .get(`/v1/candidates/${candidateEmail}/summaries`, { headers: getAuthHeader() })
			.then((response: AxiosResponse<Summary[]>) => {
				dispatch(SetSummaries(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					failureCallback(error.response.data.detail);
				} else console.error(error);
			});
	};

export const saveSummary =
(
	summary: Summary,
	candidateEmail: string,
	summaryId: string,
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
): AppThunkAction<SummaryActions> =>
(dispatch) => {
	defaultInstance.put(
			`/v1/candidates/${candidateEmail}/summaries/${summaryId}`,
			summary,
			{ headers: getAuthHeader() }
		)
	.then((response: AxiosResponse<Summary>) => {
		successCallback();
	})
	.catch((error: AxiosError<ApiError>) => {
		if (error && error.response && error.response.data) {
			failureCallback(error.response.data.detail);
		} else console.error(error);
	});
};

export const createSummary = 
(
	summary: Summary,
	candidateEmail: string,
	successCallback: () => void = () => {},
	failureCallback: (errors?: string[]) => void = () => {}
): AppThunkAction<SummaryActions> =>
(dispatch) => {
	defaultInstance
	.post(`v1/candidates/${candidateEmail}/summaries`, summary, { headers: getAuthHeader() })
	.then((response: AxiosResponse<Summary>) => {
		dispatch(AddSummary(response.data));
		successCallback();
	})
	.catch((error: AxiosError<ApiError>) => {
		if (error && error.response && error.response.data) {
			const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
			failureCallback(errorsToDisplay);
		} else console.error(error);
	});
}

export const deleteSummary = 
(
	candidateEmail:string,
	summaryId:string,
	successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
):AppThunkAction<SummaryActions> =>
(dispatch) => {
	defaultInstance
		.delete(`/v1/candidates/${candidateEmail}/summaries/${summaryId}`, { headers: getAuthHeader() })
		.then((response: AxiosResponse) => {
			dispatch(DeleteSummary(summaryId));
			successCallback();
		})
		.catch((error: AxiosError<ApiError>) => {
			if (error && error.response && error.response.data) {
				failureCallback(error.response.data.detail);
			} else console.error(error);
		});
}

export const setSummaries = (summaries:Summary[]) => {
	return (dispatch:Dispatch) => {
		dispatch(SetSummaries(summaries));
	}
}

export const updateSummary = (summary:Summary) => {
	return (dispatch:Dispatch) => {
		dispatch(UpdateSummary(summary));
	}
}