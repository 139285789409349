import { Action, Dispatch } from 'redux';

import { AppThunkAction } from '..';
import {
	AxiosError,
	AxiosResponse,
	defaultInstance,
	getAuthHeader,
} from '../../api';
import { ApiError } from '../../models';

export enum ProfileActionTypes {
	GET_PROFILES = 'GetProfiles',
	GET_PROFILES_SUCCESS = 'GetProfilesSuccess',
	GET_PROFILES_FAILURE = 'GetProfilesFailure',
	GET_SELECTED_PROFILE = 'GetSelectedProfile',
	SET_SELECTED_PROFILE = 'SetSelectedProfile',
	SET_EXISTING_PROFILES = 'SetExistingProfiles',
	ADD_EXISTING_PROFILES = 'AddExistingProfiles'
}

// ACTIONS
export interface GetProfilesAction extends Action {
	type: ProfileActionTypes.GET_PROFILES;
	loading: boolean;
}

const GetProfiles = (): GetProfilesAction => {
	return { type: ProfileActionTypes.GET_PROFILES, loading: true };
};

export interface GetProfilesActionSuccess extends Action {
	type: ProfileActionTypes.GET_PROFILES_SUCCESS;
	profiles: string[];
}

const GetProfilesSuccess = (profiles: string[]): GetProfilesActionSuccess => {
	return { type: ProfileActionTypes.GET_PROFILES_SUCCESS, profiles };
};

export interface GetProfilesActionFailure extends Action {
	type: ProfileActionTypes.GET_PROFILES_FAILURE;
	error: string;
}

const GetProfilesFailure = (error: string): GetProfilesActionFailure => {
	return { type: ProfileActionTypes.GET_PROFILES_FAILURE, error };
};

export interface GetSelectedProfileAction extends Action {
	type: ProfileActionTypes.GET_SELECTED_PROFILE;
}

const GetSelectedProfile = (): GetSelectedProfileAction => {
	return { type: ProfileActionTypes.GET_SELECTED_PROFILE }
}

export interface SetSelectedProfileAction extends Action {
	type: ProfileActionTypes.SET_SELECTED_PROFILE;
	profile: string;
}

const SetSelectedProfile = (profile: string): SetSelectedProfileAction => {
	return { type: ProfileActionTypes.SET_SELECTED_PROFILE, profile }
}

export interface SetExistingProfilesAction extends Action {
	existingProfiles: string[];
	type: ProfileActionTypes.SET_EXISTING_PROFILES;
}

const SetExistingProfiles = (existingProfiles: string[]): SetExistingProfilesAction => {
	return { type: ProfileActionTypes.SET_EXISTING_PROFILES, existingProfiles }
}

export interface AddExistingProfilesAction extends Action {
	existingProfile: string;
	type: ProfileActionTypes.ADD_EXISTING_PROFILES;
}

const AddToExistingProfiles = (existingProfile: string): AddExistingProfilesAction => {
	return { type: ProfileActionTypes.ADD_EXISTING_PROFILES, existingProfile }
}

export type ProfileActions =
	| GetProfilesAction
	| GetProfilesActionSuccess
	| GetProfilesActionFailure
	| GetSelectedProfileAction
	| SetSelectedProfileAction
	| SetExistingProfilesAction
	| AddExistingProfilesAction;

// ACTION CREATORS
export const getProfiles =
	(
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ProfileActions> =>
	(dispatch) => {
		dispatch(GetProfiles());
		defaultInstance
			.get('/v1/enums/profiles', { headers: getAuthHeader() })
			.then((response: AxiosResponse<string[]>) => {
				dispatch(GetProfilesSuccess(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};

export const getExistingProfiles = 
	(
		candidateEmail: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<ProfileActions> =>
	async (dispatch) => {
		try{
			const existingProfiles:AxiosResponse<string[]> = await defaultInstance.get(`/v1/candidates/${candidateEmail}/profiles`, { headers: getAuthHeader() });

			dispatch(SetExistingProfiles(existingProfiles.data));

			successCallback();
		}
		catch (error) {
			if (error && error.response && error.response.data) {
				const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
				failureCallback(errorsToDisplay);
			} else console.error(error);
		}
	};

export const AddExistingProfile = 
	(
		profile: string	
	): AppThunkAction<ProfileActions> =>
		(dispatch) => {
			dispatch(AddToExistingProfiles(profile));
		};

export const setSelectedStoreProfile = (profile: string) => {
    return (dispatch: Dispatch) => {
        dispatch(SetSelectedProfile(profile))
    }
}