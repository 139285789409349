import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import Loading from '../Loading';

type Props = {
	label: string;
	isLoading?: boolean;
	icon?: JSX.Element;
	size?: 'sm';
	isIconLeading?: boolean;
} & ButtonProps;

const IconButton: React.FC<Props> = ({
	isLoading = false,
	icon = null,
	label,
	size,
	isIconLeading = true,
	...props
}) => (
	<Button disabled={isLoading} size={size} {...props}>
		{!isLoading ? (
			<>
				{icon && isIconLeading && icon}
				{label.trim()}
				{icon && !isIconLeading && icon}
			</>
		) : (
			<Loading size={size} />
		)}
	</Button>
);

export default IconButton;
