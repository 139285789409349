import { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';

import IconButton from '../IconButton';

type Props = {
	deleteData: (successCallback: () => void) => void;
	label?: string;
};

const DeleteButton: React.FC<Props> = ({ deleteData, label = 'Delete' }) => {
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		return () => {
			// Prevent console warning when isLoading is set to true on successCallback
		};
	}, []);

	const successCallback = () => setLoading(false);

	const handleDelete = () => {
		const isConfirmed = confirm('Are you sure ?');
		if (isConfirmed) {
			setLoading(true);
			deleteData(successCallback);
		}
	};

	return (
		<IconButton
			isLoading={isLoading}
			onClick={handleDelete}
			variant='danger'
			icon={<MdDelete />}
			label={label}
		/>
	);
};

export default DeleteButton;
