import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'react-bootstrap';
import { Candidate, Experience, OpenAiParsedExperience } from 'src/models';
import { Description } from 'src/models/Description';
import { createExperienceWithDescription } from 'src/store/experience';
import { AddExistingProfile, selectSelectedProfile } from 'src/store/profile';
import { getParsedExperience } from '../../api';
import CreateOrEditModal from '../CreateOrEditModal';
import FormInput from '../FormInput';
import Loading from '../Loading';
import { ExperienceForm } from './Form';

type Props = {
	candidate: Candidate;
};

export const CreateExperienceModal: React.FC<Props> = ({ candidate }) => {
	const profile: string = useSelector(selectSelectedProfile);
	const dispatch = useDispatch();
	const createNewExperienceWithDescription = bindActionCreators(
		createExperienceWithDescription,
		dispatch
	);

	const [resumedExperience, setResumedExperience] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isParsed, setIsParsed] = useState(false);
	const [hasParsingError, setHasParsingError] = useState(false);

	const [experience, setExperience] = useState({
		id: null,
		company: '',
		client: '',
		location: '',
		startDate: '',
		endDate: '',
		role: '',
		tools: '',
		environment: '',
		methodology: '',
		technologies: '',
		candidateEmail: candidate.candidateEmail,
		descriptions: [],
		dateFormat: '',
	} as Experience);

	const [description, setDescription] = useState({
		experienceId: null,
		profile: profile,
		shortDescription: '',
		candidateEmail: candidate.candidateEmail,
		longDescription: null,
	} as Description);

	const resetExperience = () => {
		setExperience({
			id: null,
			company: '',
			client: '',
			location: '',
			startDate: '',
			endDate: '',
			role: '',
			tools: '',
			environment: '',
			methodology: '',
			technologies: '',
			candidateEmail: candidate.candidateEmail,
			descriptions: [],
			dateFormat: '',
		} as Experience);

		setDescription({
			experienceId: null,
			profile: profile,
			shortDescription: '',
			candidateEmail: candidate.candidateEmail,
			longDescription: null,
		} as Description);

		setIsParsed(false);
		setResumedExperience('');
		setHasParsingError(false);
	};

	const processResumedExperience = async () => {
		if (hasParsingError) setHasParsingError(false);
		setIsLoading(true);

		try {
			var parsedExperience: OpenAiParsedExperience =
				await getParsedExperience(resumedExperience);

			setIsLoading(false);

			setExperience({
				...experience,
				company: parsedExperience.company,
				client: parsedExperience.client,
				location: parsedExperience.location,
				startDate: parsedExperience.startDate,
				endDate: parsedExperience.endDate,
				role: parsedExperience.role,
				tools: parsedExperience.tools,
				environment: parsedExperience.environment,
				methodology: parsedExperience.methodology,
				technologies: parsedExperience.technologies,
				descriptions: [],
			});

			setDescription({
				...description,
				longDescription: parsedExperience.description,
			});

			setIsParsed(true);
		} catch {
			setIsLoading(false);
			setHasParsingError(true);
		}
	};

	const handleExperienceManually = () => {
		if (hasParsingError) setHasParsingError(false);
		setIsParsed(true);
	};

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		dispatch(AddExistingProfile(profile));

		description.profile = profile;

		createNewExperienceWithDescription(
			experience,
			description,
			candidate.candidateEmail,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Add experience'}
			title={'Add a new experience'}
			resetModel={resetExperience}
			showActions={isParsed && !isLoading}
			saveData={saveData}
		>
			{hasParsingError && (
				<div className='alert alert-danger' role='alert'>
					<p className='mb-0'>
						An error occured while parsing the experience
					</p>
				</div>
			)}

			{isLoading && (
				<div className='d-flex flex-column m-4 justify-content-center align-items-center'>
					<Loading />
				</div>
			)}

			{!isLoading && !isParsed && (
				<>
					<FormInput
						label='Experience'
						type='textarea'
						maxLength={2000}
						fieldValue={resumedExperience}
						setFieldValue={(value: string) =>
							setResumedExperience(value)
						}
					/>

					<div className='text-end'>
						<Button
							style={{ marginRight: '10px' }}
							onClick={handleExperienceManually}
						>
							Enter experience manually
						</Button>
						<Button onClick={processResumedExperience}>
							Parse experience
						</Button>
					</div>
				</>
			)}

			{!isLoading && isParsed && (
				<ExperienceForm
					experience={experience}
					description={description}
					saveExperience={setExperience}
					saveDescription={setDescription}
				/>
			)}
		</CreateOrEditModal>
	);
};

export default CreateExperienceModal;
