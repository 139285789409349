import { Action } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError } from '../../models';
import { AppThunkAction } from '..';

export enum LevelActionTypes {
	GET_LEVELS = 'GetLevels',
	GET_LEVELS_SUCCESS = 'GetLevelsSuccess',
	GET_LEVELS_FAILURE = 'GetLevelsFailure',
}

// ACTIONS
export interface GetLevelsAction extends Action {
	type: LevelActionTypes.GET_LEVELS;
	loading: boolean;
}
const GetLevels = (): GetLevelsAction => {
	return {
		type: LevelActionTypes.GET_LEVELS,
		loading: true,
	};
};

export interface GetLevelsActionSuccess extends Action {
	type: LevelActionTypes.GET_LEVELS_SUCCESS;
	levels: string[];
}
const GetLevelsSuccess = (levels: string[]): GetLevelsActionSuccess => {
	return {
		type: LevelActionTypes.GET_LEVELS_SUCCESS,
		levels,
	};
};

export interface GetLevelsActionFailure extends Action {
	type: LevelActionTypes.GET_LEVELS_FAILURE;
	error: string;
}
const GetLevelsFailure = (error: string): GetLevelsActionFailure => {
	return {
		type: LevelActionTypes.GET_LEVELS_FAILURE,
		error,
	};
};

export type LevelActions =
	| GetLevelsAction
	| GetLevelsActionSuccess
	| GetLevelsActionFailure;

// ACTION CREATORS
export const getLevels =
	(
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<LevelActions> =>
	(dispatch) => {
		dispatch(GetLevels());
		defaultInstance
			.get('/v1/enums/levels', { headers: getAuthHeader() })
			.then((response: AxiosResponse<string[]>) => {
				dispatch(GetLevelsSuccess(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};
