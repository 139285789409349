import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { ProfileState } from './state';

const profileSelector = (state: ApplicationState) => state.profileFeature;

export const selectProfiles = createSelector(
	profileSelector,
	(profileState: ProfileState) => profileState.profiles
);

export const selectExistingProfiles = createSelector(
	profileSelector,
	(profileState: ProfileState) => profileState.existingProfiles
);

export const selectError = createSelector(
	profileSelector,
	(profileState: ProfileState) => profileState.error
);

export const selectLoading = createSelector(
	profileSelector,
	(profileState: ProfileState) => profileState.loading
);

export const selectSelectedProfile = createSelector(
	profileSelector,
	(profileState: ProfileState) => profileState.selectedProfile
)
