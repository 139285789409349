import { Action, Reducer } from 'redux';

import { UserActions, UserActionTypes } from './actions';
import { unloadedState, UserState } from './state';

export const userReducer: Reducer<UserState> = (
	state: UserState | undefined,
	incomingAction: Action
): UserState => {
	if (!state) return unloadedState;

	const action = incomingAction as UserActions;
	switch (action.type) {
		case UserActionTypes.SIGN_IN:
			return { ...state, loading: true };
		case UserActionTypes.SIGN_IN_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.token,
				email: action.email,
			};
		case UserActionTypes.SIGN_IN_FAILURE:
			return { ...unloadedState, loading: false, error: action.error };
		case UserActionTypes.SIGNED_IN:
			return { ...state, loading: true };
		case UserActionTypes.SIGNED_IN_SUCCESS:
			return { ...state, loading: false, user: action.user };
		case UserActionTypes.SIGNED_IN_FAILURE:
			return { ...unloadedState, loading: false, error: action.error };
		case UserActionTypes.SIGN_OUT:
			return unloadedState;
		default:
			return state;
	}
};
