import { Action, Reducer } from 'redux';

import { Candidate } from 'src/models';
import { CandidateActions, CandidateActionTypes } from './actions';
import { CandidateState, unloadedState } from './state';

export const candidateReducer: Reducer<CandidateState> = (
	state: CandidateState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as CandidateActions;
	switch (action.type) {
		case CandidateActionTypes.GET_CANDIDATES:
		case CandidateActionTypes.GET_CANDIDATE:
			return { ...state, loading: true };

		case CandidateActionTypes.SET_CANDIDATE:
			state.candidate = action.candidate;
			return {
				...state
			}

		case CandidateActionTypes.SET_CANDIDATE_OFFLINE:
			state.candidate = action.candidate;
			return {
				...state
			}


		case CandidateActionTypes.GET_CANDIDATES_SUCCESS:
			return {
				...state,
				loading: false,
				candidates: action.candidates.sort(),
			};

		case CandidateActionTypes.GET_CANDIDATES_FAILURE:
		case CandidateActionTypes.GET_CANDIDATE_FAILURE:
		case CandidateActionTypes.SET_CANDIDATE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		case CandidateActionTypes.GET_CANDIDATE_SUCCESS:
		case CandidateActionTypes.SET_CANDIDATE_SUCCESS:
			return { ...state, loading: false, candidate: action.candidate };

		case CandidateActionTypes.RESET_CANDIDATE:
			return { ...state, candidate: {} as Candidate };

		case CandidateActionTypes.ADD_CANDIDATE:
			state.candidates.push(action.candidateEmail);
			return { ...state }

		case CandidateActionTypes.DELETE_CANDIDATE:
			state.candidates = state.candidates.filter(e => e !== action.candidateEmail);
			return { ...state }

		default:
			return state;
	}
};
