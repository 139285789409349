import { useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';
import FormInput from '../FormInput';
import IconButton from '../IconButton';

import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { selectSelectedProfile } from 'src/store/profile';
import { selectSelectedTemplate } from 'src/store/template';
import './style.css';

type Props = {
	generateCallback: (event, title: string) => void;
	isLoading: boolean;
};

export const GenerateModal: React.FC<Props> = ({
	generateCallback,
	isLoading,
}) => {
	const formRef = useRef(null);
	const [errors, setErrors] = useState([]);
	const [hasError, sethasError] = useState(false);
	const [show, setShow] = useState(false);
	const [validated, setValidated] = useState(false);
	const { addToast } = useToasts();
	const profile = useSelector(selectSelectedProfile);
	const template = useSelector(selectSelectedTemplate);

	const [cvTitle, setCvTitle] = useState(null);

	const handleReset = () => {
		if (formRef.current) {
			formRef.current.reset();
		}
		setValidated(false);
	};

	const handleClose = () => {
		setShow(false);
		sethasError(false);
		setErrors(null);
		setCvTitle(null);
		handleReset();
	};

	const handleCancel = () => {
		handleClose();
	};

	const handleSave = (event) => {
		const form = event.currentTarget;

		event.preventDefault();

		if (form.checkValidity() === true) {
			generateCallback(event, cvTitle);
			setValidated(true);
			handleClose();
		}
	};

	const handleShow = () => {
		if (!profile || !template) {
			addToast('A profile and template must be selected !', {
				appearance: 'error',
			});
		} else {
			setShow(true);
		}
	};

	return (
		<>
			<IconButton
				type='submit'
				label={'Generate CV'}
				isLoading={isLoading}
				icon={<FaSave />}
				className='generateCVButton'
				variant='none'
				onClick={handleShow}
				form='none'
			/>

			<Modal
				show={show}
				onHide={handleCancel}
				animation={false}
				dialogClassName='my-modal'
				scrollable={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Generate CV</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{hasError && errors && (
						<div className='alert alert-danger' role='alert'>
							{errors.length === 1 ? (
								<p className='mb-0'>{errors[0]}</p>
							) : (
								<ul className='mb-0'>
									{errors.map((error, index) => (
										<li key={index}>{error}</li>
									))}
								</ul>
							)}
						</div>
					)}
					<Form
						ref={formRef}
						noValidate
						validated={validated}
						onSubmit={handleSave}
					>
						<FormInput
							label='CV title'
							value={cvTitle}
							setFieldValue={(value) => setCvTitle(value)}
						/>
					</Form>
				</Modal.Body>
				{
					<Modal.Footer>
						<Button variant='danger' onClick={handleCancel}>
							Cancel
						</Button>
						<Button
							type='submit'
							onClick={handleSave}
							variant='success'
						>
							{'Generate'}
						</Button>
					</Modal.Footer>
				}
			</Modal>
		</>
	);
};

export default GenerateModal;
