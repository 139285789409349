import { Candidate } from '../../models';

export interface CandidateState {
	candidate: Candidate;
	candidates: string[];
	error: string;
	loading: boolean;
}

export const unloadedState: CandidateState = {
	candidate: {
		firstName: '',
		lastName: '',
		phoneNumber: '',
		candidateEmail: '',
		birthDate: new Date(),
		nationality: '',
		gender: '',
		status: '',
		availability: ''
	} as Candidate,
	candidates: [],
	error: null,
	loading: false,
};

export const reset: (candidates:string[]) => CandidateState = (candidates:string[]) => {
	return {
		candidate: {
			firstName: '',
			lastName: '',
			phoneNumber: '',
			candidateEmail: '',
			birthDate: new Date(),
			nationality: '',
			gender: '',
			status: '',
			availability: ''
		} as Candidate,
		candidates: candidates,
		error: null,
		loading: false,
	} as CandidateState
}