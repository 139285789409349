export interface ProfileState {
	profiles: string[];
	existingProfiles: string[];
	selectedProfile: string;
	error: string;
	loading: boolean;
}

export const unloadedState: ProfileState = {
	profiles: [],
	existingProfiles: [],
	selectedProfile: '',
	error: null,
	loading: false,
};
