import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Candidate, Summary } from 'src/models';
import { AddExistingProfile, selectSelectedProfile } from 'src/store/profile';
import { createSummary } from 'src/store/summary';
import CreateOrEditModal from '../CreateOrEditModal';
import Editor from '../Editor';
import FormInput from '../FormInput';

type Props = {
	candidate: Candidate;
};

export const CreateSummaryModal: React.FC<Props> = ({ candidate }) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectSelectedProfile);
	const createNewSummary = bindActionCreators(createSummary, dispatch);

	const [summary, setSummary] = useState({
		id: '',
		candidateEmail: candidate.candidateEmail,
		profile: '',
		templateName: '',
		description: '',
	} as Summary);

	const resetSummary = () =>
		setSummary({
			id: '',
			candidateEmail: candidate.candidateEmail,
			profile: '',
			templateName: '',
			description: '',
		} as Summary);

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		summary.profile = profile;

		dispatch(AddExistingProfile(profile));
		createNewSummary(
			summary,
			candidate.candidateEmail,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Add summary'}
			title={'Add a new summary'}
			resetModel={resetSummary}
			saveData={saveData}
		>
			<FormInput
				label='Profile'
				readOnly
				disabled
				required
				fieldValue={profile}
			/>
			<Editor
				label='Summary'
				value={summary.description}
				maxLength={2000}
				setValue={(value: string) =>
					setSummary({ ...summary, description: value })
				}
			/>
		</CreateOrEditModal>
	);
};

export default CreateSummaryModal;
