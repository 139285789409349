import { Action, Dispatch } from 'redux';

import { AppThunkAction } from '../';
import {
	AxiosError,
	AxiosResponse,
	defaultInstance,
	getAuthHeader,
} from '../../api';
import { ApiError, Education } from '../../models';

export enum EducationActionTypes {
	SET_EDUCATIONS = 'SetEducations',
	UPDATE_EDUCATION = 'UpdateEducation',
	ADD_EDUCATIONS = 'AddEducations',
	DELETE_EDUCATION = 'DeleteEducation',
}

// ACTIONS
export interface SetEducationsAction extends Action {
	type: EducationActionTypes.SET_EDUCATIONS;
	educations: Education[]
}
const SetEducations = (educations: Education[]): SetEducationsAction => {
	return { type: EducationActionTypes.SET_EDUCATIONS, educations: educations };
};

export interface UpdateEducationAction extends Action {
	type: EducationActionTypes.UPDATE_EDUCATION;
	education: Education;
}
const UpdateEducation = (education: Education): UpdateEducationAction => {
	return { type: EducationActionTypes.UPDATE_EDUCATION, education: education };
};

export interface AddEducationsAction extends Action {
	type: EducationActionTypes.ADD_EDUCATIONS;
	educations: Education[];
}
const AddEducations = (educations: Education[]): AddEducationsAction => {
	return { type: EducationActionTypes.ADD_EDUCATIONS, educations: educations };
}

export interface DeleteEducationAction extends Action {
	type: EducationActionTypes.DELETE_EDUCATION;
	educationId: string;
}
const DeleteEducation = (educationId: string): DeleteEducationAction => {
	return { type: EducationActionTypes.DELETE_EDUCATION, educationId };
}


export type EducationActions = SetEducationsAction | UpdateEducationAction | AddEducationsAction | DeleteEducationAction;

// ACTION CREATORS

export const getEducations =
	(
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<EducationActions> =>
		(dispatch) => {
			defaultInstance
				.get(`/v1/candidates/${candidateEmail}/educations`, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Education[]>) => {
					dispatch(SetEducations(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		};

export const saveEducation =
	(
		education: Education,
		candidateEmail: string,
		educationId: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<EducationActions> =>
		(dispatch) => {
			defaultInstance.put(
				`/v1/candidates/${candidateEmail}/educations/${educationId}`,
				education,
				{ headers: getAuthHeader() }
			)
				.then((response: AxiosResponse<Education>) => {
					dispatch(UpdateEducation(education));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					}
					else console.error(error);
				});
		};

// export const createEducation = 
// (
// 	education: Education,
// 	candidateEmail: string,
// 	successCallback: () => void = () => {},
// 	failureCallback: (errors?: string[]) => void = () => { }
// ):AppThunkAction<EducationActions> =>
// (dispatch) => {
// 	defaultInstance
// 		.post(`/v1/candidates/${candidateEmail}/educations`, education, { headers: getAuthHeader() })
// 		.then((response: AxiosResponse<Education>) => {
// 			dispatch(AddEducation(response.data))
// 			successCallback();
// 		})
// 		.catch((error: AxiosError<ApiError>) => {
// 			if (error && error.response && error.response.data) {
// 				const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
// 				failureCallback(errorsToDisplay);
// 			} else console.error(error);
// 		});
// };

export const createEducations =
	(
		educations: Education[],
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<EducationActions> =>
		(dispatch) => {
			defaultInstance
				.post(`/v1/candidates/${candidateEmail}/educations`, { educations }, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Education[]>) => {
					dispatch(AddEducations(response.data))
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const deleteEducation =
	(
		candidateEmail: string,
		educationId: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<EducationActions> =>
		(dispatch) => {
			defaultInstance
				.delete(`/v1/candidates/${candidateEmail}/educations/${educationId}`, { headers: getAuthHeader() })
				.then((response: AxiosResponse) => {
					dispatch(DeleteEducation(educationId));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		}

export const setEducations = (educations: Education[]) => {
	return (dispatch: Dispatch) => {
		dispatch(SetEducations(educations));
	}
}

export const updateEducation = (education: Education) => {
	return (dispatch: Dispatch) => {
		dispatch(UpdateEducation(education));
	}
}