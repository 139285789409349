import * as React from 'react';
import {
	Container,
	Form,
	Row,
	Col,
	Card,
	Image,
	InputGroup,
} from 'react-bootstrap';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { FiLogIn } from 'react-icons/fi';
import IconButton from '../IconButton';

import './index.css';

type Props = {
	email: string;
	onEmailChange: (value: string) => void;
	password: string;
	onPasswordChange: (value: string) => void;
	onSubmit: () => void;
	isLoading: boolean;
};

const SignInForm: React.FC<Props> = ({
	email,
	onEmailChange,
	password,
	onPasswordChange,
	onSubmit,
	isLoading,
}) => {
	const [isValidated, setIsValidated] = React.useState(false);
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const emailIsValid =
			email.length > 0 && !!email.match(/[a-z]+\.[a-z]+\@capyx\.be/i);
		const formIsValid = emailIsValid && password.length > 0;

		setIsValidated(true);

		if (formIsValid) onSubmit();
	};

	return (
		<React.Fragment>
			<Container>
				<Row>
					<Col
						sm={{ offset: 3, span: 6 }}
						md={{ offset: 3, span: 6 }}
					>
						<div className='text-center'>
							<Image
								src='https://myerp.capyx.be/img/main_logo.png'
								rounded
								fluid
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col
						sm={{ offset: 3, span: 6 }}
						md={{ offset: 3, span: 6 }}
					>
						<Card>
							<Card.Header className='panel-header'>
								<span>AutomatedCVGeneration</span>
								<div className='forgotPwd'>
									<a href='https://myerp.capyx.be/pages/forgotPassword.php'>
										Forgot password?
									</a>
								</div>
							</Card.Header>
							<Card.Body>
								<Form
									noValidate
									validated={isValidated}
									onSubmit={!isLoading ? handleSubmit : null}
								>
									<Form.Group
										className='mb-3'
										controlId='email'
									>
										<InputGroup hasValidation>
											<InputGroup.Text>
												<FaUserAlt />
											</InputGroup.Text>
											<Form.Control
												type='email'
												placeholder='Email'
												required
												defaultValue={email}
												onChange={(e) =>
													onEmailChange(
														e.currentTarget.value
															.trim()
															.toLowerCase()
													)
												}
												pattern={
													'[a-z]+.[a-z]+@capyx.be'
												}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												You must provide your Capyx
												email address!
											</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>
									<Form.Group
										className='mb-3'
										controlId='password'
									>
										<Col sm={12} md={12}>
											<InputGroup hasValidation>
												<InputGroup.Text>
													<FaLock />
												</InputGroup.Text>
												<Form.Control
													type='password'
													placeholder='Password'
													required
													defaultValue={password}
													onChange={(e) =>
														onPasswordChange(
															e.currentTarget
																.value
														)
													}
												></Form.Control>
												<Form.Control.Feedback type='invalid'>
													You must use your "MyERP"
													password!
												</Form.Control.Feedback>
											</InputGroup>
										</Col>
									</Form.Group>
									<Form.Group className='float-end'>
										<IconButton
											type='submit'
											className='btn-login'
											isLoading={isLoading}
											icon={<FiLogIn />}
											label='Log in'
											size='sm'
										/>
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default SignInForm;
