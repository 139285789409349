import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { LevelState } from './state';

const levelSelector = (state: ApplicationState) => state.levelFeature;

export const selectLevels = createSelector(
	levelSelector,
	(levelState: LevelState) => levelState.levels
);
export const selectError = createSelector(
	levelSelector,
	(levelState: LevelState) => levelState.error
);
export const selectLoading = createSelector(
	levelSelector,
	(levelState: LevelState) => levelState.loading
);
