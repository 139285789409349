import { Action, Reducer } from 'redux';

import { TemplateActions, TemplateActionTypes } from './actions';
import { unloadedState, TemplateState } from './state';

export const templateReducer: Reducer<TemplateState> = (
	state: TemplateState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as TemplateActions;
	switch (action.type) {

		case TemplateActionTypes.GET_TEMPLATES:
			return { ...state, loading: true };

		case TemplateActionTypes.GET_TEMPLATES_SUCCESS:
			return {
				...state,
				loading: false,
				templates: action.templates,
			};

		case TemplateActionTypes.GET_TEMPLATES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			};

		case TemplateActionTypes.SET_SELECTED_TEMPLATE:
			state.selectedTemplate = action.template;
			return { ...state };

		default:
			return state;
	}
};
