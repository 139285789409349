import * as innerDateFormat from 'dateformat';

const getFullDateRegExp = (): RegExp => /^\d{2}([\/-])\d{2}\1\d{4}$/g;
const getMonthYearDateRegExp = (): RegExp => /^\d{2}[\/-]\d{4}$/g;
const getYearDateRegExp = (): RegExp => /^\d{4}$/g;

const fullDateFormat = 'dd/MM/yyyy';
const monthYearDateFormat = 'MM/yyyy';
const yearDateFormat = 'yyyy';

export const dateFormats = [
	fullDateFormat,
	monthYearDateFormat,
	yearDateFormat,
];
export const dateHelpText = `Available formats : ${dateFormats.join(
	', '
)}. You can either use - or /`;

export function getDatePickerFormat(dateFormat: string) {
	dateFormat = dateFormat.replace(/d/g, 'D');
	dateFormat = dateFormat.replace(/y/g, 'Y');

	return dateFormat;
}

export function filterAndSort(values: string[]) {
	if (!values) return [];
	return values.sort().map((value: string) => formatSpaces(value));
}

export const formatSpaces = (value: string) =>
	value.replace(
		/(?<=[A-Z])(?![0-9]+)(?=[A-Z][a-z])|(?<=[^A-Z])(?![0-9]+)(?=[A-Z])|(?<=[A-Za-z])(?![0-9]+)(?=[^A-Za-z])/g,
		' '
	);

export const phoneRegex =
	/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/g;

export const convertToUtc = (
	dateString: string | null | undefined | Date
): Date | null => {
	if (!dateString) return null;

	let date: Date;
	if (dateString instanceof Date) {
		date = dateString;
	} else {
		date = new Date(dateString);
	}

	const utcDate = new Date(
		Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			0,
			0,
			0,
			0
		)
	);
	return utcDate;
};

export const isValidDateFormat = (
	dateString: string,
	isMandatory: boolean = true
): boolean => {
	return (
		getFullDateRegExp().test(dateString) ||
		getMonthYearDateRegExp().test(dateString) ||
		getYearDateRegExp().test(dateString) ||
		!isMandatory
	);
};

export const findDateFormat = (dateString: string): string => {
	let format: string;

	if (getFullDateRegExp().test(dateString)) {
		format = fullDateFormat;
	} else if (getMonthYearDateRegExp().test(dateString)) {
		format = monthYearDateFormat;
	} else if (getYearDateRegExp().test(dateString)) {
		format = yearDateFormat;
	}

	return format;
};

export const dateCreator = (dateString: string): Date | null => {
	let date: Date = null;

	if (!dateString) return null;

	if (getFullDateRegExp().test(dateString)) {
		date = new Date(
			parseInt(dateString.substring(dateString.length - 4)),
			parseInt(dateString.slice(0, 5).substring(3)) - 1,
			parseInt(dateString.slice(0, 2))
		);
	} else if (getMonthYearDateRegExp().test(dateString)) {
		date = new Date(
			parseInt(dateString.substring(dateString.length - 4)),
			parseInt(dateString.slice(0, 2)) - 1
		);
	} else if (getYearDateRegExp().test(dateString)) {
		date = new Date(dateString);
	}

	return convertToUtc(date);
};

export const dateFormat = (
	date: string | number | Date,
	format: string = 'dd/mm/yyyy'
): string => {
	if (!date) return '';
	format = format.replace(/M/g, 'm');
	format = format.replace(/Y/g, 'y');

	return innerDateFormat.default(date, format);
};

export const removeNullString = (value: string): string | null => {
	if (value === 'null') return null;
	return value;
};
