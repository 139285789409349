import { Action, Reducer } from 'redux';
import { ExperienceActions, ExperienceActionTypes } from './actions';
import { ExperienceState, unloadedState } from './state';


export const experienceReducer: Reducer<ExperienceState> = (
	state: ExperienceState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as ExperienceActions;
	switch (action.type) {
		case ExperienceActionTypes.SET_EXPERIENCES:
			state.experiences = action.experiences;
			return { ...state }

		case ExperienceActionTypes.UPDATE_EXPERIENCE:
			state.experiences = state.experiences.map(e => e.id === action.experience.id ? action.experience : e);
			return { ...state }

		case ExperienceActionTypes.ADD_EXPERIENCE:
			state.experiences.push(action.experience);
			return { ...state }

		case ExperienceActionTypes.DELETE_EXPERIENCE:
			state.experiences = state.experiences.filter(e => e.id !== action.experienceId);
			return { ...state }

		case ExperienceActionTypes.UPDATE_DESCRIPTION:
			state.experiences
				.forEach(e => e.descriptions = e.descriptions.map(d => 
					d.experienceId === action.description.experienceId && d.profile === action.description.profile ? action.description : d));
			return { ...state }

		case ExperienceActionTypes.ADD_DESCRIPTION:
			state.experiences
				.forEach(e => {
					if(e.id === action.description.experienceId){
						e.descriptions.push(action.description);
					}
				})
			return { ...state }

		case ExperienceActionTypes.DELETE_DESCRIPTION:
			state.experiences.forEach(e => {
				e.descriptions = e.descriptions.filter(d => !(d.experienceId === action.experienceId && d.profile === action.profile))
			})
			return { ...state }

		default:
			return state;
	}
};
