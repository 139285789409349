import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Skill } from 'src/models/Skill';
import { deleteSkill, updateSkill, updateStoreSkill } from 'src/store/skill';

type Props = {
	className?: string | undefined;
	skills: Skill[] | undefined;
	deleteData?: (successCallback: () => void) => void;
};

export const SkillForm: React.FC<Props> = ({
	className,
	skills,
}) => {
	const dispatch = useDispatch();
	const deleteSkillState = bindActionCreators(deleteSkill, dispatch);

	return (<section className={className}>
		<h5>
			<ListGroup horizontal className="d-flex flex-wrap">
				{skills.map((s: Skill, index: number) => (
					<ListGroup.Item style={{ border: 'none' }} key={index}>
						<Badge pill bg="dark">
							<div style={{ float: 'left', margin: '9px' }}>
								<span>{s.name}</span>
							</div>
							<Badge role="button" pill bg="secondary" className="position-absolute top-25 start-75 translate-middle badge-notification" onClick={() => deleteSkillState(s)}>
								X
							</Badge>
						</Badge>
					</ListGroup.Item>
				))}
			</ListGroup>
		</h5>
	</section>
	);
}

export * from './Modal';
