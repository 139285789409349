import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Candidate, Certification } from "src/models";
import { deleteCertification } from "src/store/certification";
import { selectSelectedProfile } from "src/store/profile";
import DeleteButton from "../DeleteButton";
import UpdateCertificationModal from "../CertificationForm/UpdateCertificationModal";

type Props = {
    className?: string | undefined;
    candidate: Candidate;
    certification: Certification;
};

type LabelDetailsProps = {
    label: string;
    details: string;
};

export const LabelDetails: React.FC<LabelDetailsProps> = ({ label, details }) => {
    return (
        <div style={{ marginBottom: '10px' }}>
            <div style={{ fontWeight: 'bold' }}>{label} : </div>
            <div>{details}</div>
        </div>
    );
}

export const CertificationPreview: React.FC<Props> = ({ className, candidate, certification }) => {
    const profile = useSelector(selectSelectedProfile);
    const dispatch = useDispatch();
    const deleteCertificationFromDb = bindActionCreators(deleteCertification, dispatch);

    return (
        <div className="mb-3">
            <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ alignSelf: 'center' }}>{certification.certificationName} {certification.year ? `(${certification.year})` : ''}</div>

                <div style={{ float: 'right' }}>
                    <UpdateCertificationModal candidate={candidate} certification={certification} />
                    <div style={{ marginLeft: '10px', display: 'inline' }}></div>
                    <DeleteButton deleteData={(successCallback) => deleteCertificationFromDb(candidate.candidateEmail, certification.id, successCallback)} />

                </div>
            </div>

            {
                (certification.organization) &&
                <div>
                    {certification.organization ? `${certification.organization}` : ''}
                </div>
            }
        </div>
    );
}

export default CertificationPreview;