import { createSelector } from 'reselect';
import { Certification } from 'src/models';

import { ApplicationState } from '..';
import { CertificationState } from './state';

const certificationSelector = (state: ApplicationState) => state.certificationFeature;

export const selectCertifications = () => createSelector(
	certificationSelector,
	(certificationState: CertificationState) => 
		certificationState.certifications
			.sort((a: Certification, b:Certification) => b.year - a.year)
			.filter(c => c)
)