import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Candidate, Experience } from 'src/models';
import { Description } from 'src/models/Description';
import { createDescription } from 'src/store/experience';
import { AddExistingProfile, selectSelectedProfile } from 'src/store/profile';
import CreateOrEditModal from '../CreateOrEditModal';
import Editor from '../Editor';
import FormInput from '../FormInput';

type Props = {
	candidate: Candidate;
	experience: Experience;
};

export const CreateDescriptionModal: React.FC<Props> = ({
	candidate,
	experience,
}) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectSelectedProfile);

	const createNewDescription = bindActionCreators(
		createDescription,
		dispatch
	);

	const [description, setDescription] = useState({
		experienceId: experience.id,
		profile: '',
		shortDescription: '',
		longDescription: '',
		candidateEmail: candidate.candidateEmail,
	} as Description);

	const resetDescription = () =>
		setDescription({
			experienceId: experience.id,
			profile: '',
			shortDescription: '',
			longDescription: '',
			candidateEmail: candidate.candidateEmail,
		} as Description);

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		description.profile = profile;
		dispatch(AddExistingProfile(profile));
		createNewDescription(
			description,
			candidate.candidateEmail,
			experience.id,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Add description'}
			title={'Add a new description'}
			resetModel={resetDescription}
			saveData={saveData}
		>
			<FormInput
				label='Profile'
				readOnly
				disabled
				required
				fieldValue={profile}
			/>
			{/* TODO: Uncomment when the field will be useful */}
			{/* <FormInput 
                label='Short description'
                type='textarea'
                required 
                maxLength={250} 
                fieldValue={description.shortDescription}
                setFieldValue={(value:string)=>
                    setDescription({...description, shortDescription: value})
                }
            /> */}
			{/* <FormInput
				label='Description'
				type='textarea'
				maxLength={2000}
				fieldValue={description.longDescription}
				setFieldValue={(value: string) =>
					setDescription({ ...description, longDescription: value })
				}
			/> */}
			<Editor
				label='Description'
				value={description.longDescription}
				maxLength={2000}
				setValue={(value: string) =>
					setDescription({ ...description, longDescription: value })
				}
			/>
		</CreateOrEditModal>
	);
};

export default CreateDescriptionModal;
