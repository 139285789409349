import { push } from 'connected-react-router';
import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { signOut } from 'src/store/user/actions';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Version from '../../components/Version';

import { Candidate } from 'src/models';
import { selectCandidates, setCandidateOffline } from 'src/store/candidate';
import { ResetCandidate } from 'src/store/root/actions';
import {
	selectCanManageCandidates,
	selectUser,
} from 'src/store/user/selectors';
import './index.css';

const Layout: React.FC = ({ children }) => {
	const dispatch = useDispatch();

	const user = useSelector(selectUser);
	const candidates = useSelector(selectCandidates);
	const canManageCandidates = useSelector(selectCanManageCandidates);
	const onSignOut = () => {
		dispatch(signOut());
	};

	const goToHome = () => {
		dispatch(push('/home'));
	};

	const goToCandidates = () => {
		dispatch(push('/candidates'));
	};

	const goToAdd = (email: string) => {
		dispatch(ResetCandidate());
		dispatch(
			setCandidateOffline({
				firstName: '',
				lastName: '',
				phoneNumber: '',
				candidateEmail: email,
				birthDate: null,
				nationality: '',
				gender: '',
				status: '',
				availability: '',
			} as Candidate)
		);
		dispatch(push(`/candidates/new?email=${email}`));
	};

	const goToMyCv = () => {
		dispatch(ResetCandidate());
		if (candidates.every((c) => c != user.email)) {
			goToAdd(user.email);
		} else {
			dispatch(push(`/candidates/${user.email}`));
		}
	};

	return (
		<React.Fragment>
			<Header
				onSignOut={onSignOut}
				user={user}
				onBrandClick={goToHome}
				onCandidatesClick={goToCandidates}
				onMyCvClick={goToMyCv}
				canManageCandidates={canManageCandidates}
			/>

			<Container className='layout-container' fluid>
				<main role='main' className='main mb-4'>
					{children}
				</main>
			</Container>

			<Footer />

			<Version />
		</React.Fragment>
	);
};

export default Layout;
