import { Action, Reducer } from 'redux';
import { LanguageActions, LanguageActionTypes } from './actions';
import { LanguageState, unloadedState } from './state';


export const languageReducer: Reducer<LanguageState> = (
	state: LanguageState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as LanguageActions;
	switch (action.type) {
		case LanguageActionTypes.SET_LANGUAGES:
			state.languages = action.languages;
			return { ...state }

		case LanguageActionTypes.UPDATE_LANGUAGE:
			state.languages = state.languages.map((language) => language.name === action.language.name ? action.language : language);
			return { ...state }

		case LanguageActionTypes.ADD_LANGUAGE:
			state.languages.push(action.language);
			return { ...state }

		case LanguageActionTypes.DELETE_LANGUAGE:
			state.languages = state.languages.filter(l => l.name !== action.language);
			return { ...state }

		default:
			return state;
	}
};
