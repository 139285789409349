import { createSelector } from 'reselect';
import { Experience } from 'src/models';
import { dateCreator } from 'src/utils';

import { ApplicationState } from '../';
import { ExperienceState } from './state';

const experienceSelector = (state: ApplicationState) => state.experienceFeature;

export const selectExperiences = createSelector(
	experienceSelector,
	(experienceState: ExperienceState) => 
		experienceState.experiences
			.sort((a:Experience, b:Experience) => {
				if (a.endDate === '' && b.endDate === '') {
					return dateCreator(b.startDate).getTime() - dateCreator(a.startDate).getTime()
				}
				else if (a.endDate === '') {
					return -1;
				}
				else if (b.endDate === '') {
					return 1
				} else {
					return dateCreator(b.endDate).getTime() - dateCreator(a.endDate).getTime()
				}
			})
			.filter(e => e)
);