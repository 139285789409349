import { Language } from 'src/models';

export interface LanguageState {
	languages: Language[];
}

export const unloadedState: LanguageState = {
	languages: []
};

export const reset: () => LanguageState = () => {
	return {
		languages: []
	} as LanguageState
}