import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { SupplierState } from './state';

const supplierSelector = (state: ApplicationState) => state.supplierFeature;

export const selectSuppliers = createSelector(
	supplierSelector,
	(supplierState: SupplierState) => supplierState.suppliers.filter(s => s)
);

export const selectAllNonReferencedSuppliersEmails = createSelector(
	supplierSelector,
	(supplierState: SupplierState) => supplierState.allSuppliersMails.filter(e => !supplierState.suppliers.find(s => s.supplierEmail === e))
);