import { Action } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError } from '../../models';
import { AppThunkAction } from '../';

export enum GenderActionTypes {
	GET_GENDERS = 'GetGenders',
	GET_GENDERS_SUCCESS = 'GetGendersSuccess',
	GET_GENDERS_FAILURE = 'GetGendersFailure',
}

// ACTIONS
export interface GetGendersAction extends Action {
	type: GenderActionTypes.GET_GENDERS;
	loading: boolean;
}
const GetGenders = (): GetGendersAction => {
	return { type: GenderActionTypes.GET_GENDERS, loading: true };
};

export interface GetGendersActionSuccess extends Action {
	type: GenderActionTypes.GET_GENDERS_SUCCESS;
	genders: string[];
}
const GetGendersSuccess = (genders: string[]): GetGendersActionSuccess => {
	return { type: GenderActionTypes.GET_GENDERS_SUCCESS, genders };
};

export interface GetGendersActionFailure extends Action {
	type: GenderActionTypes.GET_GENDERS_FAILURE;
	error: string;
}
const GetGendersFailure = (error: string): GetGendersActionFailure => {
	return { type: GenderActionTypes.GET_GENDERS_FAILURE, error };
};

export type GenderActions =
	| GetGendersAction
	| GetGendersActionSuccess
	| GetGendersActionFailure;

// ACTION CREATORS
export const getGenders =
	(
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<GenderActions> =>
	(dispatch) => {
		dispatch(GetGenders());
		defaultInstance
			.get('/v1/enums/genders', { headers: getAuthHeader() })
			.then((response: AxiosResponse<string[]>) => {
				dispatch(GetGendersSuccess(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};
