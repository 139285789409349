import * as React from 'react';

const Footer: React.FC = () => (
	<footer className='footer text-center mb-3'>
		&copy; Capyx{' '}
		{new Date().toLocaleDateString('fr-BE', { year: 'numeric' })}.
	</footer>
);

export default Footer;
