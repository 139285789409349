import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { push } from 'connected-react-router';

import { signIn } from 'src/store/user/actions';
import { selectLoading } from 'src/store/user/selectors';
import SignInForm from '../components/SignInForm';

const SignIn: React.FC = () => {
	const dispatch = useDispatch();
	const { addToast } = useToasts();
	const errorCallback = (error: string) =>
		addToast(
			error
				? `The following error occured: ${error}`
				: 'An error occured',
			{ appearance: 'error' }
		);

	const [email, setEmail] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');
	const isLoading = useSelector(selectLoading);

	const onSubmit = () => {
		dispatch(
			signIn(
				email,
				password,
				() => {
					addToast('Successfully logged in!', {
						appearance: 'success',
					});
					push('/home');
				},
				errorCallback
			)
		);
	};

	return (
		<SignInForm
			email={email}
			onEmailChange={(v) => setEmail(v)}
			password={password}
			onPasswordChange={(v) => setPassword(v)}
			onSubmit={onSubmit}
			isLoading={isLoading}
		/>
	);
};

export default SignIn;
