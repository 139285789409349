import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './style.css';

type Props = {
	value: string;
	setValue?: (value: string) => void;
	label?: string | null;
	readonly?: boolean | null;
	maxLength?: number | null;
};

export const Editor: React.FC<Props> = ({
	value,
	setValue = null,
	label = null,
	readonly = false,
	maxLength = null,
}) => {
	const reactQuillRef = React.useRef();
	const [count, setCount] = useState(value.length);

	const checkCharacterCount = (event) => {
		const currentRef: any = reactQuillRef.current;
		if (currentRef != null && maxLength != null) {
			var unprivilegedEditor = currentRef.unprivilegedEditor;
			var length = unprivilegedEditor.getHTML().length;
			if (length >= maxLength && event.key !== 'Backspace')
				event.preventDefault();
		}
	};

	const setContentLength = (event) => {
		const currentRef: any = reactQuillRef.current;
		if (currentRef != null && maxLength != null) {
			var unprivilegedEditor = currentRef.unprivilegedEditor;
			var length = unprivilegedEditor.getHTML().length;
			setCount(length);
		}
	};

	const modules = {
		toolbar: readonly
			? false
			: [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline'],
					[{ list: 'ordered' }, { list: 'bullet' }],
			  ],
	};

	const formats = ['header', 'bold', 'italic', 'underline', 'list', 'bullet'];

	return (
		<>
			{label && (
				<Form.Label column sm={3}>
					{label.trim()}
				</Form.Label>
			)}
			<ReactQuill
				ref={reactQuillRef}
				theme='snow'
				onKeyDown={checkCharacterCount}
				onKeyUp={setContentLength}
				formats={formats}
				modules={modules}
				value={value}
				onChange={setValue}
				readOnly={readonly}
			/>
			{maxLength && (
				<div className='text-length'>
					{count}/{maxLength}
				</div>
			)}
		</>
	);
};

export default Editor;
