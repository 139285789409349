import { Action, Reducer } from 'redux';
import { CertificationActions, CertificationActionTypes } from './actions';
import { CertificationState, unloadedState } from './state';

//const allSameType = (educations:Education[], type:EducationType) => educations.every(e => e.type === type);

export const certificationReducer: Reducer<CertificationState> = (
	state: CertificationState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as CertificationActions;
	switch (action.type) {
		case CertificationActionTypes.SET_CERTIFICATIONS:
			// if((allSameType(state.educations, EducationType.FORMATION) && allSameType(action.educations, EducationType.CERTIFICATION)) || allSameType(state.educations, EducationType.CERTIFICATION) && allSameType(action.educations, EducationType.FORMATION)){
			// 	state.educations = [...state.educations, ...action.educations];
			// } else {
				state.certifications = action.certifications;
			//}
			
			return { ...state }

		case CertificationActionTypes.UPDATE_CERTIFICATION:
			state.certifications = state.certifications.map((certification) => certification.id === action.certification.id ? action.certification : certification);
			return { ...state }

		case CertificationActionTypes.ADD_CERTIFICATIONS:
			state.certifications.push(...action.certifications);
			return { ...state }

		case CertificationActionTypes.DELETE_CERTIFICATION:
			state.certifications = state.certifications.filter(e => e.id !== action.certificationId);
			return { ...state }

		default:
			return state;
	}
};
