import { TemplateState, templateReducer } from './template';
import { LevelState, levelReducer } from './level';
import { UserState, userReducer } from './user';
import { GenderState, genderReducer } from './gender';
import { StatusState, statusReducer } from './employeeStatus';
import { ProfileState, profileReducer } from './profile';
import { CandidateState, candidateReducer } from './candidate';
import { SupplierState } from './supplier/state';
import { supplierReducer } from './supplier';
import { summaryReducer, SummaryState } from './summary';
import { referenceReducer, ReferenceState } from './reference';
import { EducationState } from './education/state';
import { educationReducer } from './education/reducer';
import { CertificationState } from './certification/state';
import { certificationReducer } from './certification/reducer';
import { ExperienceState } from './experience/state';
import { experienceReducer } from './experience/reducer';
import { skillReducer, SkillState } from './skill';
import { LanguageState } from './language/state';
import { languageReducer } from './language/reducer';
import { RouterState } from 'connected-react-router';

// The top-level state object
export interface ApplicationState {
	userFeature: UserState | undefined;
	genderFeature: GenderState | undefined;
	statusFeature: StatusState | undefined;
	profileFeature: ProfileState | undefined;
	levelFeature: LevelState | undefined;
	templateFeature: TemplateState | undefined;
	candidateFeature: CandidateState | undefined;
	supplierFeature: SupplierState | undefined;
	summaryFeature: SummaryState | undefined;
	referenceFeature: ReferenceState | undefined;
	educationFeature: EducationState | undefined;
	certificationFeature: CertificationState | undefined;
	experienceFeature: ExperienceState | undefined;
	skillFeature: SkillState | undefined;
	languageFeature: LanguageState | undefined;
}

export interface RootState extends ApplicationState {
	router: RouterState<unknown> | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	userFeature: userReducer,
	genderFeature: genderReducer,
	statusFeature: statusReducer,
	profileFeature: profileReducer,
	levelFeature: levelReducer,
	templateFeature: templateReducer,
	candidateFeature: candidateReducer,
	supplierFeature: supplierReducer,
	summaryFeature: summaryReducer,
	referenceFeature: referenceReducer,
	skillFeature: skillReducer,
	educationFeature: educationReducer,
	certificationFeature: certificationReducer,
	experienceFeature: experienceReducer,
	languageFeature: languageReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
	(
		dispatch: (action: TAction) => void,
		getState: () => ApplicationState
	): void;
}
