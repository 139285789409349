import { Action, Reducer } from 'redux';

import { SupplierActions, SupplierActionTypes } from './actions';
import { SupplierState, unloadedState } from './state';


export const supplierReducer: Reducer<SupplierState> = (
	state: SupplierState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as SupplierActions;
	switch (action.type) {
		case SupplierActionTypes.SET_SUPPLIERS:
			state.suppliers = action.suppliers;
			return { ...state }

		case SupplierActionTypes.UPDATE_SUPPLIER:
			state.suppliers = state.suppliers.map((supplier, index) => index === action.index ? action.supplier : supplier);
			return { ...state }

		case SupplierActionTypes.DELETE_SUPPLIER_REFERENCE:
			state.suppliers = state.suppliers.filter(e => e.supplierEmail !== action.supplierEmail);
			return { ...state }

		case SupplierActionTypes.ADD_SUPPLIER_REFERENCE:
			state.suppliers.push(action.supplier);
			return { ...state }

		case SupplierActionTypes.SET_ALL_SUPPLIERS_EMAILS:
			state.allSuppliersMails = action.suppliersEmails;
			return { ...state }

		default:
			return state;
	}
};
