import { Supplier } from '../../models';

export interface SupplierState {
	suppliers: Supplier[];
	allSuppliersMails: string[];
}

export const unloadedState: SupplierState = {
	suppliers: [],
	allSuppliersMails: []
};

export const reset: () => SupplierState = () => {
	return {
		suppliers: [],
		allSuppliersMails: []
	} as SupplierState
}