import { createSelector } from 'reselect';
import { Summary } from 'src/models';

import { ApplicationState } from '../';
import { SummaryState } from './state';

const summarySelector = (state: ApplicationState) => state.summaryFeature;

export const selectSummary = (selectedProfile: string) => createSelector(
	summarySelector,
	(summaryState: SummaryState) => summaryState.summaries.find(s => s.profile == selectedProfile)
);

export const selectSummaries = (selectedProfile: string) => createSelector(
	summarySelector,
	(SummaryState: SummaryState) => SummaryState.summaries.filter(
		summary => isSummaryFromSelectedProfileAndTemplate(summary, selectedProfile)
	)
);

function isSummaryFromSelectedProfileAndTemplate(summary: Summary, profile: string): boolean {
	return ((!profile || summary.profile === profile));
}