import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import { reducers, RootState } from './';
import { reset as resetCandidate } from './candidate/state';
import { reset as resertCertificationState } from './certification/state';
import { reset as resetEducationState } from './education/state';
import { reset as resetExperienceState } from './experience/state';
import { reset as resetLanguageState } from './language/state';
import { reset as resetReferenceState } from './reference/state';
import { RootActionTypes } from './root/actions';
import { reset as resetSkillState } from './skill/state';
import { reset as resetSummaryState } from './summary/state';
import { reset as resetSupplierState } from './supplier/state';

export default function configureStore(history: History, initialState?) {
	const middleware = [thunk, routerMiddleware(history)];

	const allReducers = combineReducers({
		...reducers,
		router: connectRouter(history),
	});

	const rootReducer = (state: RootState, action) => {
		if (action.type === RootActionTypes.RESET_CANDIDATE) {
			const {
				router,
				userFeature,
				genderFeature,
				statusFeature,
				profileFeature,
				levelFeature,
				templateFeature,
				candidateFeature,
			} = state;

			state = {
				router,
				userFeature,
				genderFeature,
				statusFeature,
				levelFeature,
				templateFeature: {
					...templateFeature,
					selectedTemplate: '',
				},
				profileFeature: {
					...profileFeature,
					selectedProfile: '',
					existingProfiles: [],
				},
				candidateFeature: resetCandidate(candidateFeature.candidates),
				supplierFeature: resetSupplierState(),
				summaryFeature: resetSummaryState(),
				referenceFeature: resetReferenceState(),
				educationFeature: resetEducationState(),
				certificationFeature: resertCertificationState(),
				experienceFeature: resetExperienceState(),
				skillFeature: resetSkillState(),
				languageFeature: resetLanguageState(),
			};
		}
		return allReducers(state, action);
	};

	const persistedReducer = persistReducer(
		{
			key: 'root',
			storage,
		},
		rootReducer
	);

	const enhancers = [];
	const windowIfDefined =
		typeof window === 'undefined' ? null : (window as any); // eslint-disable-line @typescript-eslint/no-explicit-any
	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
	}

	return createStore(
		persistedReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);
}
