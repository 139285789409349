import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { Skill, OpenAiParsedSkill } from "src/models";
import { SkillCategory } from "src/models/SkillCategory";
import { AddExistingProfile, selectSelectedProfile } from "src/store/profile";
import { addSkills } from "src/store/skill";
import CreateOrEditModal from "../CreateOrEditModal";
import FormInput from "../FormInput";
import { getParsedSkills } from '../../api';
import Loading from '../Loading';

type Props = {
    candidateEmail: string | undefined;
    category: SkillCategory | undefined;
}

export const CreateSkillModal: React.FC<Props> = ({ candidateEmail, category }) => {
    const dispatch: Dispatch<any> = useDispatch();
    const profile = useSelector(selectSelectedProfile);
    const createNewSkills = bindActionCreators(addSkills, dispatch);

    const [skillsList, setSkillsList] = useState('');

    const resetSkill = () => {
        setSkillsList('');
    };

    const saveData = async (
        handleClose: () => void,
        handleError: (errors: string[]) => void
    ) => {
        dispatch(AddExistingProfile(profile));

        const parsedSkill: OpenAiParsedSkill[] = await getParsedSkills(skillsList);
        const skills: Skill[] = parsedSkill.map((ps) => {
            return {
                ...ps,
                candidateEmail: candidateEmail,
                id: '',
                profile: profile,
                category: category
            } as Skill;
        });

        createNewSkills(
            skills,
            candidateEmail,
            handleClose,
            handleError
        );
    };

    return (
        <CreateOrEditModal label={`Add ${category} Skills`} title={`Add new ${category} Skills`} resetModel={resetSkill} saveData={saveData}>
            <FormInput
                label='Profile'
                readOnly
                disabled
                required
                fieldValue={profile}
            />
            <FormInput
                label='Skills'
                type='textarea'
                maxLength={2000}
                fieldValue={skillsList}
                setFieldValue={(value: string) =>
                    setSkillsList(value)
                }
            />
        </CreateOrEditModal>
    );
}

export default CreateSkillModal;