import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'react-bootstrap';
import { Candidate, Education, OpenAiParsedEducation } from 'src/models';
import { createEducations } from 'src/store/education';
import { getParsedEducations } from '../../api';
import CreateOrEditModal from '../CreateOrEditModal';
import FormInput from '../FormInput';
import { EducationForm } from './Form';

type Props = {
	candidate: Candidate;
};

export const CreateEducationModal: React.FC<Props> = ({ candidate }) => {
	const dispatch = useDispatch();
	const createNewEducations = bindActionCreators(createEducations, dispatch);

	const [education, setEducation] = useState({
		id: null,
		candidateEmail: candidate.candidateEmail,
		degree: null,
		faculty: null,
		year: null,
		school: null,
	} as Education);

	const [resumedEducation, setResumedEducation] = useState('');
	const [isManuallyHandlingEducation, setIsManuallyHandlingEducation] =
		useState(false);
	const [hasParsingError, setHasParsingError] = useState(false);

	const resetModel = () => {
		setEducation({
			id: null,
			candidateEmail: candidate.candidateEmail,
			degree: null,
			faculty: null,
			year: null,
			school: null,
		} as Education);
		setResumedEducation('');
		setHasParsingError(false);
		setIsManuallyHandlingEducation(false);
	};

	const saveData = async (
		handleClose: () => void,
		handleError: (errors: string[]) => void,
		resetValidation: () => void
	) => {
		try {
			var educations: Education[] = [];

			if (isManuallyHandlingEducation) {
				educations = [education];
			} else {
				const parsedEducation: OpenAiParsedEducation[] =
					await getParsedEducations(resumedEducation);

				educations = parsedEducation.map((pe) => {
					return {
						...pe,
						candidateEmail: candidate.candidateEmail,
						id: '',
					} as Education;
				});
			}
			createNewEducations(
				educations,
				candidate.candidateEmail,
				handleClose,
				handleError
			);
		} catch {
			handleError([]);
			resetValidation();
			setHasParsingError(true);
		}
	};

	const handleExperienceManually = () => {
		if (hasParsingError) setHasParsingError(false);
		setIsManuallyHandlingEducation(true);
	};

	return (
		<CreateOrEditModal
			label={'Add educations'}
			title={'Add new educations'}
			resetModel={resetModel}
			saveData={saveData}
			additionnalOption={
				isManuallyHandlingEducation ? null : (
					<Button
						style={{ marginRight: '10px' }}
						onClick={handleExperienceManually}
					>
						Enter education manually
					</Button>
				)
			}
		>
			{hasParsingError && (
				<div className='alert alert-danger' role='alert'>
					<p className='mb-0'>
						An error occured while parsing the experience
					</p>
				</div>
			)}

			{!isManuallyHandlingEducation && (
				<FormInput
					label='Educations'
					type='textarea'
					maxLength={2000}
					fieldValue={resumedEducation}
					setFieldValue={(value: string) =>
						setResumedEducation(value)
					}
				/>
			)}

			{isManuallyHandlingEducation && (
				<EducationForm
					education={education}
					saveEducation={setEducation}
				></EducationForm>
			)}
		</CreateOrEditModal>
	);
};

export default CreateEducationModal;
