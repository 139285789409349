import { createSelector } from 'reselect';
import { Skill } from 'src/models/Skill';
import { SkillCategory } from 'src/models/SkillCategory';

import { ApplicationState } from '../';
import { SkillState } from './state';

const skillSelector = (state: ApplicationState) => state.skillFeature;

export const selectSkills = createSelector( // TODO necessary?
	skillSelector,
	(skillState: SkillState) => skillState.skills
);

export const selectSkillsForProfile = (selectedProfile: string) => createSelector(
	skillSelector,
	(skillState: SkillState) => skillState.skills.filter((skill) =>
		isSkillFromSelectedProfile(skill, selectedProfile)
	)
)

export const selectManagementSkills = (selectedProfile: string) => createSelector(
	skillSelector,
	(skillState: SkillState) => skillState.skills.filter((skill) =>
		skill.category === SkillCategory.SKILL_MANAGEMENT && isSkillFromSelectedProfile(skill, selectedProfile)
	)
)

export const selectTechnicalSkills = (selectedProfile: string) => createSelector(
	skillSelector,
	(skillState: SkillState) => skillState.skills.filter((skill) =>
	 	skill.category === SkillCategory.SKILL_TECHNICAL && isSkillFromSelectedProfile(skill, selectedProfile)
	)
)

export const selectOtherSkills = (selectedProfile: string) => createSelector(
	skillSelector,
	(skillState: SkillState) => skillState.skills.filter((skill) =>
	 	skill.category === SkillCategory.SKILL_OTHER && isSkillFromSelectedProfile(skill, selectedProfile)
	)
)

function isSkillFromSelectedProfile(skill: Skill, profile: string): boolean {
	return (!profile || skill.profile === profile);
}