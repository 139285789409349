import { useParams } from 'react-router';
import useQuery from './useQuery';

const useEmailParameter = (): { email: string; isEdit: boolean } => {
	let { email } = useParams<{ email: string }>();

	const isEdit = !!email;
	if (!isEdit) {
		const query = useQuery();
		email = query.get('email');
	}

	return { email, isEdit };
};

export default useEmailParameter;
