import { Summary } from 'src/models/Summary';

export interface SummaryState {
	summaries: Summary[];
}

export const unloadedState: SummaryState = {
	summaries: []
};

export const reset: () => SummaryState = () => {
	return {
		summaries: []
	} as SummaryState
}