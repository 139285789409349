import { Action } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError } from '../../models';
import { AppThunkAction } from '..';

export enum StatusActionTypes {
	GET_STATUSES = 'GetStatuses',
	GET_STATUSES_SUCCESS = 'GetStatusesSuccess',
	GET_STATUSES_FAILURE = 'GetStatusesFailure',
}

// ACTIONS
export interface GetStatusesAction extends Action {
	type: StatusActionTypes.GET_STATUSES;
	loading: boolean;
}
const GetStatuses = (): GetStatusesAction => {
	return { type: StatusActionTypes.GET_STATUSES, loading: true };
};

export interface GetStatusesActionSuccess extends Action {
	type: StatusActionTypes.GET_STATUSES_SUCCESS;
	statuses: string[];
}
const GetStatusesSuccess = (statuses: string[]): GetStatusesActionSuccess => {
	return { type: StatusActionTypes.GET_STATUSES_SUCCESS, statuses };
};

export interface GetStatusesActionFailure extends Action {
	type: StatusActionTypes.GET_STATUSES_FAILURE;
	error: string;
}
const GetStatusesFailure = (error: string): GetStatusesActionFailure => {
	return { type: StatusActionTypes.GET_STATUSES_FAILURE, error };
};

export type StatusActions =
	| GetStatusesAction
	| GetStatusesActionSuccess
	| GetStatusesActionFailure;

// ACTION CREATORS
export const getStatuses =
	(
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<StatusActions> =>
	(dispatch) => {
		dispatch(GetStatuses());
		defaultInstance
			.get('/v1/enums/employeeStatuses', { headers: getAuthHeader() })
			.then((response: AxiosResponse<string[]>) => {
				dispatch(GetStatusesSuccess(response.data));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};
