import { Action, Reducer } from 'redux';

import { filterAndSort } from 'src/utils';
import { StatusActions, StatusActionTypes } from './actions';
import { unloadedState, StatusState } from './state';

export const statusReducer: Reducer<StatusState> = (
	state: StatusState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as StatusActions;
	switch (action.type) {
		case StatusActionTypes.GET_STATUSES:
			return { ...state, loading: true };
		case StatusActionTypes.GET_STATUSES_SUCCESS:
			return {
				...state,
				loading: false,
				statuses: filterAndSort(action.statuses),
			};
		case StatusActionTypes.GET_STATUSES_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			};
		default:
			return state;
	}
};
