import { Action, Dispatch } from "redux";
import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { SkillCategory } from "src/models/SkillCategory";
import { AppThunkAction } from "..";
import { ApiError, Skill } from "src/models";

export enum SkillActionTypes {
    GET_SKILLS = 'GetSkills',
    SET_SKILLS = 'SetSkills',
    ADD_SKILLS = 'AddSkills',
    UPDATE_SKILL = 'UpdateSkill',
    DELETE_SKILL = 'DeleteSkill'
}

export interface GetSkillsAction extends Action {
    type: SkillActionTypes.GET_SKILLS;
}

export interface SetSkillsAction extends Action { 
    type: SkillActionTypes.SET_SKILLS;
    skills: Skill[];
    category: SkillCategory;
}

export interface AddSkillsAction extends Action {
	type: SkillActionTypes.ADD_SKILLS;
	skills: Skill[];
}

export interface UpdateSkillAction extends Action {
    type: SkillActionTypes.UPDATE_SKILL;
    skill: Skill
}

export interface DeleteSkillAction extends Action {
    type: SkillActionTypes.DELETE_SKILL;
    skillId: string;
}

const SetSkills = (skills: Skill[], category: SkillCategory): SetSkillsAction => {
    return { type: SkillActionTypes.SET_SKILLS, skills, category };
}

const AddSkills = (skills: Skill[]): AddSkillsAction => {
	return { type: SkillActionTypes.ADD_SKILLS, skills: skills };
}

const UpdateSkill = (skill: Skill): UpdateSkillAction => {
    return { type: SkillActionTypes.UPDATE_SKILL, skill: skill };
}

const DeleteSkill = (skillId: string): DeleteSkillAction => {
    return { type: SkillActionTypes.DELETE_SKILL, skillId: skillId };
}

export type SkillActions = GetSkillsAction | SetSkillsAction | AddSkillsAction | UpdateSkillAction | DeleteSkillAction;

export const getSkills =
(
    candidateEmail: string,
    skillType: SkillCategory,
    successCallback: () => void = () => { },
    failureCallback: (error?: string) => void = () => { }
): AppThunkAction<SkillActions> => (dispatch) => {
    defaultInstance.get(
            `/v1/candidates/${candidateEmail}/skills`,
            { headers: getAuthHeader(), params: { category: skillType } }
        )
        .then((response: AxiosResponse<Skill[]>) => {
            response.data.map(s => {
                s.name = s.name ?? ''
            });
            dispatch(SetSkills(response.data, skillType));
            successCallback();
        })
        .catch((error: AxiosError<ApiError>) => {
            if (error && error.response && error.response.data) {
                failureCallback(error.response.data.detail);
            } else console.error(error);
        });
}

export const addSkills =
	(
		skills: Skill[],
		candidateEmail: string,
		successCallback: () => void = () => { },
        failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<SkillActions> =>
		(dispatch) => {
			defaultInstance
				.post(`/v1/candidates/${candidateEmail}/skills`, { skills }, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Skill[]>) => {
					dispatch(AddSkills(response.data))
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const updateSkill = 
(
    skill: Skill,
    successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
): AppThunkAction<SkillActions> => (dispatch) => {
    defaultInstance.put(
        `/v1/candidates/${skill.candidateEmail}/skills/${skill.id}`,
        skill,
        { headers: getAuthHeader() }
    )
    .then((response: AxiosResponse<Skill>) => {
        response.data.name = response.data.name ?? '';
        dispatch(UpdateSkill(response.data));
        successCallback();
    })
    .catch((error: AxiosError<ApiError>) => {
		if (error && error.response && error.response.data) {
			failureCallback(error.response.data.detail);
		} else console.error(error);
	});
}

export const deleteSkill =
(
    skill: Skill,
    successCallback: () => void = () => {},
	failureCallback: (error?: string) => void = () => {}
): AppThunkAction<SkillActions> => (dispatch) => {
    defaultInstance.delete(
        `/v1/candidates/${skill.candidateEmail}/skills/${skill.id}`,
        { headers: getAuthHeader() }
    )
    .then(() => {
        dispatch(DeleteSkill(skill.id));
        successCallback();
    })
    .catch((error: AxiosError<ApiError>) => {
		if (error && error.response && error.response.data) {
			failureCallback(error.response.data.detail);
		} else console.error(error);
	});
}

export const updateStoreSkill = (skill: Skill) => {
    return (dispatch: Dispatch) => {
        dispatch(UpdateSkill(skill))
    }
}

export const deleteStoreSkill = (skillId: string) => {
    return (dispatch: Dispatch) => {
        dispatch(DeleteSkill(skillId))
    }
}
