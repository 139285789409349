import { createSelector } from 'reselect';
import { Education } from 'src/models';

import { ApplicationState } from '../';
import { EducationState } from './state';

const educationSelector = (state: ApplicationState) => state.educationFeature;

export const selectEducations = () => createSelector(
	educationSelector,
	(educationState: EducationState) => 
		educationState.educations
			.sort((a: Education, b:Education) => b.year - a.year)
			.filter(c => c)
)