import { Education } from 'src/models/Education';

export interface EducationState {
	educations: Education[];
}

export const unloadedState: EducationState = {
	educations: []
};

export const reset: () => EducationState = () => {
	return {
		educations: []
	} as EducationState
}