import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button } from 'react-bootstrap';
import { getParsedCertifications } from 'src/api';
import {
	Candidate,
	Certification,
	OpenAiParsedCertification,
} from 'src/models';
import { createCertifications } from 'src/store/certification';
import CreateOrEditModal from '../CreateOrEditModal';
import FormInput from '../FormInput';
import { CertificationForm } from './Form';

type Props = {
	candidate: Candidate;
};

export const CreateCertificationModal: React.FC<Props> = ({ candidate }) => {
	const dispatch = useDispatch();
	const createNewCertifications = bindActionCreators(
		createCertifications,
		dispatch
	);

	const [certification, setCertification] = useState({
		id: null,
		candidateEmail: candidate.candidateEmail,
		certificationName: null,
		year: null,
		organization: null,
	} as Certification);

	const [resumedCertification, setResumedCertification] = useState('');
	const [
		isManuallyHandlingCertification,
		setIsManuallyHandlingCertification,
	] = useState(false);
	const [hasParsingError, setHasParsingError] = useState(false);

	const resetModel = () => {
		setCertification({
			id: null,
			candidateEmail: candidate.candidateEmail,
			certificationName: null,
			year: null,
			organization: null,
		} as Certification);
		setHasParsingError(false);
		setIsManuallyHandlingCertification(false);
		setResumedCertification('');
	};

	const saveData = async (
		handleClose: () => void,
		handleError: (errors: string[]) => void,
		resetValidation: () => void
	) => {
		try {
			var certifications: Certification[] = [];

			if (isManuallyHandlingCertification) {
				certifications = [certification];
			} else {
				const parsedCertification: OpenAiParsedCertification[] =
					await getParsedCertifications(resumedCertification);

				certifications = parsedCertification.map((pc) => {
					return {
						...pc,
						candidateEmail: candidate.candidateEmail,
						id: '',
					} as Certification;
				});
			}
			createNewCertifications(
				certifications,
				candidate.candidateEmail,
				handleClose,
				handleError
			);
		} catch {
			handleError([]);
			resetValidation();
			setHasParsingError(true);
		}
	};

	const handleCertificationManually = () => {
		if (hasParsingError) setHasParsingError(false);
		setIsManuallyHandlingCertification(true);
	};

	return (
		<CreateOrEditModal
			label={'Add certifications'}
			title={'Add new certifications'}
			resetModel={resetModel}
			saveData={saveData}
			additionnalOption={
				isManuallyHandlingCertification ? null : (
					<Button
						style={{ marginRight: '10px' }}
						onClick={handleCertificationManually}
					>
						Enter certification manually
					</Button>
				)
			}
		>
			{hasParsingError && (
				<div className='alert alert-danger' role='alert'>
					<p className='mb-0'>
						An error occured while parsing the experience
					</p>
				</div>
			)}

			{!isManuallyHandlingCertification && (
				<FormInput
					label='Certifications'
					type='textarea'
					maxLength={2000}
					fieldValue={resumedCertification}
					setFieldValue={(value: string) =>
						setResumedCertification(value)
					}
				/>
			)}

			{isManuallyHandlingCertification && (
				<CertificationForm
					certification={certification}
					saveCertification={setCertification}
				></CertificationForm>
			)}
		</CreateOrEditModal>
	);
};

export default CreateCertificationModal;
