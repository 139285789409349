import { Action } from 'redux';

import { AppThunkAction } from '..';
import { AxiosResponse, defaultInstance, getAuthHeader } from '../../api';
import { ApiError, Candidate } from '../../models';

export enum PdfActionTypes {
	GENERATE_PDF = 'GeneratePdf',
}

// ACTIONS
export interface GeneratePdfAction extends Action {
	type: PdfActionTypes.GENERATE_PDF;
	loading: boolean;
}

export type PdfActions = GeneratePdfAction;

// ACTION CREATORS
export const generatePdf =
	(
		candidate: Candidate,
		profile: string,
		template: string,
		title: string,
		successCallback: () => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<PdfActions> =>
	(dispatch) => {
		defaultInstance
			.get(`v1/candidates/${candidate.candidateEmail}/cv`, {
				headers: getAuthHeader(),
				params: { profile, template, title },
				responseType: 'blob',
			})
			.then((response: AxiosResponse) => {
				successCallback();
				const fileName = `${
					template === 'BNP' ? 'CCES' : template.toUpperCase()
				}_CV ${candidate.lastName.toUpperCase()} ${
					candidate.firstName
				}.pdf`;

				const href = URL.createObjectURL(response.data);

				// create "a" HTML element with href to file & click
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();

				// clean up "a" element & remove ObjectURL
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			})
			.catch((error) => {
				if (error && error.response && error.response.data) {
					const fr = new FileReader();

					fr.onload = function () {
						const errorResponse: ApiError = JSON.parse(
							fr.result as string
						);
						const errorsToDisplay = Object.values(
							errorResponse.errors
						).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					};

					fr.readAsText(error.response.data);
				} else console.error(error);
			});
	};

export const getCvHtmlTemplate =
	(
		candidate: Candidate,
		profile: string,
		template: string,
		title: string,
		successCallback: (value: string) => void = () => {},
		failureCallback: (errors?: string[]) => void = () => {}
	): AppThunkAction<PdfActions> =>
	(dispatch) => {
		defaultInstance
			.get(`v1/candidates/${candidate.candidateEmail}/cv/preview`, {
				headers: getAuthHeader(),
				params: { profile, template, title },
			})
			.then((response: AxiosResponse) => {
				successCallback(response.data);
			})
			.catch((error) => {
				if (error && error.response && error.response.data) {
					const fr = new FileReader();

					fr.onload = function () {
						const errorResponse: ApiError = JSON.parse(
							fr.result as string
						);
						const errorsToDisplay = Object.values(
							errorResponse.errors
						).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					};

					fr.readAsText(error.response.data);
				} else console.error(error);
			});
	};
