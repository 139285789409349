import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useEffect, useState } from 'react';
import { Candidate, Certification } from 'src/models';
import {
	saveCertification,
} from 'src/store/certification';
import CreateOrEditModal from '../CreateOrEditModal';
import { CertificationForm } from './Form';

type Props = {
	candidate: Candidate;
	certification: Certification;
};

export const UpdateCertificationModal: React.FC<Props> = ({
	candidate,
	certification,
}) => {
	const dispatch = useDispatch();
	const saveCertificationAction = bindActionCreators(saveCertification, dispatch);

	const [initialCertificationState, setInitialCertificationState] = useState(null);
	const [internalCertification, setInternalCertification] = useState(null);

	useEffect(() => {
		setInitialCertificationState(certification);
		setInternalCertification(certification);
	}, [certification])

	const onCancel = () => {
		setInternalCertification({ ...initialCertificationState });
	};

	const saveData = (
		handleClose: () => void,
		handleError: (errors: string[]) => void
	) => {
		saveCertificationAction(
			internalCertification,
			candidate.candidateEmail,
			certification.id,
			handleClose,
			handleError
		);
	};

	return (
		<CreateOrEditModal
			label={'Edit'}
			title={'Edit certification'}
			isEdit={true}
			resetModel={() => { }}
			saveData={saveData}
			onCancel={onCancel}
		>
			<CertificationForm
				certification={internalCertification}
				saveCertification={setInternalCertification}
			/>
		</CreateOrEditModal>
	);
};

export default UpdateCertificationModal;