import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { TemplateState } from './state';

const templateSelector = (state: ApplicationState) => state.templateFeature;

export const selectTemplates = createSelector(
	templateSelector,
	(templateState: TemplateState) => templateState.templates
);

export const selectError = createSelector(
	templateSelector,
	(templateState: TemplateState) => templateState.error
);

export const selectLoading = createSelector(
	templateSelector,
	(templateState: TemplateState) => templateState.loading
);

export const selectSelectedTemplate = createSelector(
	templateSelector,
	(templateState: TemplateState) => templateState.selectedTemplate
)
