import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Candidate } from "src/models";
import { addSupplierReference, getAllSuppliers } from "src/store/supplier";
import { selectAllNonReferencedSuppliersEmails } from "src/store/supplier/selectors";
import CreateOrEditModal from "../CreateOrEditModal";
import FormInput from "../FormInput";

type Props = {
    candidate: Candidate;
}

export const CreateSupplierReferenceModal: React.FC<Props> = ({ candidate }) => {
    const [supplierEmail, setSupplierEmail] = useState("");
    const dispatch = useDispatch();
    let suppliers = useSelector(selectAllNonReferencedSuppliersEmails);

    useEffect(() => {
        if (suppliers.length === 0) {
            const fetchAllSuppliers = bindActionCreators(getAllSuppliers, dispatch);
            fetchAllSuppliers();
        }
    }, [])

    const createNewSupplierReference = bindActionCreators(addSupplierReference, dispatch);
    const resetSupplierEmail = () => setSupplierEmail("");
    const saveData = (handleClose: () => void) => createNewSupplierReference(candidate.candidateEmail, supplierEmail, handleClose);

    return (
        <CreateOrEditModal label={`Add supplier`} title={`Add a new supplier`} resetModel={resetSupplierEmail} saveData={saveData}>
            <FormInput
                type="select"
                label='Suppliers'
                values={suppliers}
                required
                fieldValue={supplierEmail}
                setFieldValue={(value: string) =>
                    setSupplierEmail(value)
                }
            />
        </CreateOrEditModal>
    );
}

export default CreateSupplierReferenceModal;