import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import { Experience } from 'src/models';
import { Description } from 'src/models/Description';
import { dateCreator, dateFormat, findDateFormat } from 'src/utils';
import DeleteButton from '../DeleteButton';
import Editor from '../Editor';
import FormInput from '../FormInput';
import IconButton from '../IconButton';

type Props = {
	experience: Experience;
	description: Description;
	saveExperience: (experience: Experience) => void;
	saveDescription: (description: Description) => void;
};

export const ExperienceForm: React.FC<Props> = ({
	experience,
	description,
	saveExperience,
	saveDescription,
}) => {
	const [datesFormat, setDatesFormat] = useState(
		experience.startDate ? findDateFormat(experience.startDate) : 'MM/yyyy'
	);

	return (
		<>
			<Row>
				<Col>
					<FormInput
						label='Company'
						required
						fieldValue={experience.company}
						setFieldValue={(value: string) =>
							saveExperience({
								...experience,
								company: value,
							})
						}
					/>
				</Col>
				<Col>
					<FormInput
						label='Client'
						fieldValue={experience.client}
						setFieldValue={(value: string) =>
							saveExperience({
								...experience,
								client: value,
							})
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormInput
						label='Start date'
						type='date'
						dateFormat={datesFormat}
						required
						fieldValue={dateCreator(experience.startDate)}
						setFieldValue={(value: Date) => {
							saveExperience({
								...experience,
								startDate: dateFormat(value, datesFormat),
							});
						}}
					/>
					<FormInput
						label='Date format'
						type='select'
						values={['MM/yyyy', 'yyyy']}
						fieldValue={datesFormat}
						setFieldValue={(value: string) => {
							setDatesFormat(value);
							saveExperience({
								...experience,
								dateFormat: value,
								startDate: dateFormat(
									dateCreator(experience.startDate),
									value
								),
								endDate: dateFormat(
									dateCreator(experience.endDate),
									value
								),
							});
						}}
					/>
				</Col>
				<Col>
					<FormInput
						label='End date'
						type='date'
						dateFormat={datesFormat}
						fieldValue={dateCreator(experience.endDate)}
						setFieldValue={(value: Date) => {
							saveExperience({
								...experience,
								endDate: dateFormat(value, datesFormat),
							});
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FormInput
						label='Location'
						fieldValue={experience.location}
						setFieldValue={(value: string) =>
							saveExperience({
								...experience,
								location: value,
							})
						}
					/>
				</Col>
				<Col>
					<FormInput
						label='Role'
						required
						maxLength={200}
						fieldValue={experience.role}
						setFieldValue={(value: string) =>
							saveExperience({
								...experience,
								role: value,
							})
						}
					/>
				</Col>
			</Row>

			<FormInput
				label='Tools'
				type='textarea'
				maxLength={1000}
				fieldValue={experience.tools}
				setFieldValue={(value: string) =>
					saveExperience({ ...experience, tools: value })
				}
			/>
			<FormInput
				label='Environment'
				type='textarea'
				maxLength={1000}
				fieldValue={experience.environment}
				setFieldValue={(value: string) =>
					saveExperience({ ...experience, environment: value })
				}
			/>
			<FormInput
				label='Methodology'
				type='textarea'
				maxLength={1000}
				fieldValue={experience.methodology}
				setFieldValue={(value: string) =>
					saveExperience({ ...experience, methodology: value })
				}
			/>
			<FormInput
				label='Technologies'
				type='textarea'
				maxLength={1000}
				fieldValue={experience.technologies}
				setFieldValue={(value: string) =>
					saveExperience({
						...experience,
						technologies: value,
					})
				}
			/>

			{description?.longDescription != null ? (
				<>
					{description.experienceId === null && (
						<div
							style={{
								fontWeight: 'bold',
								marginBottom: '10px',
								display: 'flex',
								justifyContent: 'end',
							}}
						>
							<div>
								<DeleteButton
									label='Delete description'
									deleteData={() =>
										saveDescription({
											...description,
											longDescription: null,
										})
									}
								/>
							</div>
						</div>
					)}
					<Editor
						maxLength={2000}
						label='Description'
						value={description.longDescription}
						setValue={(value) =>
							saveDescription({
								...description,
								longDescription: value,
							})
						}
					/>
				</>
			) : (
				<>
					<IconButton
						onClick={() => {
							saveDescription({
								...description,
								longDescription: '',
							});
						}}
						variant={'primary'}
						icon={<MdAdd color='white' />}
						label={'Add description'}
					/>
				</>
			)}
		</>
	);
};
