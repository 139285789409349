import { Action } from "redux";

export enum RootActionTypes {
    RESET_CANDIDATE = 'ResetCandidate',
}

export interface ResetCandidateAction extends Action {
	type: RootActionTypes.RESET_CANDIDATE;
}
export const ResetCandidate = (): ResetCandidateAction => {
	return { type: RootActionTypes.RESET_CANDIDATE };
};
