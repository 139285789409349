import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Candidate, Summary } from 'src/models';
import { deleteSummary, saveSummary, updateSummary } from 'src/store/summary';
import DeleteButton from '../DeleteButton';
import Editor from '../Editor';
import { CreateSummaryModal } from '../SummaryForm';

type Props = {
	candidate: Candidate;
	summary: Summary | null;
};

export const SummaryPreview: React.FC<Props> = ({ candidate, summary }) => {
	const dispatch = useDispatch();
	const updateSummaryState = bindActionCreators(updateSummary, dispatch);
	const updateSummaryDb = bindActionCreators(saveSummary, dispatch);
	const deleteSummaryFromDb = bindActionCreators(deleteSummary, dispatch);

	const onDebounce = useMemo(
		() =>
			debounce((summ: Summary) => {
				summary = { ...summary, ...summ };

				const summaryId = summary.id;
				const candidateEmail = summary.candidateEmail;

				updateSummaryDb(summary, candidateEmail, summaryId);
			}, 500),
		[debounce]
	);

	return (
		<Col>
			<Row>
				<Col>
					<header className='mb-5'>
						<h3>Summary</h3>
					</header>
				</Col>
				<Col>
					<div className='d-flex flex-row-reverse'>
						{!summary && (
							<CreateSummaryModal candidate={candidate} />
						)}
						{summary && (
							<DeleteButton
								deleteData={(successCallback) =>
									deleteSummaryFromDb(
										summary.candidateEmail,
										summary.id,
										successCallback
									)
								}
							/>
						)}
					</div>
				</Col>
			</Row>
			{summary && (
				<>
					<Editor
						value={summary.description}
						maxLength={2000}
						setValue={(value: string) => {
							updateSummaryState({
								...summary,
								description: value,
							});
							onDebounce({ ...summary, description: value });
						}}
					/>
				</>
			)}
		</Col>
	);
};

export default SummaryPreview;
