import { Action, Dispatch } from 'redux';

import {
    defaultInstance,
    AxiosError,
    AxiosResponse,
    getAuthHeader,
} from '../../api';
import { ApiError, Language } from '../../models';
import { AppThunkAction } from '../';

export enum LanguageActionTypes {
    SET_LANGUAGES = 'SetLanguages',
    UPDATE_LANGUAGE = 'UpdateLanguage',
    ADD_LANGUAGE = 'AddLanguage',
    DELETE_LANGUAGE = 'DeleteLanguage'
}

// ACTIONS
export interface SetLanguagesAction extends Action {
    type: LanguageActionTypes.SET_LANGUAGES;
    languages: Language[]
}
const SetLanguages = (languages: Language[]): SetLanguagesAction => {
    return { type: LanguageActionTypes.SET_LANGUAGES, languages };
};

export interface UpdateLanguageAction extends Action {
    type: LanguageActionTypes.UPDATE_LANGUAGE;
    language: Language;
}
const UpdateLanguage = (language: Language): UpdateLanguageAction => {
    return { type: LanguageActionTypes.UPDATE_LANGUAGE, language }
}

export interface AddLanguageAction extends Action {
    type: LanguageActionTypes.ADD_LANGUAGE;
    language: Language;
};
const AddLanguange = (language: Language): AddLanguageAction => {
    return { type: LanguageActionTypes.ADD_LANGUAGE, language }
};

export interface DeleteLanguageAction extends Action {
    type: LanguageActionTypes.DELETE_LANGUAGE;
    language: string;
};
const DeleteLanguage = (language: string): DeleteLanguageAction => {
    return { type: LanguageActionTypes.DELETE_LANGUAGE, language }
};

export type LanguageActions = SetLanguagesAction | UpdateLanguageAction | AddLanguageAction | DeleteLanguageAction;

// ACTION CREATORS

export const getLanguages =
    (
        candidateEmail: string,
        successCallback: () => void = () => { },
        failureCallback: (error?: string) => void = () => { }
    ): AppThunkAction<LanguageActions> =>
        (dispatch) => {
            defaultInstance
                .get(`/v1/candidates/${candidateEmail}/languages`, { headers: getAuthHeader() })
                .then((response: AxiosResponse<Language[]>) => {
                    dispatch(SetLanguages(response.data));

                    successCallback();
                })
                .catch((error: AxiosError<ApiError>) => {
                    if (error && error.response && error.response.data) {
                        failureCallback(error.response.data.detail);
                    } else console.error(error);
                });
        };

export const setLanguages = (languages: Language[]) => {
    return (dispatch: Dispatch) => {
        dispatch(SetLanguages(languages));
    }
}

export const saveLanguage =
    (
        language: Language,
        candidateEmail: string,
        comprehensionId: string,
        successCallback: () => void = () => { },
        failureCallback: (error?: string) => void = () => { }
    ): AppThunkAction<LanguageActions> =>
        (dispatch) => {
            language.candidateEmail = candidateEmail;
            defaultInstance.put(
                `/v1/candidates/${candidateEmail}/languages/${comprehensionId}`,
                language,
                { headers: getAuthHeader() }
            )
                .then((response: AxiosResponse<Language>) => {
                    successCallback();
                })
                .catch((error: AxiosError<ApiError>) => {
                    if (error && error.response && error.response.data) {
                        failureCallback(error.response.data.detail);
                    } else console.error(error);
                });
        };

export const createLanguage =
    (
        language: Language,
        candidateEmail: string,
        successCallback: () => void = () => { },
        failureCallback: (errors?: string[]) => void = () => { }
    ): AppThunkAction<LanguageActions> =>
        (dispatch) => {
            defaultInstance
                .post(`v1/candidates/${candidateEmail}/languages`, language, { headers: getAuthHeader() })
                .then((response: AxiosResponse<Language>) => {
                    dispatch(AddLanguange(response.data));
                    successCallback();
                })
                .catch((error: AxiosError<ApiError>) => {
                    if (error && error.response && error.response.data) {
                        const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
                    } else console.error(error);
                });
        }

export const deleteLanguage =
    (
        candidateEmail: string,
        language: string,
        successCallback: () => void = () => { },
        failureCallback: (error?: string) => void = () => { }
    ): AppThunkAction<LanguageActions> =>
        (dispatch) => {
            defaultInstance
                .delete(`/v1/candidates/${candidateEmail}/languages/${language}`, { headers: getAuthHeader() })
                .then((response: AxiosResponse) => {
                    dispatch(DeleteLanguage(language));
                    successCallback();
                })
                .catch((error: AxiosError<ApiError>) => {
                    if (error && error.response && error.response.data) {
                        failureCallback(error.response.data.detail);
                    } else console.error(error);
                });
        }

export const updateLanguage = (language: Language) => {
    return (dispatch: Dispatch) => {
        dispatch(UpdateLanguage(language));
    }
}