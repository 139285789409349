import { Action, Dispatch } from 'redux';

import { convertToUtc } from 'src/utils';
import { AppThunkAction } from '../';
import {
	AxiosError,
	AxiosResponse,
	defaultInstance,
	getAuthHeader,
} from '../../api';
import { ApiError, Candidate } from '../../models';

export enum CandidateActionTypes {
	GET_CANDIDATES = 'GetCandidates',
	GET_CANDIDATES_SUCCESS = 'GetCandidatesSuccess',
	GET_CANDIDATES_FAILURE = 'GetCandidatesFailure',
	GET_CANDIDATE = 'GetCandidate',
	GET_CANDIDATE_SUCCESS = 'GetCandidateSuccess',
	GET_CANDIDATE_FAILURE = 'GetCandidateFailure',
	SET_CANDIDATE = 'SetCandidate',
	SET_CANDIDATE_OFFLINE = 'SetCandidateOffline',
	SET_CANDIDATE_SUCCESS = 'SetCandidateSuccess',
	SET_CANDIDATE_FAILURE = 'SetCandidateFailure',
	RESET_CANDIDATE = 'ResetCandidate',
	ADD_CANDIDATE = 'AddCandidate',
	DELETE_CANDIDATE = 'DeleteCandidate'
}

// ACTIONS
export interface GetCandidatesAction extends Action {
	type: CandidateActionTypes.GET_CANDIDATES;
	loading: boolean;
}
const GetCandidates = (): GetCandidatesAction => {
	return { type: CandidateActionTypes.GET_CANDIDATES, loading: true };
};

export interface GetCandidatesActionSuccess extends Action {
	type: CandidateActionTypes.GET_CANDIDATES_SUCCESS;
	candidates: string[];
}
const GetCandidatesSuccess = (
	candidates: string[]
): GetCandidatesActionSuccess => {
	return { type: CandidateActionTypes.GET_CANDIDATES_SUCCESS, candidates };
};

export interface GetCandidatesActionFailure extends Action {
	type: CandidateActionTypes.GET_CANDIDATES_FAILURE;
	error: string;
}
const GetCandidatesFailure = (error: string): GetCandidatesActionFailure => {
	return { type: CandidateActionTypes.GET_CANDIDATES_FAILURE, error };
};

export interface GetCandidateAction extends Action {
	type: CandidateActionTypes.GET_CANDIDATE;
	loading: boolean;
}
const GetCandidate = (): GetCandidateAction => {
	return { type: CandidateActionTypes.GET_CANDIDATE, loading: true };
};

export interface GetCandidateActionSuccess extends Action {
	type: CandidateActionTypes.GET_CANDIDATE_SUCCESS;
	candidate: Candidate;
}
const GetCandidateSuccess = (
	candidate: Candidate
): GetCandidateActionSuccess => {
	return { type: CandidateActionTypes.GET_CANDIDATE_SUCCESS, candidate };
};

export interface GetCandidateActionFailure extends Action {
	type: CandidateActionTypes.GET_CANDIDATE_FAILURE;
	error: string;
}
const GetCandidateFailure = (error: string): GetCandidateActionFailure => {
	return { type: CandidateActionTypes.GET_CANDIDATE_FAILURE, error };
};

export interface SetCandidateAction extends Action {
	type: CandidateActionTypes.SET_CANDIDATE;
	candidate: Candidate;
}
const SetCandidate = (candidate: Candidate): SetCandidateAction => {
	return { type: CandidateActionTypes.SET_CANDIDATE, candidate };
};

export interface SetCandidateOfflineAction extends Action {
	type: CandidateActionTypes.SET_CANDIDATE_OFFLINE;
	candidate: Candidate;
}
const SetCandidateOffline = (
	candidate: Candidate
): SetCandidateOfflineAction => {
	return { type: CandidateActionTypes.SET_CANDIDATE_OFFLINE, candidate };
};

export interface SetCandidateActionSuccess extends Action {
	type: CandidateActionTypes.SET_CANDIDATE_SUCCESS;
	candidate: Candidate;
}
const SetCandidateSuccess = (
	candidate: Candidate
): SetCandidateActionSuccess => {
	return { type: CandidateActionTypes.SET_CANDIDATE_SUCCESS, candidate };
};

export interface SetCandidateActionFailure extends Action {
	type: CandidateActionTypes.SET_CANDIDATE_FAILURE;
	error: string;
}
const SetCandidateFailure = (error: string): SetCandidateActionFailure => {
	return { type: CandidateActionTypes.SET_CANDIDATE_FAILURE, error };
};

export interface ResetCandidateAction extends Action {
	type: CandidateActionTypes.RESET_CANDIDATE;
}
const ResetCandidate = (): ResetCandidateAction => {
	return { type: CandidateActionTypes.RESET_CANDIDATE };
};

export interface AddCandidateAction extends Action {
	type: CandidateActionTypes.ADD_CANDIDATE;
	candidateEmail: string;
}
export const AddCandidate = (candidateEmail: string): AddCandidateAction => {
	return { type: CandidateActionTypes.ADD_CANDIDATE, candidateEmail };
};

export interface DeleteCandidateAction extends Action {
	type: CandidateActionTypes.DELETE_CANDIDATE;
	candidateEmail: string;
}
export const DeleteCandidate = (candidateEmail: string): DeleteCandidateAction => {
	return { type: CandidateActionTypes.DELETE_CANDIDATE, candidateEmail };
};


export type CandidateActions =
	| GetCandidatesAction
	| GetCandidatesActionSuccess
	| GetCandidatesActionFailure
	| GetCandidateAction
	| GetCandidateActionSuccess
	| GetCandidateActionFailure
	| SetCandidateAction
	| SetCandidateActionSuccess
	| SetCandidateActionFailure
	| ResetCandidateAction
	| SetCandidateOfflineAction
	| AddCandidateAction
	| DeleteCandidateAction;

// ACTION CREATORS
export const getCandidates =
	(
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<CandidateActions> =>
		(dispatch) => {
			dispatch(GetCandidates());

			defaultInstance
				.get('/v1/candidates', { headers: getAuthHeader() })
				.then((response: AxiosResponse<string[]>) => {
					dispatch(GetCandidatesSuccess(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						dispatch(GetCandidatesFailure(error.response.data.detail));
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		};

export const getCandidate =
	(
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<CandidateActions> =>
		(dispatch) => {
			dispatch(GetCandidate());

			defaultInstance
				.get(`/v1/candidates/${candidateEmail}`, {
					headers: getAuthHeader(),
				})
				.then((response: AxiosResponse<Candidate>) => {
					response.data.birthDate = convertToUtc(response.data.birthDate);
					if (!response.data.phoneNumber) response.data.phoneNumber = '';

					dispatch(GetCandidateSuccess(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					console.log(error);
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const setCandidateOffline = (candidate: Candidate) => {
	return (dispatch: Dispatch) => {
		dispatch(SetCandidateOffline(candidate));
	}
}

export const setCandidate =
	(
		candidate: Candidate,
		candidateEmail: string,
		candidateAlreadyExists: boolean,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<CandidateActions> =>
		(dispatch) => {
			(candidateAlreadyExists
				? defaultInstance.put(
					`/v1/candidates/${candidateEmail}`,
					candidate,
					{ headers: getAuthHeader() }
				)
				: defaultInstance.post(`/v1/candidates`, candidate, {
					headers: {
						...getAuthHeader(),
						'Content-Type': 'application/json',
					},
				})
			)
				.then((response: AxiosResponse<Candidate>) => {
					if (!response.data.phoneNumber) response.data.phoneNumber = '';

					dispatch(SetCandidateSuccess(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const resetCandidate =
	(): AppThunkAction<CandidateActions> => (dispatch) => {
		dispatch(ResetCandidate());
	};

export const deleteCandidate =
(
	candidateEmail: string,
	successCallback: () => void = () => { },
	failureCallback: (errors?: string[]) => void = () => { }
): AppThunkAction<CandidateActions> =>
	(dispatch) => {
		defaultInstance
			.delete(`/v1/candidates/${candidateEmail}`, {
				headers: getAuthHeader(),
			})
			.then((response: AxiosResponse<Candidate>) => {
				dispatch(DeleteCandidate(candidateEmail));
				successCallback();
			})
			.catch((error: AxiosError<ApiError>) => {
				console.log(error);
				if (error && error.response && error.response.data) {
					const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
					failureCallback(errorsToDisplay);
				} else console.error(error);
			});
	};