import axios from 'axios';

export const authInstance = axios.create({
	baseURL: `https://api${process.env.REACT_APP_ENVIRONMENT === 'production' ? '' : '-dev'}.capyx.be`,
});

export const defaultInstance = axios.create({
	baseURL: '/api',
});

export * from 'axios';

