import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Candidate, Reference } from "src/models";
import { deleteReference } from "src/store/reference";
import { selectSelectedProfile } from "src/store/profile";
import DeleteButton from "../DeleteButton";
import UpdateReferenceModal from "../ReferenceForm/UpdateReferenceModal";

type Props = {
    className?: string | undefined;
    candidate: Candidate;
    reference: Reference;
};

type LabelDetailsProps = {
    label: string;
    details: string;
};

export const LabelDetails: React.FC<LabelDetailsProps> = ({ label, details }) => {
    return (
        <div style={{ marginBottom: '10px' }}>
            <div style={{ fontWeight: 'bold' }}>{label} : </div>
            <div>{details}</div>
        </div>
    );
}

export const ReferencePreview: React.FC<Props> = ({ className, candidate, reference }) => {
    const profile = useSelector(selectSelectedProfile);
    const dispatch = useDispatch();
    const deleteReferenceFromDb = bindActionCreators(deleteReference, dispatch);

    return (
        <div className="mb-3">
            {
                (reference.order == -1 ?
                <div>
                    <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ alignSelf: 'center' }}>{reference.firstName} {reference.lastName}</div>

                        <div style={{ float: 'right' }}>
                            <UpdateReferenceModal candidate={candidate} reference={reference} />
                            <div style={{ marginLeft: '10px', display: 'inline' }}></div>
                            <DeleteButton deleteData={(successCallback) => deleteReferenceFromDb(candidate.candidateEmail, reference.id, successCallback)} />
                        </div>
                    </div>
                    <div>Not currently displayed on resume</div>
                </div>
                :
                <div>
                    <div style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ alignSelf: 'center' }}>{reference.order} | {reference.firstName} {reference.lastName}</div>

                        <div style={{ float: 'right' }}>
                            <UpdateReferenceModal candidate={candidate} reference={reference} />
                            <div style={{ marginLeft: '10px', display: 'inline' }}></div>
                            <DeleteButton deleteData={(successCallback) => deleteReferenceFromDb(candidate.candidateEmail, reference.id, successCallback)} />
                        </div>
                    </div>
                    <div>{reference.company} - {reference.relationship}</div>
                    <div>
                        {reference.referenceEmail ? `${reference.referenceEmail}` : ''} {reference.referenceEmail && reference.phoneNumber ? ' | ' : ''} {reference.phoneNumber ? `${reference.phoneNumber}` : ''}
                    </div>
                </div>
                )
            }
        </div>
    );
}

export default ReferencePreview;