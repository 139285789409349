import axios, { AxiosResponse } from 'axios';
import {
	OpenAiParsedCertification,
	OpenAiParsedEducation,
	OpenAiParsedExperience,
	OpenAiParsedSkill,
	OpenAiPrompt,
	OpenAiResponse,
} from 'src/models';
import { removeNullString } from 'src/utils';

export function getOpenAiApiKey() {
	return { Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}` };
}

export async function getPromptResponse<T>(promptContent: string): Promise<T> {
	const prompt: OpenAiPrompt = {
		model: 'gpt-3.5-turbo',
		messages: [
			{
				role: 'user',
				content: promptContent,
			},
		],
	};

	const response: AxiosResponse<OpenAiResponse> = await axios
		.create()
		.post('https://api.openai.com/v1/chat/completions', prompt, {
			headers: getOpenAiApiKey(),
		});

	return JSON.parse(response.data.choices[0].message.content);
}

export async function getParsedCertifications(
	certifications: string
): Promise<OpenAiParsedCertification[]> {
	const certificationFormat: string = JSON.stringify([
		{
			certificationName: 'string',
			year: 'yyyy format (only write the end year)',
			organization: 'string',
		},
	]);

	const parsingMessage: string = `Parse the given input into the following format, knowing that the fields year and organization are optional (use null value if needed) :  \n${certificationFormat}\n${certifications}`;

	var parsedCertifications: OpenAiParsedCertification[] =
		await getPromptResponse<OpenAiParsedCertification[]>(parsingMessage);

	return parsedCertifications.map((pc) => {
		return {
			...pc,
			organization: removeNullString(pc.organization),
		} as OpenAiParsedCertification;
	});
}

export async function getParsedEducations(
	educations: string
): Promise<OpenAiParsedEducation[]> {
	const educationFormat: string = JSON.stringify([
		{
			school: 'string',
			year: 'yyyy (only write the end year)',
			faculty: 'string',
			degree: 'string',
		},
	]);

	const parsingMessage: string = `Parse the given input into the following format, knowing that the fields year and degree are optional (use null value if needed) :  \n${educationFormat}\n${educations}`;

	var parsedEducations: OpenAiParsedEducation[] = await getPromptResponse<
		OpenAiParsedEducation[]
	>(parsingMessage);

	return parsedEducations.map((pe) => {
		return {
			...pe,
			degree: removeNullString(pe.degree),
		} as OpenAiParsedEducation;
	});
}

export async function getParsedExperience(
	experience: string
): Promise<OpenAiParsedExperience> {
	const experienceFormat: string = JSON.stringify({
		company: 'string',
		client: 'string',
		location: 'string',
		startDate:
			'mm/yyyy or yyyy format, depending on the information in the input',
		endDate:
			'mm/yyyy or yyyy format, depending on the information in the input',
		role: 'string',
		tools: 'string',
		environment: 'string',
		methodology: 'string',
		technologies: 'string',
		description: 'string',
	});

	const parsingMessage: string = `Parse the given input into the following format, taking into account new lines in the description (keep the bullet points), put null value for the end date if the experience is current and put null object value for the missing information and not null as a string : \n${experienceFormat}\n${experience}`;

	var parsedExperience: OpenAiParsedExperience =
		await getPromptResponse<OpenAiParsedExperience>(parsingMessage);

	return {
		...parsedExperience,
		client: removeNullString(parsedExperience.client),
		location: removeNullString(parsedExperience.location),
		role: removeNullString(parsedExperience.role),
		tools: removeNullString(parsedExperience.tools),
		environment: removeNullString(parsedExperience.environment),
		methodology: removeNullString(parsedExperience.methodology),
		technologies: removeNullString(parsedExperience.technologies),
		description: removeNullString(parsedExperience.description),
	} as OpenAiParsedExperience;
}

export async function getParsedSkills(
	skills: string
): Promise<OpenAiParsedSkill[]> {
	const skillFormat: string = JSON.stringify([
		{
			name: 'string',
		},
	]);

	const parsingMessage: string = `Parse the given list of words into the following format : \n${skillFormat}\n${skills}`;

	return await getPromptResponse<OpenAiParsedSkill[]>(parsingMessage);
}
