import { createSelector } from 'reselect';

import { ApplicationState } from '../';
import { LanguageState } from './state';
import { LevelState } from '../level';

const languagesSelector: (state: ApplicationState) => [LanguageState, LevelState] = (state: ApplicationState) => [state.languageFeature, state.levelFeature];

export const selectLanguages = createSelector(
	languagesSelector,
	([languageState, levelState]) => {
		return languageState.languages.sort(function (a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		}).filter(l => l);
	}
);