import { Action } from 'redux';

import { AppThunkAction } from '../';
import { ApiError, Token, User } from '../../models';
import {
	authInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
	setAuthHeader,
} from '../../api';

export enum UserActionTypes {
	SIGN_IN = 'SignIn',
	SIGN_IN_SUCCESS = 'SignInSuccess',
	SIGN_IN_FAILURE = 'SignInFailure',
	SIGNED_IN = 'SignedIn',
	SIGNED_IN_SUCCESS = 'SignedInSuccess',
	SIGNED_IN_FAILURE = 'SignedInFailure',
	SIGN_OUT = 'SignOut',
}

// ACTIONS
export interface SignInAction extends Action {
	type: UserActionTypes.SIGN_IN;
	loading: boolean;
}
const SignIn = (): SignInAction => {
	return { type: UserActionTypes.SIGN_IN, loading: true };
};

export interface SignInSuccessAction extends Action {
	type: UserActionTypes.SIGN_IN_SUCCESS;
	token: Token;
	email: string;
}
const SignInSuccess = (token: Token, email: string): SignInSuccessAction => {
	return { type: UserActionTypes.SIGN_IN_SUCCESS, token, email };
};

export interface SignInFailureAction extends Action {
	type: UserActionTypes.SIGN_IN_FAILURE;
	error: string;
}
const SignInFailure = (error: string): SignInFailureAction => {
	return { type: UserActionTypes.SIGN_IN_FAILURE, error };
};

export interface SignedInAction extends Action {
	type: UserActionTypes.SIGNED_IN;
	loading: boolean;
}
const SignedIn = (): SignedInAction => {
	return { type: UserActionTypes.SIGNED_IN, loading: true };
};

export interface SignedInSuccessAction extends Action {
	type: UserActionTypes.SIGNED_IN_SUCCESS;
	user: User;
}
const SignedInSuccess = (user: User): SignedInSuccessAction => {
	return {
		type: UserActionTypes.SIGNED_IN_SUCCESS,
		user,
	};
};

export interface SignedInFailureAction extends Action {
	type: UserActionTypes.SIGNED_IN_FAILURE;
	error: string;
}
const SignedInFailure = (error: string): SignedInFailureAction => {
	return { type: UserActionTypes.SIGNED_IN_FAILURE, error };
};

export interface SignOutAction extends Action {
	type: UserActionTypes.SIGN_OUT;
}
const SignOut = (): SignOutAction => {
	return { type: UserActionTypes.SIGN_OUT };
};

export type UserActions =
	| SignInAction
	| SignInSuccessAction
	| SignInFailureAction
	| SignedInAction
	| SignedInSuccessAction
	| SignedInFailureAction
	| SignOutAction;

// ACTION CREATORS
export const signIn =
	(
		email: string,
		password: string,
		successCallback: () => void = () => {},
		failureCallback: (error?: string) => void = () => {}
	): AppThunkAction<UserActions> =>
	(dispatch) => {
		dispatch(SignIn());
		authInstance
			.post(`/v1/auth/connect`, { email, password })
			.then((response: AxiosResponse<Token>) => {
				dispatch(SignInSuccess(response.data, email));
				return { token: response.data, email };
			})
			.then(({ token, email }: { token: Token; email: string }) => {
				setAuthHeader(token);

				dispatch(SignedIn());
				authInstance
					.get(`/v1/users/${email}`, { headers: getAuthHeader() })
					.then((response: AxiosResponse<User>) => {
						dispatch(SignedInSuccess(response.data));
						successCallback();
					})
					.catch((error: AxiosError<ApiError>) => {
						if (error && error.response && error.response.data) {
							dispatch(
								SignedInFailure(error.response.data.detail)
							);
							failureCallback(error.response.data.detail);
						} else console.error(error);
					});
			})
			.catch((error: AxiosError<ApiError>) => {
				if (error && error.response && error.response.data) {
					dispatch(SignInFailure(error.response.data.detail));
					failureCallback(error.response.data.detail);
				} else console.error(error);
			})
			.catch((error) => {
				const unknownErrorMsg = 'An unknown error occured';
				console.error(error);
				dispatch(SignInFailure(unknownErrorMsg));
				failureCallback(unknownErrorMsg);
			});
	};

export const signOut = (): UserActions => SignOut();
