import { Action, Reducer } from 'redux';
import { ReferenceActions, ReferenceActionTypes } from './actions';
import { ReferenceState, unloadedState } from './state';


export const referenceReducer: Reducer<ReferenceState> = (
	state: ReferenceState | undefined,
	incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as ReferenceActions;
	switch (action.type) {
		case ReferenceActionTypes.SET_REFERENCES:
			state.references = action.references;
			return { ...state }

		case ReferenceActionTypes.UPDATE_REFERENCE:
			action.reference.isDisplayable = action.reference.order != -1;
			state.references = state.references.map((reference) => reference.id === action.reference.id ? action.reference : reference);
			return { ...state }

		case ReferenceActionTypes.ADD_REFERENCE:
			state.references.push(action.reference);
			return { ...state }

		case ReferenceActionTypes.DELETE_REFERENCE:
			state.references = state.references.filter(e => e.id !== action.referenceId);
			return { ...state }

		default:
			return state;
	}
};
