import * as React from 'react';
import { Spinner } from 'react-bootstrap';

type Props = {
	size?: 'sm';
};

const Loading: React.FC<Props> = ({ size }) => (
	<Spinner animation='border' role='status' variant='dark' size={size}>
		<span className='visually-hidden'>Loading...</span>
	</Spinner>
);

export default Loading;
