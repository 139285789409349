import { Action, Dispatch } from 'redux';

import {
	defaultInstance,
	AxiosError,
	AxiosResponse,
	getAuthHeader,
} from '../../api';
import { ApiError, Certification, Summary } from '../../models';
import { AppThunkAction } from '..';

export enum CertificationActionTypes {
	SET_CERTIFICATIONS = 'SetCertifications',
	UPDATE_CERTIFICATION = 'UpdateCertification',
	ADD_CERTIFICATIONS = 'AddCertifications',
	DELETE_CERTIFICATION = 'DeleteCertification'
}

// ACTIONS
export interface SetCertificationsAction extends Action {
	type: CertificationActionTypes.SET_CERTIFICATIONS;
	certifications: Certification[]
}
const SetCertifications = (certifications: Certification[]): SetCertificationsAction => {
	return { type: CertificationActionTypes.SET_CERTIFICATIONS, certifications: certifications };
};

export interface UpdateCertificationAction extends Action {
	type: CertificationActionTypes.UPDATE_CERTIFICATION;
	certification: Certification;
}
const UpdateCertification = (certification: Certification): UpdateCertificationAction => {
	return { type: CertificationActionTypes.UPDATE_CERTIFICATION, certification: certification };
};

export interface AddCertificationsAction extends Action {
	type: CertificationActionTypes.ADD_CERTIFICATIONS;
	certifications: Certification[];
}
const AddCertifications = (certifications: Certification[]): AddCertificationsAction => {
	return { type: CertificationActionTypes.ADD_CERTIFICATIONS, certifications: certifications };
}

export interface DeleteCertificationAction extends Action {
	type: CertificationActionTypes.DELETE_CERTIFICATION;
	certificationId: string;
}
const DeleteCertification = (certificationId: string): DeleteCertificationAction => {
	return { type: CertificationActionTypes.DELETE_CERTIFICATION, certificationId };
}


export type CertificationActions = SetCertificationsAction | UpdateCertificationAction | AddCertificationsAction | DeleteCertificationAction;

// ACTION CREATORS

export const getCertifications =
	(
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<CertificationActions> =>
		(dispatch) => {
			defaultInstance
				.get(`/v1/candidates/${candidateEmail}/certifications`, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Certification[]>) => {
					dispatch(SetCertifications(response.data));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		};

export const saveCertification =
	(
		certification: Certification,
		candidateEmail: string,
		certificationId: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<CertificationActions> =>
		(dispatch) => {
			defaultInstance.put(
				`/v1/candidates/${candidateEmail}/certifications/${certificationId}`,
				certification,
				{ headers: getAuthHeader() }
			)
				.then((response: AxiosResponse<Certification>) => {
					dispatch(UpdateCertification(certification));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					}
					else console.error(error);
				});
		};

// 
export const createCertifications =
	(
		certifications: Certification[],
		candidateEmail: string,
		successCallback: () => void = () => { },
		failureCallback: (errors?: string[]) => void = () => { }
	): AppThunkAction<CertificationActions> =>
		(dispatch) => {
			defaultInstance
				.post(`/v1/candidates/${candidateEmail}/certifications`, { certifications }, { headers: getAuthHeader() })
				.then((response: AxiosResponse<Certification[]>) => {
					dispatch(AddCertifications(response.data))
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						const errorsToDisplay = Object.values(error.response.data.errors).flat() as Array<string>;
						failureCallback(errorsToDisplay);
					} else console.error(error);
				});
		};

export const deleteCertification =
	(
		candidateEmail: string,
		certificationId: string,
		successCallback: () => void = () => { },
		failureCallback: (error?: string) => void = () => { }
	): AppThunkAction<CertificationActions> =>
		(dispatch) => {
			defaultInstance
				.delete(`/v1/candidates/${candidateEmail}/certifications/${certificationId}`, { headers: getAuthHeader() })
				.then((response: AxiosResponse) => {
					dispatch(DeleteCertification(certificationId));
					successCallback();
				})
				.catch((error: AxiosError<ApiError>) => {
					if (error && error.response && error.response.data) {
						failureCallback(error.response.data.detail);
					} else console.error(error);
				});
		}

export const setCertifications = (certifications: Certification[]) => {
	return (dispatch: Dispatch) => {
		dispatch(SetCertifications(certifications));
	}
}

export const updateCertification = (certification: Certification) => {
	return (dispatch: Dispatch) => {
		dispatch(UpdateCertification(certification));
	}
}