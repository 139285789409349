import { Action, Reducer } from "redux";
import { Skill } from "src/models/Skill";
import { SkillCategory } from "src/models/SkillCategory";
import { SkillActions, SkillActionTypes } from "./actions";
import { SkillState, unloadedState } from "./state";

const allSameType = (skills: Skill[], category: SkillCategory) => skills.every(e => e.category === category);

export const skillReducer: Reducer<SkillState> = (
    state: SkillState | undefined,
    incomingAction: Action
) => {
	if (!state) return unloadedState;

	const action = incomingAction as SkillActions;
	switch (action.type) {
		case SkillActionTypes.GET_SKILLS:
			return { ...state }

		case SkillActionTypes.SET_SKILLS:
			if (state.skills.every((s) => s.category !== action.category)) {
				state.skills = [ ...state.skills, ...action.skills ];
			} else {
				state.skills = action.skills;
			}
			
			return { ...state }

		case SkillActionTypes.ADD_SKILLS:
			state.skills.push(...action.skills);
			return { ...state }

		case SkillActionTypes.UPDATE_SKILL:
			state.skills = state.skills.map((skill) => skill.id === action.skill.id ? action.skill : skill);
			return { ...state }

		case SkillActionTypes.DELETE_SKILL:
			state.skills = state.skills.filter((skill) => skill.id !== action.skillId);
			return { ...state }

		default:
			return state;
	}
}