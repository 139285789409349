import debounce from 'lodash.debounce';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Candidate, Language } from 'src/models';
import { deleteLanguage, saveLanguage, updateLanguage } from 'src/store/language';
import { selectLevels } from 'src/store/level';
import DeleteButton from '../DeleteButton';
import FormInput from '../FormInput';

type Props = {
	className?: string | undefined;
	language: Language;
	candidate: Candidate;
};

export const LanguageForm: React.FC<Props> = ({
	className,
	language,
	candidate
}) => {
	let levels = useSelector(selectLevels);
	const dispatch = useDispatch();
	const updateLanguageState = bindActionCreators(updateLanguage, dispatch);
	const updateLanguageDb = bindActionCreators(saveLanguage, dispatch);
	const deleteLanguageFromDb = bindActionCreators(deleteLanguage, dispatch)

	const onDebounce = useMemo(
		() =>
			debounce((l: Language) => {
				const candidateEmail = candidate.candidateEmail;
				const languageName = l.name;
				
				updateLanguageDb(l, candidateEmail, languageName);

			}, 500),
		[debounce]
	);

	return (
		<tr className={className}>
			<td>
				<FormInput 
					disabled 
					maxLength={50} 
					fieldValue={language.name}
				/>
			</td>
			<td>
				<FormInput 
					type='select'
					values={levels} 
					fieldValue={language.level}
					setFieldValue={(value:string) =>
						updateLanguageState({...language, level: value})
					}
					saveFieldvalue={(value:string)=>
						onDebounce({...language, level: value})
					}
				/>
			</td>
			<td>
				<DeleteButton deleteData={(successCallback) => deleteLanguageFromDb(candidate.candidateEmail, language.name, successCallback)} />
			</td>
		</tr>
	);
};

export * from './Modal';
